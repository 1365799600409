import "core-js/modules/es.array.from.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.iterator.js";
import useBaseUtils from "~/functions/useBaseUtils";
import useBreakpoints from "~/functions/useBreakpoints";
export default {
  __name: 'DiligenceSkeletonCheckItems',
  props: {
    checkAmount: {
      type: Number,
      "default": 1
    },
    isOverviewTab: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBreakpoints = useBreakpoints(),
      sm = _useBreakpoints.sm;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive;
    var wrapperStyle = computed(function () {
      return {
        "grid-template-columns": sm.value ? "repeat(3, minmax(0, 1fr))" : "minmax(0,1fr)",
        "grid-gap": sm.value ? "8px" : "0",
        "--item-min-height": sm.value ? "198px" : "unset"
      };
    });
    var rows = computed(function () {
      return Array.from({
        length: props.checkAmount
      }, function (_, index) {
        return index;
      });
    });
    return {
      __sfc: true,
      sm: sm,
      darkActive: darkActive,
      wrapperStyle: wrapperStyle,
      props: props,
      rows: rows
    };
  }
};