import {
  cyclicalDescriptionIdMatch,
  highlySpecializedDescriptionMatch,
} from "~/utils/constants/industryCategories"

const notApplicableMsg = [
  {
    requiredVariables: [],
    messageArray: [
      { tag: "strong", customProp: true, value: "Company" },
      {
        tag: "strong",
        value:
          " was not profitable during this time period so metric is not applicable",
      },
    ],
  },
]

const getScoreFromDescCutoff = (cutoffArr = [], value) => {
  const keys = cutoffArr
    .filter((key) => !isNaN(key))
    .map(Number)
    .sort((a, b) => b - a)
  const results = keys.map((key) => {
    return value >= key
  })
  const firstTrueKey = results.indexOf(true)
  const scoreMap = { 0: 2, 1: 1, 2: 0, "-1": 0 }
  return scoreMap[firstTrueKey]
}

const useObjectKeysAsDescCutoffValues = (dataObject, textObject) => {
  const value = dataObject?.checkValue?.v
  const keys = Object.keys(textObject)
    .filter((key) => !isNaN(key))
    .map(Number)
    .sort((a, b) => b - a)
  const results = keys.map((key) => {
    return value >= key
  })

  const firstTrueKey = results.indexOf(true)

  const scoreMessage = value
    ? textObject[keys[firstTrueKey]] || textObject.default
    : notApplicableMsg
  const scoreMap = { 0: 2, 1: 1, 2: 0, "-1": 0 }
  const score = scoreMap[firstTrueKey]

  return {
    message: scoreMessage,
    score,
  }
}

const getDimensionScore = (score) => {
  const isBullish = score > 70
  const isBearish = score < 40
  const isNeutral = !isBullish && !isBearish
  return {
    isBullish,
    isBearish,
    isNeutral,
  }
}

const getCheckScore = (score) => {
  const isBullish = score === 2
  const isNeutral = score === 1
  const isBearish = score < 1

  return {
    isBullish,
    isBearish,
    isNeutral,
  }
}

const getMessage = (
  score,
  getScoreFn = () => {},
  bullishMessage,
  neutralMessage,
  bearishMessage
) => {
  const { isBearish, isBullish } = getScoreFn(score)
  if (isBearish) {
    return bearishMessage
  } else if (isBullish) {
    return bullishMessage
  } else {
    return neutralMessage
  }
}

/**
 * Returns a message based on the dimension score.
 *
 * @param {number} score - The dimension score to check.
 * @param {string} bullishMessage - The message to return if the score is bullish.
 * @param {string} neutralMessage - The message to return if the score is neutral.
 * @param {string} bearishMessage - The message to return if the score is bearish.
 *
 * @returns {string} The message based on the score.
 */
const getDimensionMsg = (
  score,
  bullishMessage,
  neutralMessage,
  bearishMessage
) =>
  getMessage(
    score,
    getDimensionScore,
    bullishMessage,
    neutralMessage,
    bearishMessage
  )

/**
 * Returns a message based on the check score.
 *
 * @param {number} score - The check score.
 * @param {string} bullishMessage - The message to return if the score is bullish.
 * @param {string} neutralMessage - The message to return if the score is neutral.
 * @param {string} bearishMessage - The message to return if the score is bearish.
 *
 * @returns {string} The message based on the score.
 */
const getCheckMessage = (
  score,
  bullishMessage,
  neutralMessage,
  bearishMessage
) =>
  getMessage(
    score,
    getCheckScore,
    bullishMessage,
    neutralMessage,
    bearishMessage
  )

const warningMessagesByType = {
  cyclical: (tickerName) =>
    `${tickerName} operates in a cyclical industry, making the Due Diligence score less relevant.`,
  highlySpecialized: (tickerName) =>
    `${tickerName} operates in a highly specialized industry, making the Due Diligence score less relevant.`,
  unprofitable: (tickerName) =>
    `${tickerName} is currently an unprofitable company, making this valuation framework less relevant.`,
}

const lowercaseStr = (item) => item.toLowerCase()
const lowercaseCyclicalMatch = cyclicalDescriptionIdMatch.map(lowercaseStr)
const lowercaseSpecializedMatch =
  highlySpecializedDescriptionMatch.map(lowercaseStr)
const getWarningMessageByCompanyDescription = (
  descriptionToMatch = "",
  tickerName = ""
) => {
  const lowercaseVersion = descriptionToMatch.toLowerCase()
  if (lowercaseCyclicalMatch.includes(lowercaseVersion)) {
    return warningMessagesByType.cyclical(tickerName)
  }
  if (lowercaseSpecializedMatch.includes(lowercaseVersion)) {
    return warningMessagesByType.highlySpecialized(tickerName)
  }
  return ""
}

export {
  notApplicableMsg,
  useObjectKeysAsDescCutoffValues,
  getScoreFromDescCutoff,
  getCheckMessage,
  getCheckScore,
  getDimensionMsg,
  getDimensionScore,
  getWarningMessageByCompanyDescription,
}
