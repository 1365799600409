import "core-js/modules/es.array.concat.js";
import { usePreferredLanguages } from "@vueuse/core";
import { utcParse } from "d3-time-format";
import { createUtcEpochToLongDateTimeFormatter, createUtcEpochToShortDateTimeFormatter } from "~/utils/tools/date";
var dateTimeFormatters = function dateTimeFormatters() {
  // I18n.setLanguage
  var langs = usePreferredLanguages();
  var dateLanguage = langs.value[0] || "default"; // TODO: check if there is a dateLanguage on the store and reset if so?
  var IEX_Price_Timezone = "America/New_York";
  var parseUtcDateTime = utcParse("%Y-%m-%dT%H:%M:%S.%LZ"); // formatCiqDate is the method called by parseCiqTime which is used
  // to format dates in the WatchlistTable
  // formatCiqDate was a computed property in the option api
  var utcEpochToLongDate = computed(function () {
    return createUtcEpochToLongDateTimeFormatter(langs.value[0]);
  });
  var utcEpochToShortDate = computed(function () {
    return createUtcEpochToShortDateTimeFormatter(langs.value[0]);
  });
  var utcEpochToTwoDigitYear = computed(function () {
    return new Intl.DateTimeFormat(langs.value[0], {
      year: "2-digit"
    });
  }); // FIXME: formatCiqDate appears to be a UTC epoch date formatter
  var formatCiqDate = computed(function () {
    return new Intl.DateTimeFormat(langs.value[0], {
      timeZone: "UTC",
      month: "short",
      day: "numeric"
    });
  }); // parseCiqTime is the method called in WatchlistTable component
  // that accepts the date returned for a price close
  // TODO: this is a string from....
  var parseCiqTime = function parseCiqTime(stringUTCDateTime, I18nFn) {
    var parser = utcParse("%Y-%m-%dT%H:%M:%S.%LZ");
    return "".concat(formatCiqDate.value.format(parser(stringUTCDateTime)), " ").concat(I18nFn ? I18nFn("Close") : "Close");
  };
  var userEpochToOnlyTime = computed(function () {
    return new Intl.DateTimeFormat(langs.value[0], {
      hour: "numeric",
      minute: "numeric"
    });
  });
  var userEpochToShortDate = computed(function () {
    return Intl.DateTimeFormat(langs.value[0], {
      year: "numeric",
      month: "long",
      day: "numeric"
    });
  });
  var userEpochToLongDate = computed(function () {
    return Intl.DateTimeFormat(langs.value[0], {
      year: "2-digit",
      month: "long",
      day: "numeric"
    });
  });
  var userEpochToDateTime = computed(function () {
    return new Intl.DateTimeFormat(langs.value[0], {
      hour: "numeric",
      minute: "numeric",
      year: "numeric",
      month: "long",
      day: "numeric"
    });
  });
  var epochToDateTime_EST = computed(function () {
    return new Intl.DateTimeFormat(langs.value[0], {
      timeZone: IEX_Price_Timezone,
      timeZoneName: "short",
      hour: "numeric",
      minute: "numeric",
      year: "numeric",
      month: "long",
      day: "numeric"
    });
  });
  var epochToOnlyTime_EST = computed(function () {
    return new Intl.DateTimeFormat(langs.value[0], {
      timeZone: IEX_Price_Timezone,
      timeZoneName: "short",
      hour: "numeric",
      minute: "numeric"
    });
  });
  return {
    langs: langs,
    dateLanguage: dateLanguage,
    IEX_Price_Timezone: IEX_Price_Timezone,
    parseUtcDateTime: parseUtcDateTime,
    parseCiqTime: parseCiqTime,
    epochToDateTime_EST: epochToDateTime_EST,
    epochToOnlyTime_EST: epochToOnlyTime_EST,
    utcEpochToLongDate: utcEpochToLongDate,
    utcEpochToShortDate: utcEpochToShortDate,
    utcEpochToTwoDigitYear: utcEpochToTwoDigitYear,
    userEpochToLongDate: userEpochToLongDate,
    userEpochToOnlyTime: userEpochToOnlyTime,
    userEpochToShortDate: userEpochToShortDate,
    userEpochToDateTime: userEpochToDateTime
  };
};
export default dateTimeFormatters;