import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import { DATASOURCE_QUERY_PARAMS } from "~/feature-toggle";
import { findMetricKey } from "~/utils/ciq";
var useCiqTableOptions = function useCiqTableOptions() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var $route = useRoute();
  onMounted(function () {
    if (DATASOURCE_QUERY_PARAMS && props.tableType === "financials") {
      var _$route$query;
      var dataSource = (_$route$query = $route.query) === null || _$route$query === void 0 ? void 0 : _$route$query.dataset;
      var slugs = {
        alt: "fmp",
        ms: "morningstar"
      };
      if (dataSource !== "tikr" && slugs[dataSource]) {
        activeTableSelection.value = slugs[dataSource];
      }
    }
  });
  var fetching = computed(function () {
    var _$store$state$ciq;
    if (activeTableSelection.value.includes("fmp")) {
      var _$store$state$fmp;
      return (_$store$state$fmp = $store.state.fmp) === null || _$store$state$fmp === void 0 || (_$store$state$fmp = _$store$state$fmp.loading) === null || _$store$state$fmp === void 0 ? void 0 : _$store$state$fmp.financials;
    }
    if (activeTableSelection.value.includes("morningstar")) {
      var _$store$state$morning;
      return (_$store$state$morning = $store.state.morningstar) === null || _$store$state$morning === void 0 || (_$store$state$morning = _$store$state$morning.loading) === null || _$store$state$morning === void 0 ? void 0 : _$store$state$morning.financials;
    }
    return (_$store$state$ciq = $store.state.ciq) === null || _$store$state$ciq === void 0 ? void 0 : _$store$state$ciq.fetchingFinancials;
  });
  var reverseDates = computed({
    get: function get() {
      return $store.state.config.reverseDates;
    },
    set: function set(val) {
      $store.dispatch("config/changeLocalStorage", {
        vuexKey: "reverseDates",
        localStorageKey: "reverseDates",
        value: val
      });
    }
  });
  var decimals = computed({
    get: function get() {
      return $store.state.ciq["".concat(props.tableType, "Decimals")];
    },
    set: function set(value) {
      $store.commit("".concat(props.storeModule, "/setToggle"), {
        type: "".concat(props.tableType, "Decimals"),
        value: value
      });
    }
  });
  var formatNumberToDecimals = computed(function () {
    var lang = navigator.language || "default";
    return new Intl.NumberFormat(lang, {
      minimumFractionDigits: decimals.value,
      maximumFractionDigits: decimals.value
    });
  });
  var formatPercentToDecimals = computed(function () {
    var lang = navigator.language || "default";
    return new Intl.NumberFormat(lang, {
      style: "percent",
      minimumFractionDigits: decimals.value,
      maximumFractionDigits: decimals.value
    });
  });
  var desiredUnits = computed({
    get: function get() {
      return $store.state.ciq["".concat(props.tableType, "Units")];
    },
    set: function set(value) {
      $store.commit("".concat(props.storeModule, "/setToggle"), {
        type: "".concat(props.tableType, "Units"),
        value: value
      });
    }
  });
  var activePeriod = computed({
    get: function get() {
      return $store.state[props.storeModule]["".concat(props.tableType, "PeriodToggle")];
    },
    set: function set(value) {
      $store.commit("".concat(props.storeModule, "/setToggle"), {
        type: "".concat(props.tableType, "PeriodToggle"),
        value: value
      });
    }
  });
  var activeTableSelection = computed({
    get: function get() {
      return $store.state[props.storeModule]["".concat(props.tableType, "TableSelection")];
    },
    set: function set(value) {
      // console.log({
      //   value,
      //   store: props.storeModule,
      //   tableType: props.tableType,
      // })
      $store.commit("config/setCompanyFinancialDataSource", value);
      $store.commit("".concat(props.storeModule, "/setToggle"), {
        type: "".concat(props.tableType, "TableSelection"),
        value: value
      });
    }
  });
  var currencyToggle = computed({
    get: function get() {
      return $store.state[activeTableSelection.value]["".concat(props.tableType, "CurrencyToggle")];
    },
    set: function set(value) {
      $store.commit("".concat(activeTableSelection.value, "/setToggle"), {
        type: "".concat(props.tableType, "CurrencyToggle"),
        value: value
      });
    }
  });
  var currentIso = computed(function () {
    var _currencies$value;
    return (_currencies$value = currencies.value) === null || _currencies$value === void 0 || (_currencies$value = _currencies$value[currencyToggle.value]) === null || _currencies$value === void 0 ? void 0 : _currencies$value.code;
  });
  var dateRange = computed({
    get: function get() {
      return $store.state[props.storeModule]["".concat(props.tableType, "DateRange")];
    },
    set: function set(value) {
      $store.commit("".concat(props.storeModule, "/setToggle"), {
        type: "".concat(props.tableType, "DateRange"),
        value: value
      });
    }
  });
  var currencies = computed(function () {
    var _$store$state;
    return (_$store$state = $store.state) === null || _$store$state === void 0 || (_$store$state = _$store$state[activeTableSelection.value]) === null || _$store$state === void 0 ? void 0 : _$store$state["".concat(props.tableType, "Currencies")];
  });
  var makeDateLabels = function makeDateLabels(date, index) {
    if (activePeriod.value === 0) {
      // its annual
      // if (arr.length - 1 === index) {
      //   return date.label
      // }
      if (index % 2) {
        return "";
      }
      return date.annualLabel;
    } else {
      // its quarters
      if (["fmp", "morningstar"].includes(activeTableSelection.value)) {
        if (date.quarterStr === "Q4") {
          var year = Number(date.yearStr);
          if (year % 2) {
            return date.annualLabel;
          } else {
            return "";
          }
        }
        return "";
      } else if (date.calendarquarter === 4) {
        return date.annualLabel;
      }
      return "";
    }
  };
  var makeThumbLabels = function makeThumbLabels(date) {
    if (activePeriod.value === 0) {
      // its annual
      return date ? date.text : "";
    } else {
      // its quarterly
      return date ? date.quarterLabel : "";
    }
  };
  var transformValuationTemplateIntoRows = function transformValuationTemplateIntoRows($store, template) {
    var _$store$state$ciq$tic, _$store$state$ciq$add;
    var tid = (_$store$state$ciq$tic = $store.state.ciq.ticker) === null || _$store$state$ciq$tic === void 0 ? void 0 : _$store$state$ciq$tic.tradingitemid;
    var datObj = ((_$store$state$ciq$add = $store.state.ciq.addMultiples) === null || _$store$state$ciq$add === void 0 ? void 0 : _$store$state$ciq$add[tid]) || {};
    var tblDataObj = datObj === null || datObj === void 0 ? void 0 : datObj.tblDataObj;
    return template.map(function (tblRows) {
      return tblRows.filter(function (row) {
        if (row.formula === "h3") {
          return true;
        }
        var denominatorKey = findMetricKey(row.denominator);
        var numeratorKey = findMetricKey(row.numerator);
        if (row.formula === "val") {
          return tblDataObj[denominatorKey];
        } else {
          return tblDataObj[denominatorKey] && tblDataObj[numeratorKey];
        }
      });
    });
  };
  return {
    decimals: decimals,
    formatNumberToDecimals: formatNumberToDecimals,
    formatPercentToDecimals: formatPercentToDecimals,
    desiredUnits: desiredUnits,
    activePeriod: activePeriod,
    activeTableSelection: activeTableSelection,
    currencyToggle: currencyToggle,
    currencies: currencies,
    currentIso: currentIso,
    dateRange: dateRange,
    makeDateLabels: makeDateLabels,
    makeThumbLabels: makeThumbLabels,
    reverseDates: reverseDates,
    findMetricKey: findMetricKey,
    transformValuationTemplateIntoRows: transformValuationTemplateIntoRows,
    fetching: fetching
  };
};
export default useCiqTableOptions;