import "core-js/modules/es.number.constructor.js";
import DiligenceMessage from "~/components/dueDiligence/DiligenceMessage.vue";
import DiligenceScore from "~/components/dueDiligence/DiligenceScore.vue";
import useBaseUtils from "~/functions/useBaseUtils";
import LockedDataMessage from "~/components/LockedDataMessage.vue";
export default {
  __name: 'DiligenceCategoryOverview',
  props: {
    label: {
      type: String,
      "default": ""
    },
    score: {
      type: Number,
      "default": 0
    },
    message: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    vars: {
      type: Object,
      "default": function _default() {}
    },
    disabled: {
      type: Boolean,
      "default": false
    },
    maxScore: {
      type: Number,
      "default": 100
    },
    locked: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    var isNonAvailableCategory = computed(function () {
      return props.disabled && props.maxScore === 0 && props.label === "Dividend";
    });
    return {
      __sfc: true,
      I18nFn: I18nFn,
      props: props,
      isNonAvailableCategory: isNonAvailableCategory,
      DiligenceMessage: DiligenceMessage,
      DiligenceScore: DiligenceScore,
      LockedDataMessage: LockedDataMessage
    };
  }
};