import "core-js/modules/es.number.constructor.js";
import useBreakpoints from "~/functions/useBreakpoints";
import useBaseUtils from "~/functions/useBaseUtils";
import LockedDataMessage from "~/components/LockedDataMessage.vue";
import DiligenceMessage from "~/components/dueDiligence/DiligenceMessage.vue";
export default {
  __name: 'DiligenceCheckItem',
  props: {
    name: {
      type: String,
      "default": ""
    },
    actualCheckScore: {
      type: Number,
      "default": 0
    },
    maxCheckScore: {
      type: Number,
      "default": 2
    },
    percentage: {
      type: Number,
      "default": 0
    },
    disabled: {
      type: Boolean,
      "default": true
    },
    message: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    variablesObject: {
      type: Object,
      "default": function _default() {}
    },
    company: {
      type: String,
      "default": ""
    },
    isAnyCellUnauth: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBreakpoints = useBreakpoints(),
      sm = _useBreakpoints.sm;
    var _useBaseUtils = useBaseUtils(),
      stylesDiligenceScore = _useBaseUtils.stylesDiligenceScore,
      toggleLightDarkMode = _useBaseUtils.toggleLightDarkMode;
    var unauthStyling = computed(function () {
      return {
        color: "#707070",
        background: toggleLightDarkMode("#e9e9e9", "#383838"),
        border: "1px solid ".concat(toggleLightDarkMode("#d9d9d9", "rgba(85,85,85,0.33)"))
      };
    });
    var chipData = computed(function () {
      if (props.isAnyCellUnauth) {
        return unauthStyling.value;
      }
      return stylesDiligenceScore.value[props.actualCheckScore];
    });
    return {
      __sfc: true,
      props: props,
      sm: sm,
      stylesDiligenceScore: stylesDiligenceScore,
      toggleLightDarkMode: toggleLightDarkMode,
      unauthStyling: unauthStyling,
      chipData: chipData,
      LockedDataMessage: LockedDataMessage,
      DiligenceMessage: DiligenceMessage
    };
  }
};