var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ skeleton: true, dark: _setup.darkActive },style:(_setup.wrapperStyle)},_vm._l((_setup.rows),function(item,idx){return _c('div',{key:`checkItem${item}`,class:{ item: true, 'is-mobile': !_setup.sm, 'is-overview': _vm.isOverviewTab }},[_c('div',{class:{
        header: true,
        'mb-3': _setup.sm,
      }},[_c('v-skeleton-loader',{class:{
          'mock-title': true,
          'mock-pill': !_vm.isOverviewTab && idx !== 0,
        },attrs:{"type":"table-cell"}}),_vm._v(" "),((!_setup.sm && idx !== 0) || (_vm.isOverviewTab && !_setup.sm))?_c('v-skeleton-loader',{staticClass:"ml-3",attrs:{"type":"table-cell"}}):_vm._e(),_vm._v(" "),(_vm.isOverviewTab || idx === 0)?_c('v-skeleton-loader',{staticClass:"mock-number",attrs:{"type":"table-cell"}}):_vm._e()],1),_vm._v(" "),((!_setup.sm && idx === 0 && !_vm.isOverviewTab) || _setup.sm)?_c('v-skeleton-loader',{staticClass:"mock-content",attrs:{"type":"list-item-three-line"}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }