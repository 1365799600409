import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import glossary from "~/utils/templates/glossary";
import { rowObjToSelectedKey } from "~/utils/ciq";
import { calculateCAGR } from "~/modules/ratios";
var useCiqTableUtils = function useCiqTableUtils() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var darkActive = computed(function () {
    return $store.state.config.darkMode;
  });
  var addRowToSelectedObject = function addRowToSelectedObject(row, rowId) {
    var period = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "a";
    var chartType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "financialsChart";
    var vuexModule = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "ciq";
    var payload = {
      row: row,
      rowId: rowId,
      chartType: chartType,
      period: period
    };
    $store.commit("".concat(vuexModule, "/addToChart"), payload);
  };
  var removeRowFromSelectedObject = function removeRowFromSelectedObject(rowId) {
    var period = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "a";
    var chartType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "financialsChart";
    var vuexModule = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "ciq"; // code to remove the finRow from the selectedRow Object
    // what happens if there is nothing here?
    // how to remove a key from and object resulting in a new copy...
    // need to invoke reactivity on removal of row
    // const selectedRows = this.selectedRows
    // const newObj = {}
    // const rowRemoved = Object.keys(selectedRows)
    //   .filter(f => f !== rowId)
    //   .reduce((acc, rowId) => {
    //     acc[rowId] = selectedRows[rowId]
    //     return acc
    //   }, newObj)
    // this.selectedRows = rowRemoved
    var payload = {
      rowId: rowId,
      period: period,
      chartType: chartType
    };
    $store.commit("".concat(vuexModule, "/removeFromChart"), payload);
  };
  var selectedCIQFinancialRows = computed(function () {
    var period = props.period;
    var rowObj = $store.state.ciq.financialsChart;
    if (rowObj[period]) {
      return rowObj[period] || {};
    } else {
      return {};
    }
  });
  var selectedMorningstarFinancialRows = computed(function () {
    var period = props.period;
    var rowObj = $store.state.morningstar.financialsChart;
    if (rowObj[period]) {
      return rowObj[period] || {};
    } else {
      return {};
    }
  });
  var selectedFMPFinancialRows = computed(function () {
    var period = props.period;
    var rowObj = $store.state.fmp.financialsChart;
    if (rowObj[period]) {
      return rowObj[period] || {};
    } else {
      return {};
    }
  });
  var selectedEstimateRows = computed(function () {
    var period = props.period;
    var rowObj = $store.state.ciq.estimatesChart;
    if (rowObj[period]) {
      return rowObj[period] || {};
    } else {
      return {};
    }
  });
  var selectedMultipleRows = computed(function () {
    var period = props.period;
    var rowObj = $store.state.ciq.multiplesChart;
    if (rowObj[period]) {
      return rowObj[period] || {};
    } else {
      return {};
    }
  });
  var nestedRowToSelectedKey = function nestedRowToSelectedKey(lineitem) {
    var segmentName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    var abbr = props.segType;
    return "".concat(abbr, "-").concat(lineitem.dataitemid, "-").concat(segmentName);
  };
  var generateRowClasses = function generateRowClasses(rowId, selectedRowsObj, darkMode, row) {
    var title = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
    var classArray = row.format.split(" ");
    if (!title) {
      classArray = [];
    } else if (classArray.includes("lineabove")) {
      if (darkMode) {
        classArray.push("whitelineabove");
      } else {
        classArray.push("blacklineabove");
      }
    } // const rowId = nestedRowToSelectedKey(row, segmentName)
    // does this need to be selectedRows.value?
    if (selectedRowsObj[rowId]) {
      classArray.push("clicked");
    }
    return classArray;
  };
  var determineDataObject = function determineDataObject(lineitem, dateObj) {
    var guidance = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var specialPeriod = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false; // Function goal - take the lineitem and dateObj and return
    // the correct object containing the dataitemvalue because the actual
    // data object is stored under the key 'mean' 'median' 'actual'... eventually high/low as well
    // First, determine the type of date (actual, mean, median)
    // FIXME: This is where you would perform logic to switch between mean and median
    var dateType = dateObj.isEstimate ? "mean" : "actual";
    if (specialPeriod) {
      dateType = "actual";
    } else if (guidance) {
      dateType = guidance;
    }
    var periodId = dateObj.value;
    var metricObj = lineitem[dateType]; // check lineitem[dataType], return the dataitemvalue object or undefined
    return metricObj ? metricObj[periodId] : undefined;
  };
  var estValCAGR = function estValCAGR(lineitem, dateHeaders) {
    var reverseDates = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var firstDate = reverseDates ? dateHeaders[dateHeaders.length - 1] : dateHeaders[0];
    var lastDate = reverseDates ? dateHeaders[0] : dateHeaders[dateHeaders.length - 1];
    var firstDataObj = determineDataObject(lineitem, firstDate);
    var lastDataObj = determineDataObject(lineitem, lastDate);
    if (firstDataObj && lastDataObj) {
      var _calculateCAGR = calculateCAGR({
          initialValue: firstDataObj.dataitemvalue,
          lastValue: lastDataObj.dataitemvalue,
          firstDate: firstDataObj.timeVal,
          lastDate: lastDataObj.timeVal
        }),
        cagr = _calculateCAGR.cagr;
      return cagr;
    }
  };
  var formatCagrClass = function formatCagrClass(CAGR, darkMode) {
    // CAGR should be a number
    var positiveColor = darkMode ? "accent--text" : "black--text";
    if (CAGR) {
      if (typeof CAGR === "string") {
        return parseFloat(CAGR) > 0 ? positiveColor : "red--text";
      } else if (typeof CAGR === "number") {
        return CAGR > 0 ? positiveColor : "red--text";
      }
    }
  };
  var formatEstimateClass = function formatEstimateClass(lineitem, dateObj, darkMode) {
    var data = determineDataObject(lineitem, dateObj);
    var positiveColor = darkMode ? "accent--text" : "black--text";
    if (data) {
      if (typeof data.dataitemvalue === "string") {
        return parseFloat(data.dataitemvalue) * lineitem.multiplyby > 0 ? positiveColor : "red--text";
      } else if (typeof data.dataitemvalue === "number") {
        return data.dataitemvalue * lineitem.multiplyby > 0 ? positiveColor : "red--text";
      }
    }
  }; // FIXME: this is a bad name
  var formatFinancialClass = function formatFinancialClass(fin, date, darkMode) {
    var data = date ? fin[date] : undefined;
    var positiveColor = darkMode ? "accent--text" : "black--text";
    if (data) {
      if (typeof data.v === "string") {
        var d = parseFloat(data.v) * fin.multiplyby;
        return d > 0 ? positiveColor : "red--text";
      } else if (typeof data.v === "number") {
        var _d = data.v * fin.multiplyby;
        return _d > 0 ? positiveColor : "red--text";
      }
    }
  };
  var formatCiqDataObj = function formatCiqDataObj(data, lineitem) {
    // const dateType = dateObj.value
    var positiveColor = darkActive.value ? "accent--text" : "black--text"; // console.log("data in formatFinancialClass", data)
    if (data) {
      if (typeof data.dataitemvalue === "string") {
        return parseFloat(data.dataitemvalue) * lineitem.multiplyby > 0 ? positiveColor : "red--text";
      } else if (typeof data.dataitemvalue === "number") {
        return data.dataitemvalue * lineitem.multiplyby > 0 ? positiveColor : "red--text";
      }
    }
  };
  return {
    glossary: glossary,
    addRowToSelectedObject: addRowToSelectedObject,
    removeRowFromSelectedObject: removeRowFromSelectedObject,
    selectedCIQFinancialRows: selectedCIQFinancialRows,
    selectedFMPFinancialRows: selectedFMPFinancialRows,
    selectedMorningstarFinancialRows: selectedMorningstarFinancialRows,
    selectedEstimateRows: selectedEstimateRows,
    nestedRowToSelectedKey: nestedRowToSelectedKey,
    rowObjToSelectedKey: rowObjToSelectedKey,
    generateRowClasses: generateRowClasses,
    formatFinancialClass: formatFinancialClass,
    formatEstimateClass: formatEstimateClass,
    formatCiqDataObj: formatCiqDataObj,
    determineDataObject: determineDataObject,
    estValCAGR: estValCAGR,
    formatCagrClass: formatCagrClass,
    selectedMultipleRows: selectedMultipleRows
  };
};
export default useCiqTableUtils;