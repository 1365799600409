var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:(_setup.categoryStyles)},[_c('h3',{staticClass:"category-title"},[_vm._v(_vm._s(_setup.props.title))]),_vm._v(" "),_c('ul',{class:{
      'metric-items-wrapper': true,
      'is-sm': _setup.sm && _setup.props.size !== 1,
    },style:({
      '--size': _setup.props.size || 1,
      '--gap': _vm.isDense ? '32px' : '24px',
      '--padding': _vm.isDense ? '0.25em' : '0.4em',
    })},_vm._l((_setup.metricList),function(metric){return _c('li',{key:metric.key},[_c('OverviewMetricDisplay',_vm._b({attrs:{"hide-for-business-mode":metric.hideForBusinessMode && _vm.businessModeActive}},'OverviewMetricDisplay',metric,false))],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }