export default {
  props: {
    charttype: { type: String, default: "Quotes" },
    chartsymbol: { type: String, default: "" },
  },
  // data: function () {
  //   return {
  //     hideself: false
  //   }
  // },
  computed: {
    items() {
      const results = this.entities
      const chosenTickers = this.addTickersForAutoComplete
      const allResults = results.concat(chosenTickers)
      return allResults
    },
    // addTickersForChart defined on each chart as some charts require
    // periodTypesWhileOthers do not
    // addTickersForChart() {
    //   return this.$store.state.ciq[`${this.type}Tickers`][this.period] || {}
    //   return this.$store.state.ciq.quotesChartTickers
    //   return this.$store.state.ciq.multiplesChartTickers || {}
    // },
    addTickersForAutoComplete: {
      get() {
        return Object.keys(this.addTickersForChart).reduce((acc, id) => {
          acc.push(this.addTickersForChart[id])
          return acc
        }, [])
      },
      set() {
        // console.log("addTickersForAutoComplete setter: ", e)
        return Object.keys(this.addTickersForChart).reduce((acc, id) => {
          acc.push(this.addTickersForChart[id])
          return acc
        }, [])
      },
    },
    fetchingAddTicker() {
      return this.$store.state.ciq[`fetchingAdd${this.charttype}`]
        ? "primaryAction"
        : false
    },
    type() {
      // watchout this is camel case and different from props
      return `${this.charttype.toLowerCase()}Chart`
    },
  },
  methods: {
    customFilter(f) {
      // remove the already selected items from the autocomplete
      // select while allowing them to be present
      // for multiple chips
      const alreadyFetched = this.addTickersForChart
      const activeTicker = this.$store.state.ciq.ticker || {}
      if (
        activeTicker.tradingitemid &&
        f.tradingitemid === activeTicker.tradingitemid
      ) {
        return false
      }
      return !alreadyFetched[f.tradingitemid]
    },
  },
}
