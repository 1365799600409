import { getDimensionMsg, getDimensionScore } from "~/utils/dueDiligence/utils"

const getQualityMessage = (score) => {
  const tag1 = getDimensionMsg(
    score,
    "Indicates high quality company with strong competitive advantages",
    "Fair business quality",
    "Poor business quality"
  )
  const tag2 = getDimensionMsg(score, "that has", "with", "with")
  const tag = getDimensionMsg(
    score,
    "",
    "",
    "Company likely operates in a commoditized, competitive, and/or cyclical industry indicating higher business risk"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        {
          tag: "span",
          value: tag1,
        },
      ],
    },
    {
      requiredVariables: ["grossMargins"],
      messageArray: [
        { tag: "span", value: tag2 },
        { tag: "span", customProp: true, value: "grossMargins" },
        {
          tag: "span",
          value: "Gross Margins",
          highlightMetric: "grossMargins",
          to: {
            source: "financials",
            tab: "is",
          },
        },
      ],
    },
    {
      requiredVariables: ["roc"],
      messageArray: [
        { tag: "span", value: "and" },
        { tag: "span", customProp: true, value: "roc" },
        {
          tag: "span",
          value: "Returns on Capital",
          // which of the required variables should this be linked with
          highlightMetric: "roc",
          to: {
            source: "financials",
            tab: "r",
          },
        },
        { tag: "span", value: tag, prepend: "." },
      ],
    },
  ]
}

const getGrowthMessage = (score, isEpsNegative) => {
  const partOne = getDimensionMsg(
    score,
    "Rapid growth",
    "Company experienced",
    "Weak growth score as company likely operates in a mature, competitive, or cyclical industry with limited growth prospects."
  )
  const partTwo = getDimensionMsg(
    score,
    "CAGR over the past 5 years. Requires further qualitative research but highlights a business that may have strong growth prospects",
    "CAGR over the past 5 years.",
    "CAGR over the past 5 years."
  )
  const withStr = getDimensionMsg(score, "with", "", "")

  const defaultObj = [
    {
      requiredVariables: [],
      messageArray: [{ tag: "span", value: partOne }],
    },
    {
      requiredVariables: ["revenues"],
      messageArray: [
        { tag: "span", value: withStr },
        { tag: "span", customProp: true, value: "revenues" },
        {
          tag: "span",
          value: "revenues",
          highlightMetric: "revenues",
          to: { source: "estimates", tab: "est" },
        },
      ],
    },
  ]

  if (!isEpsNegative) {
    defaultObj.push({
      requiredVariables: ["eps"],
      messageArray: [
        { tag: "span", value: "and" },
        { tag: "span", customProp: true, value: "eps" },
        {
          tag: "span",
          value: "EPS",
          highlightMetric: "eps",
          to: {
            source: "estimates",
            tab: "est",
          },
        },
      ],
    })
  }

  const lastPart = {
    requiredVariables: ["revenues", "eps"],
    messageArray: [
      {
        tag: "span",
        value: partTwo,
      },
    ],
  }

  defaultObj.push(lastPart)

  return defaultObj
}

const getForecastMessage = (score, isEpsNegative) => {
  const { isNeutral } = getDimensionScore(score)
  const partOne = getDimensionMsg(score, "rapid growth ", "", "weak growth ")
  const withVar = isNeutral ? "" : "with"

  const defaultObj = [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "span", value: "Wall Street analysts forecast" },
        {
          tag: "span",
          value: partOne,
        },
      ],
    },
    {
      requiredVariables: ["revenues"],
      messageArray: [
        { tag: "span", value: withVar },
        { tag: "span", customProp: true, value: "revenues" },
        {
          tag: "span",
          value: "revenues",
          highlightMetric: "revenues",
          to: { source: "estimates", tab: "est" },
        },
      ],
    },
  ]

  if (!isEpsNegative) {
    defaultObj.push({
      requiredVariables: ["eps"],
      messageArray: [
        { tag: "span", value: "and" },
        { tag: "span", customProp: true, value: "eps" },
        {
          tag: "span",
          value: "Earnings Per Share",
          highlightMetric: "eps",
          to: {
            source: "estimates",
            tab: "est",
          },
        },
      ],
    })
  }

  defaultObj.push({
    requiredVariables: ["revenues", "eps"],
    messageArray: [{ tag: "span", value: "CAGR over next 2 years" }],
  })

  return defaultObj
}

const getValuationMessage = (
  score,
  isFirstParamAbove,
  isSecondParamAbove,
  ntmPriceOverEarnings
) => {
  const msg = getDimensionMsg(
    score,
    "Potentially undervalued",
    "Appears fairly valued",
    "Potentially overvalued"
  )

  const finalMsg = [
    {
      requiredVariables: [],
      messageArray: [
        {
          tag: "span",
          value: msg,
        },
      ],
    },
    {
      requiredVariables: ["evRevFiveYearAvg"],
      messageArray: [
        { tag: "span", value: "with its NTM" },
        {
          tag: "span",
          value: "EV/Revenue",
          highlightMetric: "evRevFiveYearAvg",
          to: {
            source: "multiples",
            tab: "multi",
          },
        },
        { tag: "span", value: "multiple" },
        { tag: "span", customProp: true, value: "evRevFiveYearAvg" },
        {
          tag: "span",
          value: `${isFirstParamAbove ? "above" : "below"} its 5-Year Average`,
        },
      ],
    },
  ]

  if (ntmPriceOverEarnings > 0) {
    finalMsg.push({
      requiredVariables: [
        "evRevFiveYearAvg",
        "ntmPriceOverEarningsFiveYearAvgComparison",
      ],
      messageArray: [
        { tag: "span", value: "and its NTM" },
        {
          tag: "span",
          value: "P/E",
          highlightMetric: "evRevFiveYearAvg",
          to: {
            source: "multiples",
            tab: "multi",
          },
        },
        { tag: "span", value: "multiple" },
        {
          tag: "span",
          customProp: true,
          value: "ntmPriceOverEarningsFiveYearAvgComparison",
        },
        {
          tag: "span",
          value: `${
            isSecondParamAbove ? "above" : "below"
          } it's 5-Year Average`,
        },
      ],
    })
  }

  return finalMsg
}

const getSafetyMessage = (score) => {
  const partOne = getDimensionMsg(
    score,
    "Company appears to have a healthy balance sheet with limited financial risks",
    "Company has a fair balance sheet but should be evaluated further for potential financial risks",
    "Company appears to have a weak balance sheet with potential financial risks"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [{ tag: "span", value: partOne }],
    },
  ]
}

const getDividendMessage = (score) => {
  const pt1 = getDimensionMsg(
    score,
    "Company’s dividends seem safe",
    "Company ",
    "Company’s dividends may be at risk."
  )
  const pt2 = getDimensionMsg(
    score,
    "with LTM",
    "has a LTM Dividend",
    "Its LTM"
  )
  const pt3 = getDimensionMsg(score, "of", "of", "is")
  return [
    {
      requiredVariables: [],
      messageArray: [
        {
          tag: "span",
          value: pt1,
        },
      ],
    },
    {
      requiredVariables: ["payoutRatio"],
      messageArray: [
        { tag: "span", value: pt2 },
        {
          tag: "span",
          value: "Payout Ratio",
          highlightMetric: "payoutRatio",
          to: { source: "financials", tab: "is" },
        },
        { tag: "span", value: pt3 },
        { tag: "span", customProp: true, value: "payoutRatio", append: "." },
      ],
    },
    {
      requiredVariables: ["dps"],
      messageArray: [
        {
          tag: "span",
          value: "Dividends Per Share",
          highlightMetric: "dps",
          to: {
            source: "estimates",
            tab: "est",
          },
        },
        {
          tag: "span",
          value: "CAGR of ",
        },
        {
          tag: "span",
          customProp: true,
          value: "dps",
        },
      ],
    },
    {
      requiredVariables: ["dpsFiveYearsAgo", "dpsLast"],
      messageArray: [
        {
          tag: "span",
          customProp: true,
          prepend: "(",
          value: "dpsFiveYearsAgo",
        },
        {
          tag: "span",
          value: "to",
        },
        {
          tag: "span",
          customProp: true,
          value: "dpsLast",
        },
        {
          tag: "span",
          value: " over the past 5 years)",
        },
      ],
    },
  ]
}

export {
  getQualityMessage,
  getGrowthMessage,
  getForecastMessage,
  getValuationMessage,
  getSafetyMessage,
  getDividendMessage,
}
