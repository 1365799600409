import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.is-integer.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { isString } from "lodash";
import DiligenceLink from "./DiligenceLink.vue";
import { MILLION } from "~/utils/constants/numbers";
import UnauthorizedIcon from "~/components/UnauthorizedIcon.vue";
import useNumberFormatters from "~/functions/useNumberFormatters";
export default {
  __name: 'DiligenceMessage',
  props: {
    message: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    vars: {
      type: Object,
      "default": function _default() {}
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useNumberFormatters = useNumberFormatters(),
      formatNumberTwoDecimals = _useNumberFormatters.formatNumberTwoDecimals,
      formatInt = _useNumberFormatters.formatInt;
    var formattedNumber = function formattedNumber(obj) {
      if (obj.formatted) {
        return obj.formatted;
      } else {
        var formatter = Number.isInteger(obj.v) ? formatInt.value : formatNumberTwoDecimals.value;
        return formatter.format(obj.v);
      }
    };
    var computedStyle = function computedStyle(partial) {
      return {
        "margin-left": partial !== null && partial !== void 0 && partial.prepend ? "-4px" : "unset",
        "margin-right": partial !== null && partial !== void 0 && partial.append ? "-4px" : "unset"
      };
    };
    var formattedValue = function formattedValue(obj) {
      return isString(obj) ? obj : formattedNumber(obj);
    };
    var checkValidPartial = function checkValidPartial(requiredVariables, vars) {
      return (requiredVariables === null || requiredVariables === void 0 ? void 0 : requiredVariables.length) > 0 ? requiredVariables.every(function (element) {
        var object = vars === null || vars === void 0 ? void 0 : vars[element];
        return !Number.isNaN(object === null || object === void 0 ? void 0 : object.v);
      }) : true;
    };
    var getRandom = function getRandom() {
      return Math.random() * MILLION;
    };
    var getItemKey = function getItemKey(item) {
      return "".concat((item === null || item === void 0 ? void 0 : item.key) || (item === null || item === void 0 ? void 0 : item.value), "-").concat(getRandom());
    };
    var msg = computed(function () {
      return props.message.reduce(function (acc, item) {
        var isValid = checkValidPartial(item === null || item === void 0 ? void 0 : item.requiredVariables, props.vars);
        if (isValid) {
          var mappedMessageArr = item.messageArray.map(function (partial) {
            var _partial$to;
            var isValidCustomPropWithLink = (partial === null || partial === void 0 || (_partial$to = partial.to) === null || _partial$to === void 0 ? void 0 : _partial$to.source) && partial.highlightMetric;
            var extraData = {};
            if (isValidCustomPropWithLink) {
              var _props$vars$partial$h;
              var cellObj = ((_props$vars$partial$h = props.vars[partial.highlightMetric]) === null || _props$vars$partial$h === void 0 ? void 0 : _props$vars$partial$h[0]) || props.vars[partial.highlightMetric];
              var cellName = cellObj === null || cellObj === void 0 ? void 0 : cellObj._cellName;
              var sourceName = cellObj === null || cellObj === void 0 ? void 0 : cellObj._sourceName;
              var tableNameId = cellObj === null || cellObj === void 0 ? void 0 : cellObj._tableNameId;
              extraData._cellName = cellName;
              extraData._sourceName = sourceName;
              extraData._tableNameId = tableNameId;
            }
            return _objectSpread(_objectSpread(_objectSpread({}, partial), extraData), {}, {
              key: getItemKey(partial)
            });
          });
          acc.push.apply(acc, _toConsumableArray(mappedMessageArr));
        }
        return acc;
      }, []);
    });
    return {
      __sfc: true,
      props: props,
      formatNumberTwoDecimals: formatNumberTwoDecimals,
      formatInt: formatInt,
      formattedNumber: formattedNumber,
      computedStyle: computedStyle,
      formattedValue: formattedValue,
      checkValidPartial: checkValidPartial,
      getRandom: getRandom,
      getItemKey: getItemKey,
      msg: msg,
      DiligenceLink: DiligenceLink,
      UnauthorizedIcon: UnauthorizedIcon
    };
  }
};