import "core-js/modules/es.number.constructor.js";
import useBaseUtils from "~/functions/useBaseUtils";
import { getDimensionScore } from "~/utils/dueDiligence/utils";
export default {
  __name: 'DiligenceScore',
  props: {
    score: {
      type: Number,
      "default": 0
    },
    locked: {
      type: Boolean,
      "default": false
    },
    nonAvailable: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      toggleLightDarkMode = _useBaseUtils.toggleLightDarkMode,
      stylesDiligenceScore = _useBaseUtils.stylesDiligenceScore;
    var lockedStyle = computed(function () {
      return {
        color: toggleLightDarkMode("#707070", "#666"),
        background: toggleLightDarkMode("#e9e9e9", "#383838"),
        border: "1px solid ".concat(toggleLightDarkMode("#D9D9D9", "#555"))
      };
    });
    var getIndex = function getIndex(isBullish, isNeutral) {
      return isBullish ? 2 : isNeutral ? 1 : 0;
    };
    var scoreStyling = computed(function () {
      if (props.locked || props.nonAvailable) {
        return lockedStyle.value;
      }
      var score = props.score;
      var _getDimensionScore = getDimensionScore(score),
        isBullish = _getDimensionScore.isBullish,
        isNeutral = _getDimensionScore.isNeutral;
      var index = getIndex(isBullish, isNeutral);
      return stylesDiligenceScore.value[index];
    });
    return {
      __sfc: true,
      props: props,
      toggleLightDarkMode: toggleLightDarkMode,
      stylesDiligenceScore: stylesDiligenceScore,
      lockedStyle: lockedStyle,
      getIndex: getIndex,
      scoreStyling: scoreStyling
    };
  }
};