import useBaseUtils from "~/functions/useBaseUtils"
import useBreakpoints from "~/functions/useBreakpoints"


export default {
  __name: 'WarningMsg',
  props: {
  title: { type: String, default: "Warning:" },
  message: { type: String, default: "" },
  tooltipText: { type: String, default: "" },
},
  setup(__props) {


const { I18nFn } = useBaseUtils()
const { lg } = useBreakpoints()

return { __sfc: true,I18nFn, lg }
}

}