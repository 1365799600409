var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ 'wrapper-overview': true, dark: _setup.darkActive },style:(_setup.wrapperStyling)},[_c('div',{staticClass:"overview-summary",style:(_setup.summaryStyling)},[_c('div',{staticClass:"overview-summary-content"},[_c('div',{class:{ header: true, 'mb-3': _setup.lg, 'text-center': _setup.sm }},[_c('h3',{staticClass:"text-center"},[_vm._v("TIKR Diligence Score")]),_vm._v(" "),(_vm.disabled)?_c('div',{class:{
            'text-center': true,
            'mt-2': true,
            'd-inline-flex': _setup.sm && !_setup.lg,
          }},[_c('v-icon',{attrs:{"color":_setup.iconColor}},[_vm._v("mdi-lock")]),_vm._v(" "),(_setup.lg || !_setup.sm)?_c('br'):_vm._e(),_vm._v(" "),_c('nuxt-link',{class:{
              'upgrade-link': true,
              'font-weight-bold': true,
              'mt-2': _setup.lg || !_setup.sm,
            },attrs:{"to":`/account/subs?ref=${_setup.refCode}`}},[_vm._v("\n            Upgrade Plan to View\n          ")])],1):_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.score))])]),_vm._v(" "),_c(_setup.DiligenceThermometer,{attrs:{"vertical":!_setup.lg,"score":_vm.score,"disabled":_vm.disabled}})],1)]),_vm._v(" "),_c('div',{staticClass:"overview-list",style:(_setup.listStyling)},[(!_setup.sm)?[_c('v-expansion-panels',{attrs:{"flat":"","multiple":""},model:{value:(_setup.openedPanels),callback:function ($$v) {_setup.openedPanels=$$v},expression:"openedPanels"}},_vm._l((_setup.dimensionList),function(item,key){return _c('v-expansion-panel',{key:key},[_c('v-expansion-panel-header',[_c('div',{class:{
                'score-wrapper': true,
                'mr-3': true,
                locked: item.unauthDimension,
              }},[_c(_setup.DiligenceScore,{attrs:{"locked":item.unauthDimension,"score":item.actualDimensionScore,"non-available":item.actualDimensionScore === 0 &&
                  item.maxDimensionScore === 0 &&
                  item.label === 'Dividend'}})],1),_vm._v(" "),_c('h4',{staticClass:"font-weight-medium"},[_vm._v("\n              "+_vm._s(key)+"\n            ")])]),_vm._v(" "),_c('v-expansion-panel-content',{staticClass:"content-mobile"},[(item.unauthDimension)?_c(_setup.LockedDataMessage):_c(_setup.DiligenceMessage,{attrs:{"message":item.dimensionMessage,"vars":item.dimensionVars}})],1)],1)}),1)]:_vm._l((_setup.dimensionList),function(item,key){return _c(_setup.DiligenceCategoryOverview,{key:key,attrs:{"label":key,"score":item.actualDimensionScore,"message":item.dimensionMessage,"vars":item.dimensionVars,"disabled":!item.validDimension,"max-score":item.maxDimensionScore,"locked":item.unauthDimension}})})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }