import { isString, isObject, isArray } from "lodash"
import OverviewMetricSkeleton from "~/components/overviewMetrics/OverviewMetricSkeleton.vue"
import useMetrics from "~/functions/useMetrics"
import useBaseUtils from "~/functions/useBaseUtils"
import useCommonStrings from "~/functions/useCommonStrings"
import { objectHasAllKeys } from "~/utils/tools/object"


export default {
  __name: 'OverviewMetricDisplay',
  props: {
  title: { type: String, default: "" },
  periodKey: { type: String, default: "a" }, // "annual", "quarterly"
  metricKeys: {
    type: Array,
    default: () => [],
    validator(values) {
      const hasAtLeastOneKey = values.length > 0
      const allValuesHaveName =
        values.filter((obj) => obj.name).length === values.length
      const allValuesHaveValidSourceProp = values.filter((obj) =>
        ["financials", "trading", "estimates", "company", "valuation"].includes(
          isArray(obj.source) ? obj.source[0].source : obj.source
        )
      )
      return (
        hasAtLeastOneKey && allValuesHaveName && allValuesHaveValidSourceProp
      )
    },
  },
  formula: {
    type: [String, Object],
    default: "val",
    validator(value) {
      if (isString(value)) {
        return [
          "val",
          "div",
          "yield",
          "sum",
          "avg",
          "computeHigh",
          "computeLow",
          "dxdt",
          "YoY",
          "YoYdiv",
        ].includes(value)
      } else if (isObject(value)) {
        const neededProps = {
          cagr: ["startRelativePeriod", "endRelativePeriod"],
          custom: ["evaluate"],
        }
        return (
          Object.keys(neededProps).includes(value.type) &&
          objectHasAllKeys(value, neededProps[value.type])
        )
      }
    },
  },
  formatType: { type: String, default: "val" }, // float, int, val? , turns, pct
  units: { type: String, default: "" },
  actualOrEstimateKey: { type: String, default: "actual" }, // "actual", "mean", "median", "high", "low", "stDev", "numEst"
  relativeDateKey: { type: Number, default: 0 }, // -2, -1, 0, +1, +2
  desiredDateKey: { type: String, default: "" },
  relativeDate: { type: String, default: "" },
  hideIf: { type: Array, default: () => [] },
  hideForBusinessMode: { type: Boolean, default: false },
  showLoader: { type: Boolean, default: true },
  ntm: { type: Boolean, default: false },
},
  setup(__props) {

const props = __props;



const { label, loading, show, unauth, cellValue, isValid } = useMetrics(props)
const { I18nFn } = useBaseUtils()
const { priceHiddenString } = useCommonStrings()

return { __sfc: true,props, label, loading, show, unauth, cellValue, isValid, I18nFn, priceHiddenString, OverviewMetricSkeleton }
}

}