import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'OverviewMetrics',
  props: {
    businessModeActive: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var categories = [{
      title: "Market Data",
      metrics: [{
        title: "52 Week High",
        metricKeys: [{
          name: "high",
          source: "trading"
        }],
        formatType: "currency",
        hideForBusinessMode: true
      }, {
        title: "52 Week Low",
        metricKeys: [{
          name: "low",
          source: "trading"
        }],
        formatType: "currency",
        hideForBusinessMode: true
      }, {
        title: "Avg. 3 Month Volume",
        metricKeys: [{
          name: "vol3mo",
          source: "trading"
        }],
        units: "MM"
      }, {
        title: "5 Yr Beta",
        metricKeys: [{
          name: "beta",
          source: "financials"
        }],
        formatType: "float"
      }, {
        title: "Float %",
        metricKeys: [{
          name: "float (%)",
          source: "financials"
        }],
        formatType: "whole_pct"
      }]
    }, {
      title: "Capital Structure",
      metrics: [{
        title: "Market Cap (MM)",
        metricKeys: [{
          name: "marketcap",
          source: "company"
        }],
        formatType: "currency",
        hideForBusinessMode: true
      }, {
        title: "Enterprise Value (MM)",
        metricKeys: [{
          name: "tev",
          source: "company"
        }],
        formatType: "currency",
        hideForBusinessMode: true
      }, {
        title: "Shares Outstanding",
        metricKeys: [{
          name: "sharesoutstanding",
          source: "company"
        }],
        formatType: "float",
        formula: "val",
        units: "MM"
      }, {
        title: "LTM Net Debt (MM)",
        metricKeys: [{
          name: "net debt 4364",
          source: "financials"
        }],
        formatType: "currency",
        relativeDate: "ltm",
        units: "$MM"
      }, {
        title: "LTM Net Debt/EBITDA",
        formatType: "turns",
        relativeDate: "ltm",
        metricKeys: [{
          name: "net debt / ebitda 4193",
          source: "financials"
        }]
      }]
    }, {
      title: "Efficiency",
      metrics: [{
        title: "LTM Gross Margin",
        formula: "val",
        metricKeys: [{
          name: "gross profit margin % 4074",
          source: "financials"
        }],
        formatType: "whole_pct",
        relativeDate: "ltm"
      }, {
        title: "LTM EBIT Margin",
        formula: "val",
        metricKeys: [{
          name: "ebit margin % 4053",
          source: "financials"
        }],
        formatType: "whole_pct",
        relativeDate: "ltm"
      }, {
        title: "LTM ROA",
        metricKeys: [{
          name: "return on assets % 4178",
          source: "financials"
        }],
        formatType: "whole_pct",
        relativeDate: "ltm"
      }, {
        title: "LTM ROE",
        metricKeys: [{
          name: "return on equity % 4128",
          source: "financials"
        }],
        formatType: "whole_pct",
        relativeDate: "ltm"
      }, {
        title: "LTM ROIC",
        metricKeys: [{
          name: "return on capital % 43905",
          source: "financials"
        }],
        formatType: "whole_pct",
        relativeDate: "ltm"
      }, {
        title: "LTM ROCE",
        metricKeys: [{
          name: "operating income",
          aliasId: "operatingIncome",
          source: "financials"
        }, {
          name: "total assets 1007",
          aliasId: "totalAssets",
          source: "financials"
        }, {
          name: "total current liabilities 1009",
          aliasId: "currentLiabilities",
          source: "financials"
        }],
        formula: {
          type: "custom",
          evaluate: "* 100 (/ {{ operatingIncome }} (- {{ totalAssets }} {{ currentLiabilities }}))"
        },
        formatType: "whole_pct",
        relativeDate: "ltm"
      }]
    }, {
      title: "Growth",
      metrics: [{
        title: "Fwd 2-Yr Rev. CAGR",
        metricKeys: [{
          name: "revenue act.",
          source: "estimates"
        }],
        formula: {
          type: "cagr",
          startRelativePeriod: 0,
          endRelativePeriod: +2
        }
      }, {
        title: "Fwd 2-Yr EBITDA CAGR",
        metricKeys: [{
          name: "ebitda act.",
          source: "estimates"
        }],
        formula: {
          type: "cagr",
          startRelativePeriod: 0,
          endRelativePeriod: +2
        }
      }, {
        title: "Fwd 2-Yr EPS CAGR",
        metricKeys: [{
          name: "eps normalized act.",
          source: "estimates"
        }],
        formula: {
          type: "cagr",
          startRelativePeriod: 0,
          endRelativePeriod: +2
        }
      }, {
        title: "Last 3-Yr Rev. CAGR",
        metricKeys: [{
          name: "revenue",
          source: [{
            metricKey: "revenue act.",
            source: "estimates"
          }, {
            metricKey: "total revenues 28",
            source: "financials"
          }]
        }],
        formula: {
          type: "cagr",
          startRelativePeriod: -3,
          endRelativePeriod: 0
        }
      }, {
        title: "Last 3-Yr EBITDA CAGR",
        metricKeys: [{
          name: "ebitda",
          source: [{
            metricKey: "ebitda act.",
            source: "estimates"
          }, {
            metricKey: "ebitda 4051",
            source: "financials"
          }]
        }],
        formula: {
          type: "cagr",
          startRelativePeriod: -3,
          endRelativePeriod: 0
        }
      }, {
        title: "Last 3-Yr EPS CAGR",
        metricKeys: [{
          name: "eps",
          source: [{
            metricKey: "eps normalized act.",
            source: "estimates"
          }, {
            metricKey: "diluted eps excl extra items 142",
            source: "financials"
          }]
        }],
        formula: {
          type: "cagr",
          startRelativePeriod: -3,
          endRelativePeriod: 0
        }
      }]
    }, {
      title: "Valuation",
      size: 2,
      metrics: [{
        title: "Street Target Price",
        metricKeys: [{
          name: "target stock price mean",
          source: "valuation"
        }],
        formula: "val",
        formatType: "currency",
        hideForBusinessMode: true
      }, {
        title: "EV/Revenues",
        metricKeys: [{
          name: "tev",
          source: "valuation"
        }, {
          name: "revenue",
          source: "valuation"
        }],
        formula: "div",
        formatType: "turns",
        hideForBusinessMode: true,
        ntm: true
      }, {
        title: "EV/EBITDA",
        metricKeys: [{
          name: "tev",
          source: "valuation"
        }, {
          name: "ebitda",
          source: [{
            metricKey: "ebitda",
            exact: true,
            source: "valuation"
          }, {
            metricKey: "ebitda",
            source: "valuation"
          }]
        }],
        formula: "div",
        formatType: "turns",
        hideForBusinessMode: true,
        ntm: true
      }, {
        title: "P/E",
        metricKeys: [{
          name: "priceclose",
          source: "valuation"
        }, {
          name: "eps normalized",
          source: "valuation"
        }],
        formula: "div",
        formatType: "turns",
        hideForBusinessMode: true,
        ntm: true
      }, {
        title: "MC/FCF",
        metricKeys: [{
          name: "mc",
          source: "valuation"
        }, {
          name: "free cash flow",
          source: [{
            metricKey: "free cash flow",
            exact: true,
            source: "valuation"
          }, {
            metricKey: "fin levered free cash flow",
            source: "valuation"
          }]
        }],
        formula: "div",
        formatType: "turns",
        hideForBusinessMode: true,
        ntm: true
      }, {
        title: "LTM EV/Revenues",
        metricKeys: [{
          name: "tev",
          source: "valuation"
        }, {
          name: "total revenues",
          source: "valuation"
        }],
        formula: "div",
        formatType: "turns",
        hideForBusinessMode: true
      }, {
        title: "LTM EV/Gross Profit",
        formula: "div",
        metricKeys: [{
          name: "tev",
          source: "valuation"
        }, {
          name: "gross profit 10",
          source: "valuation"
        }],
        relativeDate: "ltm",
        formatType: "turns",
        hideForBusinessMode: true
      }, {
        title: "LTM P/E",
        metricKeys: [{
          name: "priceclose",
          source: "valuation"
        }, {
          name: "diluted eps excl extra items 142",
          source: "valuation"
        }],
        formula: "div",
        formatType: "turns",
        hideForBusinessMode: true
      }, {
        title: "LTM P/BV",
        metricKeys: [{
          name: "priceclose",
          source: "valuation"
        }, {
          name: "book value/share 4020",
          source: "valuation"
        }],
        formula: "div",
        formatType: "turns",
        hideForBusinessMode: true
      }, {
        title: "LTM P/NCAV",
        metricKeys: [{
          name: "priceclose",
          source: "valuation"
        }, {
          name: "ltm_ncav",
          source: "valuation"
        }],
        relativeDate: "ltm",
        formula: "div",
        formatType: "turns",
        hideForBusinessMode: true
      }, {
        title: "Dividend Yield",
        metricKeys: [{
          name: "dividends per share 3058",
          source: "financials"
        }],
        formula: "yield",
        hideForBusinessMode: true
      }, {
        title: "Payout Ratio",
        metricKeys: [{
          name: "payout ratio % 4377",
          source: "financials"
        }],
        relativeDate: "ltm",
        formatType: "whole_pct",
        hideForBusinessMode: true
      }]
    }];
    var _useBaseUtils = useBaseUtils(),
      toPascalCase = _useBaseUtils.toPascalCase;
    var _useNuxtApp = useNuxtApp(),
      $vuetify = _useNuxtApp.$vuetify;
    var isTwoColumns = computed(function () {
      var lgAndUp = $vuetify.breakpoint.lgAndUp;
      return lgAndUp;
    });
    var setSmBpSize = function setSmBpSize() {
      var multiplicator = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var defaultSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      return multiplicator * defaultSize;
    };
    var setMdBpSize = function setMdBpSize(isTwoColumns, isOdd, isLast) {
      return isTwoColumns ? isOdd && isLast ? 12 : 6 : null;
    };
    var isLast = function isLast(idx, arr) {
      return idx === Object.keys(arr).length - 1;
    };
    var getClasses = function getClasses(_ref) {
      var isLast = _ref.isLast,
        breakpointObj = _ref.breakpointObj,
        index = _ref.index;
      var isLgAndUp = breakpointObj === null || breakpointObj === void 0 ? void 0 : breakpointObj.lgAndUp;
      var isOdd = index % 2 !== 0;
      if (isLast && isLgAndUp) {
        return "pt-0";
      } else if (isLgAndUp) {
        return isOdd ? "pl-2" : "pr-2";
      }
      return "";
    };
    var computedCategories = computed(function () {
      return categories.map(function (cat, idx) {
        var last = isLast(idx, categories);
        return _objectSpread(_objectSpread({}, cat), {}, {
          key: "metricCategory".concat(toPascalCase(cat.title)),
          sm: setSmBpSize(cat.size, 4),
          md: setMdBpSize(isTwoColumns.value, categories.length % 2 !== 0, last),
          "class": getClasses({
            index: idx,
            isLast: last,
            breakpointObj: $vuetify.breakpoint
          })
        });
      });
    });
    return {
      __sfc: true,
      categories: categories,
      toPascalCase: toPascalCase,
      $vuetify: $vuetify,
      isTwoColumns: isTwoColumns,
      setSmBpSize: setSmBpSize,
      setMdBpSize: setMdBpSize,
      isLast: isLast,
      getClasses: getClasses,
      computedCategories: computedCategories
    };
  }
};