import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import DiligenceCategoryOverview from "./DiligenceCategoryOverview.vue";
import DiligenceMessage from "./DiligenceMessage.vue";
import DiligenceThermometer from "./DiligenceThermometer.vue";
import DiligenceScore from "./DiligenceScore.vue";
import LockedDataMessage from "~/components/LockedDataMessage.vue";
import useBaseUtils from "~/functions/useBaseUtils";
import useBreakpoints from "~/functions/useBreakpoints";
export default {
  __name: 'DiligenceOverview',
  props: {
    dimensions: {
      type: Object,
      "default": function _default() {}
    },
    score: {
      type: Number,
      "default": 0
    },
    disabled: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    var props = __props;
    var _useBreakpoints = useBreakpoints(),
      sm = _useBreakpoints.sm,
      lg = _useBreakpoints.lg;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      refCode = _useBaseUtils.refCode;
    var openedPanels = ref([]);
    var clearPanels = function clearPanels() {
      if (openedPanels.value.length > 0) {
        openedPanels.value = [];
      }
    };
    expose({
      clearPanels: clearPanels
    });
    var listStyling = computed(function () {
      return {
        "grid-template-columns": sm.value ? "repeat(3, minmax(0, 1fr))" : "minmax(0,1fr)"
      };
    });
    var summaryStyling = computed(function () {
      return {
        "--display": lg.value ? "grid" : "flex",
        "grid-template-columns": "minmax(0,1fr)",
        "--align-items": lg.value ? "unset" : "center",
        "min-height": lg.value ? "404px" : "unset"
      };
    });
    var wrapperStyling = computed(function () {
      return {
        "grid-template-columns": lg.value ? "230px minmax(0, 1fr)" : "minmax(0,1fr)",
        "--max-width": sm.value ? "100%" : "120px"
      };
    });
    var iconColor = computed(function () {
      return darkActive.value ? "#d9d9d9" : "#2F3033";
    });
    var dimensionList = computed(function () {
      return Object.keys(props.dimensions).reduce(function (acc, key) {
        var item = props.dimensions[key];
        acc[key] = item;
        return acc;
      }, {});
    });
    return {
      __sfc: true,
      props: props,
      sm: sm,
      lg: lg,
      darkActive: darkActive,
      refCode: refCode,
      openedPanels: openedPanels,
      clearPanels: clearPanels,
      listStyling: listStyling,
      summaryStyling: summaryStyling,
      wrapperStyling: wrapperStyling,
      iconColor: iconColor,
      dimensionList: dimensionList,
      DiligenceCategoryOverview: DiligenceCategoryOverview,
      DiligenceMessage: DiligenceMessage,
      DiligenceThermometer: DiligenceThermometer,
      DiligenceScore: DiligenceScore,
      LockedDataMessage: LockedDataMessage
    };
  }
};