import "core-js/modules/es.number.constructor.js";
import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  props: {
    message: {
      type: String,
      "default": "Upgrade Subscription!"
    },
    zIndex: {
      type: [Number, String],
      "default": 8
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode;
    return {
      refCode: refCode
    };
  }
});