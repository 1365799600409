import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import { isArray } from "lodash";
import useCiqValuationState from "~/functions/useCiqValuationState";
import useCiqDataSource from "~/functions/useCiqDataSource";
var getShowPropByCellType = function getShowPropByCellType(cellType, props) {
  if (cellType === "valuation") {
    var _props$hideIf;
    var _useCiqValuationState = useCiqValuationState(),
      valuationData = _useCiqValuationState.valuationData;
    return props !== null && props !== void 0 && (_props$hideIf = props.hideIf) !== null && _props$hideIf !== void 0 && _props$hideIf.length ? !props.hideIf.filter(function (key) {
      return !!valuationData.value[key];
    }).length > 0 : true;
  } else {
    return true;
  }
};
var getAllSources = function getAllSources() {
  var src = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var sources = Object.values(src).flatMap(function (item) {
    return isArray(item.source) ? item.source.map(function (subitem) {
      return subitem.source;
    }) : item.source;
  });
  return _toConsumableArray(new Set(sources));
};
var getShowProp = function getShowProp(allSources, props) {
  var show = allSources.map(function (source) {
    return getShowPropByCellType(source, props);
  }).every(function (key) {
    return key === true;
  });
  return show;
};
var useMetrics = function useMetrics(props) {
  var _useCiqDataSource = useCiqDataSource(props),
    cellValue = _useCiqDataSource.cellValue,
    isAnyCellUnauth = _useCiqDataSource.isAnyCellUnauth,
    isCellLoading = _useCiqDataSource.isCellLoading,
    isValid = _useCiqDataSource.isValid,
    label = _useCiqDataSource.label;
  var show = computed(function () {
    var allSources = getAllSources(props.metricKeys);
    return getShowProp(allSources, props);
  });
  var loading = computed(function () {
    return isCellLoading.value;
  });
  var unauth = computed(function () {
    return isAnyCellUnauth.value;
  });
  return {
    label: label,
    loading: loading,
    unauth: unauth,
    cellValue: cellValue,
    show: show,
    isValid: isValid
  };
};
export default useMetrics;