export const getRowsAndTickersSelected = ({
  $store,
  chartType = "multiples",
  period = "ntm",
}) => {
  const $ciqStore = $store.state.ciq
  const chartKey = `${chartType}Chart`
  const tickersKey = `${chartKey}Tickers`
  const selectedRows = $ciqStore?.[chartKey]?.[period] || []
  const isFinancialType = chartType === "financials"
  const selectedTickers = isFinancialType
    ? $ciqStore?.[tickersKey]?.[period] || {}
    : $ciqStore?.[tickersKey] || []
  const hasCurrentTidInStore = ["multiples"].includes(chartType)
  const companyName = $ciqStore?.ticker?.companyname

  const rows = Object.keys(selectedRows).map(
    (key) => selectedRows[key]?.name,
    []
  )

  const tickers = Object.keys(selectedTickers).map(
    (key) => selectedTickers?.[key]?.companyname,
    []
  )

  if (!hasCurrentTidInStore && companyName) {
    tickers.unshift(companyName)
  }

  return { rows, tickers }
}
