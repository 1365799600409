import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import debounce from "lodash/debounce";
import useBaseUtils from "~/functions/useBaseUtils";
var stage = process.env.LAMBDA_STAGE;
var devStage = "dev";
export default defineComponent({
  props: {
    ticker: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    small: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    return {
      I18nFn: I18nFn
    };
  },
  computed: {
    activeWatchlist: function activeWatchlist() {
      var activeId = Object.hasOwn(this.$store.state.watchlist.activeWatchlist, "id") ? this.$store.state.watchlist.activeWatchlist.id : null;
      if (activeId) {
        var activeWatchlist = Object.hasOwn(this.$store.state.watchlist.watchlists, activeId) ? this.$store.state.watchlist.watchlists[activeId] : null;
        if (activeWatchlist) {
          return activeWatchlist;
        }
      }
      return false;
    },
    isActiveFav: function isActiveFav() {
      var _this = this;
      if (this.activeWatchlist) {
        var activeWatchlist = this.$store.state.watchlist.watchlists[this.activeWatchlist.id];
        if (activeWatchlist !== null && activeWatchlist !== void 0 && activeWatchlist.tickers && activeWatchlist.tickers.length > 0) {
          var ticker = activeWatchlist.tickers.filter(function (t) {
            if (_this.ticker.tradingitemid) {
              // you're viewing a stock
              return t.tid === _this.ticker.tradingitemid.toString();
            } else if (_this.ticker.tid) {
              // you're dealing with a watchlist / compressed item
              return t.tid === _this.ticker.tid.toString();
            }
            return false;
          }); // if there is no match then stock[0] is undefined
          return ticker.length > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    customColor: function customColor() {
      return "primaryAction";
    },
    iconType: function iconType() {
      return this.$store.state.watchlist.activeWatchlist.icon || this.$store.state.config.favIconType;
    }
  },
  created: function created() {
    this.debounceToggleFav = debounce(this.toggleFav, 100);
  },
  methods: {
    toggleFav: function toggleFav() {
      // if we're debouncing toggle fav perhaps if somehow this.activewatchlist gets forgotten
      // in the difference of time between the click and the execution of the debounced
      // method that is what caused the error sahil experienced...
      // how can I fix this...
      var ticker = this.ticker;
      var tid = ticker.tradingitemid || ticker.tid;
      if (this.activeWatchlist) {
        if (this.isActiveFav) {
          if (stage === devStage) {
            console.log("".concat(tid, " is an active fav, remove from list"));
          }
          this.$store.dispatch("watchlist/deleteTicker", {
            tid: tid,
            wid: this.activeWatchlist.id
          });
        } else {
          // FIXME: Reference upper limit somewhere else in settings
          var maxTickers = this.$store.state.watchlist.maxTickers;
          if (this.activeWatchlist.tickers.length >= maxTickers) {
            confirm("".concat(this.I18nFn("Error: watchlist is full - cannot add more than"), " ").concat(maxTickers, "\n            ").concat(this.I18nFn("Please Create a new watchlist")));
          } else {
            if (stage === devStage) {
              console.log("".concat(tid, " is not a fav, adding to activewatchlist"));
            }
            this.$store.dispatch("watchlist/addTicker", {
              ticker: this.ticker,
              wid: this.activeWatchlist.id
            });
          }
        }
      } else {
        confirm(this.I18nFn("Please create an active watchlist in “My Watchlists” before adding a favorite ticker!"));
      }
    }
  }
});