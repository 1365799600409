import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _excluded = ["to"];
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _objectWithoutProperties(e, t) {
  if (null == e) return {};
  var o,
    r,
    i = _objectWithoutPropertiesLoose(e, t);
  if (Object.getOwnPropertySymbols) {
    var s = Object.getOwnPropertySymbols(e);
    for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
  }
  return i;
}
function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (e.includes(n)) continue;
    t[n] = r[n];
  }
  return t;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import useBaseUtils from "~/functions/useBaseUtils";
import useBreakpoints from "~/functions/useBreakpoints";
import DiligenceCheckItem from "~/components/dueDiligence/DiligenceCheckItem.vue";
import LockedDataMessage from "~/components/LockedDataMessage.vue";
import DiligenceMessage from "~/components/dueDiligence/DiligenceMessage.vue";
import DiligenceScore from "~/components/dueDiligence/DiligenceScore.vue";
export default {
  __name: 'DiligenceDimensionScore',
  props: {
    label: {
      type: String,
      "default": "",
      required: true
    },
    score: {
      type: Number,
      "default": 0,
      required: true
    },
    conditions: {
      type: Array,
      "default": function _default() {
        return [];
      },
      required: true
    },
    dimensionMessage: {
      type: Array,
      "default": function _default() {
        return [];
      },
      required: true
    },
    dimensionVars: {
      type: Object,
      "default": function _default() {},
      required: true
    },
    locked: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    var props = __props;
    var _useBreakpoints = useBreakpoints(),
      sm = _useBreakpoints.sm;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      stylesDiligenceScore = _useBaseUtils.stylesDiligenceScore;
    var openedPanels = ref([]);
    var clearPanels = function clearPanels() {
      if (openedPanels.value.length > 0) {
        openedPanels.value = [];
      }
    };
    expose({
      clearPanels: clearPanels
    });
    var listStyling = computed(function () {
      return {
        "grid-template-columns": sm.value ? "repeat(3, minmax(0, 1fr))" : "minmax(0,1fr)",
        "--item-min-height": sm.value ? "198px" : "unset",
        "--dimension-padding": sm.value ? "20px" : "16px"
      };
    });
    var getChipData = function getChipData(prop, checkScore) {
      return stylesDiligenceScore.value[checkScore][prop];
    };
    var getMobileTitle = function getMobileTitle(_ref2) {
      var message = _ref2.message,
        disabled = _ref2.disabled;
      return message.map(function (item) {
        if (disabled) {
          return _objectSpread(_objectSpread({}, item), {}, {
            tag: "span"
          });
        }
        var filteredMsgArray = item.messageArray.filter(function (partial) {
          return partial.tag === "strong" && !partial.removeFromTitle;
        }).map(function (part) {
          // removing links from titles
          var to = part.to,
            rest = _objectWithoutProperties(part, _excluded);
          return _objectSpread({}, rest);
        });
        return _objectSpread(_objectSpread({}, item), {}, {
          messageArray: filteredMsgArray
        });
      });
    };
    var getMobileDesc = function getMobileDesc(message) {
      var forbidden = ["strong", "br"];
      return message.map(function (item) {
        var filteredMsgArray = item.messageArray.filter(function (partial) {
          return !forbidden.includes(partial.tag) || partial.removeFromTitle;
        });
        return _objectSpread(_objectSpread({}, item), {}, {
          messageArray: filteredMsgArray
        });
      });
    };
    var conditionsPanels = computed(function () {
      return props.conditions.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          color: getChipData("background", item.actualCheckScore),
          textColor: getChipData("color", item.actualCheckScore),
          style: {
            "--border": getChipData("border", item.actualCheckScore)
          },
          panelLabel: item.disabled ? "n/a" : getChipData("label", item.actualCheckScore),
          mobileTitle: getMobileTitle(item),
          mobileDesc: getMobileDesc(item.message)
        });
      });
    });
    return {
      __sfc: true,
      props: props,
      sm: sm,
      darkActive: darkActive,
      stylesDiligenceScore: stylesDiligenceScore,
      openedPanels: openedPanels,
      clearPanels: clearPanels,
      listStyling: listStyling,
      getChipData: getChipData,
      getMobileTitle: getMobileTitle,
      getMobileDesc: getMobileDesc,
      conditionsPanels: conditionsPanels,
      DiligenceCheckItem: DiligenceCheckItem,
      LockedDataMessage: LockedDataMessage,
      DiligenceMessage: DiligenceMessage,
      DiligenceScore: DiligenceScore
    };
  }
};