import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import DiligenceSkeletonTabs from "./DiligenceSkeletonTabs.vue";
import DiligenceDimensionScore from "./DiligenceDimensionScore.vue";
import DiligenceNoDividends from "./DiligenceNoDividends.vue";
import DiligenceOverview from "./DiligenceOverview.vue";
import WarningMsg from "~/components/WarningMsg.vue";
import useBreakpoints from "~/functions/useBreakpoints";
import useBaseUtils from "~/functions/useBaseUtils";
import useCompanyScore from "~/functions/useCompanyScore";
import dimensionsTemplate from "~/utils/dueDiligence/diligenceTemplate";
import { getWarningMessageByCompanyDescription } from "~/utils/dueDiligence/utils";
export default {
  __name: 'QuickDueDiligence',
  setup: function setup(__props) {
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var currentTab = ref(0);
    var _useBreakpoints = useBreakpoints(),
      lg = _useBreakpoints.lg;
    var _useCompanyScore = useCompanyScore(dimensionsTemplate),
      scoreResults = _useCompanyScore.scoreResults,
      isScoreLoading = _useCompanyScore.isScoreLoading;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    var overviewRef = ref(null);
    var dimensionRef = ref(null);
    watch(currentTab, function (newVal) {
      var _dimensionRef$value;
      if (newVal !== 0) {
        var _overviewRef$value;
        (_overviewRef$value = overviewRef.value) === null || _overviewRef$value === void 0 || _overviewRef$value.clearPanels();
      }
      (_dimensionRef$value = dimensionRef.value) === null || _dimensionRef$value === void 0 || (_dimensionRef$value = _dimensionRef$value[0]) === null || _dimensionRef$value === void 0 || _dimensionRef$value.clearPanels();
    });
    var tabs = computed(function () {
      return Object.keys(dimensionsTemplate).map(function (tabName) {
        return {
          key: "".concat(tradingItemId.value, "_").concat(tabName, "_tab"),
          titleKey: "".concat(tradingItemId.value, "_").concat(tabName, "_tabTitle"),
          tabName: tabName
        };
      }) || [];
    });
    var tradingItemId = computed(function () {
      var _$store$state$ciq$tic;
      return (_$store$state$ciq$tic = $store.state.ciq.ticker) === null || _$store$state$ciq$tic === void 0 ? void 0 : _$store$state$ciq$tic.tradingitemid;
    });
    var companyWarningMessage = computed(function () {
      var ticker = $store.state.ciq.ticker;
      return getWarningMessageByCompanyDescription(ticker.simpleindustrydescription, ticker.tickersymbol);
    });
    var headerStyle = computed(function () {
      return {
        display: lg.value ? "flex" : "grid",
        gap: lg.value ? "8px" : "0 5px",
        "grid-template-columns": "22px minmax(0,1fr)",
        "align-content": "center",
        "--font-size": lg.value ? "22px" : "19px"
      };
    });
    var wrapperStyle = computed(function () {
      return {
        "--font-size-message": "14px",
        "--dark-mode-card-bg": "#1e1e1e"
      };
    });
    return {
      __sfc: true,
      $store: $store,
      currentTab: currentTab,
      lg: lg,
      scoreResults: scoreResults,
      isScoreLoading: isScoreLoading,
      I18nFn: I18nFn,
      overviewRef: overviewRef,
      dimensionRef: dimensionRef,
      tabs: tabs,
      tradingItemId: tradingItemId,
      companyWarningMessage: companyWarningMessage,
      headerStyle: headerStyle,
      wrapperStyle: wrapperStyle,
      DiligenceSkeletonTabs: DiligenceSkeletonTabs,
      DiligenceDimensionScore: DiligenceDimensionScore,
      DiligenceNoDividends: DiligenceNoDividends,
      DiligenceOverview: DiligenceOverview,
      WarningMsg: WarningMsg
    };
  }
};