import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _createForOfIteratorHelper(r, e) {
  var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (!t) {
    if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) {
      t && (r = t);
      var _n = 0,
        F = function F() {};
      return {
        s: F,
        n: function n() {
          return _n >= r.length ? {
            done: !0
          } : {
            done: !1,
            value: r[_n++]
          };
        },
        e: function e(r) {
          throw r;
        },
        f: F
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var o,
    a = !0,
    u = !1;
  return {
    s: function s() {
      t = t.call(r);
    },
    n: function n() {
      var r = t.next();
      return a = r.done, r;
    },
    e: function e(r) {
      u = !0, o = r;
    },
    f: function f() {
      try {
        a || null == t["return"] || t["return"]();
      } finally {
        if (u) throw o;
      }
    }
  };
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
import { rowObjToSelectedKey } from "~/utils/ciq";
import { estimateTemplate } from "~/utils/templates/estimates_template";
import { financialTemplates } from "~/utils/templates/financials_templates";
import { valuationTemplate } from "~/utils/templates/valuation_template";
import useCiqTableUtils from "~/functions/useCiqTableUtils";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import { dataSourceTabs } from "~/utils/constants/tabs";
import { SHOW_MORNINGSTAR_FINANCIALS } from "~/feature-toggle";
export default {
  __name: 'DiligenceLink',
  props: {
    label: {
      type: String,
      "default": ""
    },
    tabName: {
      type: String,
      "default": ""
    },
    sourceName: {
      type: String,
      "default": ""
    },
    component: {
      type: String,
      "default": "span"
    },
    append: {
      type: String,
      "default": ""
    },
    prepend: {
      type: String,
      "default": ""
    },
    clickType: {
      type: String,
      "default": ""
    },
    clickTableNameId: {
      type: String,
      "default": ""
    },
    vuexModule: {
      type: String,
      "default": "ciq"
    }
  },
  setup: function setup(__props) {
    var _$featureToggle$toggl;
    var props = __props;
    var _useNuxtApp = useNuxtApp(),
      $route = _useNuxtApp.$route,
      $vuetify = _useNuxtApp.$vuetify,
      $store = _useNuxtApp.$store,
      $featureToggle = _useNuxtApp.$featureToggle;
    var _useCiqTableUtils = useCiqTableUtils(props),
      addRowToSelectedObject = _useCiqTableUtils.addRowToSelectedObject;
    var enableDataSourceQueryParams = (_$featureToggle$toggl = $featureToggle.toggles) === null || _$featureToggle$toggl === void 0 ? void 0 : _$featureToggle$toggl["datasource-query-params"];
    var url = computed(function () {
      var query = $route.query;
      var _dataSourceTabs = dataSourceTabs(SHOW_MORNINGSTAR_FINANCIALS),
        _dataSourceTabs2 = _slicedToArray(_dataSourceTabs, 1),
        dataSourceFirstSlug = _dataSourceTabs2[0];
      var cid = query.cid,
        tid = query.tid,
        ref = query.ref;
      var params = "?cid=".concat(cid, "&tid=").concat(tid, "&tab=").concat(props.tabName, "&ref=").concat(ref);
      if (enableDataSourceQueryParams) {
        params += "&dataset=".concat(dataSourceFirstSlug.slug);
      }
      if (props.sourceName) {
        return "/stock/".concat(props.sourceName).concat(params);
      }
      return "";
    });
    var classes = computed(function () {
      return {
        "font-weight-bold": props.component === "strong"
      };
    });
    var styles = computed(function () {
      return {
        "--margin-left": props !== null && props !== void 0 && props.prepend ? "-4px" : "unset",
        "--margin-right": props !== null && props !== void 0 && props.append ? "-4px" : "unset"
      };
    });
    var rowData = computed(function () {
      var row = getRowById(props.clickTableNameId, props.clickType, $store);
      var rowId = rowObjToSelectedKey(row);
      return _objectSpread(_objectSpread({}, row), {}, {
        rowId: rowId
      });
    });
    var linkObj = computed(function () {
      return {
        "class": classes.value,
        to: url.value
      };
    });
    var getSourceRowObjById = function getSourceRowObjById(idToSearch, sample) {
      if (!idToSearch) {
        console.error("No ID attached");
      }
      for (var key in sample) {
        var obj = sample[key];
        var keys = Object.keys(obj);
        if (obj.keys) {
          var _iterator = _createForOfIteratorHelper(obj.keys),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;
              if (item.name === idToSearch) {
                return item;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        } else {
          var _iterator2 = _createForOfIteratorHelper(keys),
            _step2;
          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var subkey = _step2.value;
              var _item2 = obj[subkey];
              if (_item2.keys) {
                var _iterator3 = _createForOfIteratorHelper(_item2.keys),
                  _step3;
                try {
                  for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                    var _item = _step3.value;
                    if (_item.name === idToSearch) {
                      return _item;
                    }
                  }
                } catch (err) {
                  _iterator3.e(err);
                } finally {
                  _iterator3.f();
                }
              }
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
      }
    };
    var getComputedRowObjsById = function getComputedRowObjsById(idToSearch, sample) {
      if (!idToSearch) {
        console.error("No ID attached");
      }
      for (var key in sample) {
        var items = sample[key];
        for (var rowIdx in items) {
          var row = items[rowIdx];
          if (row.name === idToSearch) {
            return row;
          }
        }
      }
    };
    var getSourceRowObjs = function getSourceRowObjs(type) {
      switch (type) {
        case "valuation":
          return valuationTemplate;
        case "financials":
          return financialTemplates;
        case "estimates":
          return estimateTemplate;
        default:
          console.log("different source:", type);
          break;
      }
    };
    var getPeriodByType = function getPeriodByType(type) {
      switch (type) {
        case "valuation":
          return "ntm";
        case "financials":
          return "a";
        case "estimates":
          return "a";
        default:
          console.log("no type found:", type);
          break;
      }
    };
    var getComputedArr = function getComputedArr(type, $store) {
      switch (type) {
        case "valuation":
          {
            var _useCiqTableOptions = useCiqTableOptions({
                storeModule: "ciq",
                tableType: "multiples"
              }),
              transformValuationTemplateIntoRows = _useCiqTableOptions.transformValuationTemplateIntoRows;
            var tblTemplateArr = valuationTemplate.map(function (t) {
              return t.keys;
            });
            return transformValuationTemplateIntoRows($store, tblTemplateArr);
          }
        case "financials":
          return $store.state.ciq.financials.a.financials;
        case "estimates":
          return $store.state.ciq.estimates.a.estimates;
        default:
          console.log("different source:", type);
          break;
      }
    };
    var getComputedRowObjs = function getComputedRowObjs(_ref) {
      var clickType = _ref.clickType,
        tableNameId = _ref.tableNameId,
        $store = _ref.$store;
      var arr = getComputedArr(clickType, $store);
      var item = getComputedRowObjsById(tableNameId, arr);
      return item;
    };
    var getRowById = function getRowById(tableNameId, clickType, $store) {
      var sourceRows = getSourceRowObjs(clickType);
      var sourceRowObj = getSourceRowObjById(tableNameId, sourceRows);
      var generatedRows = getComputedRowObjs({
        clickType: clickType,
        tableNameId: tableNameId,
        $store: $store
      });
      return _objectSpread(_objectSpread({}, sourceRowObj), generatedRows);
    };
    var getChartType = function getChartType(type) {
      switch (type) {
        case "estimates":
          return "estimatesChart";
        case "financials":
          return "financialsChart";
        case "valuation":
          return "multiplesChart";
        default:
          console.log("no type found:", type);
          break;
      }
    };
    var onClick = function onClick() {
      try {
        var row = rowData.value;
        var period = getPeriodByType(props.clickType);
        var chartType = getChartType(props.clickType);
        var rowInfo = _objectSpread({
          period: period,
          chartType: chartType
        }, row);
        addRowToSelectedObject(row, row.rowId, period, chartType, props.vuexModule);
        $vuetify.goTo("#stocktabbar");
        console.log({
          row: row,
          rowInfo: rowInfo
        });
      } catch (error) {
        console.log("erro", error);
      }
      return false;
    };
    return {
      __sfc: true,
      props: props,
      $route: $route,
      $vuetify: $vuetify,
      $store: $store,
      $featureToggle: $featureToggle,
      addRowToSelectedObject: addRowToSelectedObject,
      enableDataSourceQueryParams: enableDataSourceQueryParams,
      url: url,
      classes: classes,
      styles: styles,
      rowData: rowData,
      linkObj: linkObj,
      getSourceRowObjById: getSourceRowObjById,
      getComputedRowObjsById: getComputedRowObjsById,
      getSourceRowObjs: getSourceRowObjs,
      getPeriodByType: getPeriodByType,
      getComputedArr: getComputedArr,
      getComputedRowObjs: getComputedRowObjs,
      getRowById: getRowById,
      getChartType: getChartType,
      onClick: onClick
    };
  }
};