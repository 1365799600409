import metrics from "~/utils/constants/metrics"
import diligenceVariables from "~/utils/dueDiligence/diligenceVariables"
import scoreVariables from "~/utils/dueDiligence/scoreVariables"

import {
  qualityOptions,
  growthOptions,
  forecastOptions,
  valuationOptions,
  safetyOptions,
  dividendOptions,
} from "~/utils/dueDiligence/diligenceOptions"
import {
  getQualityMessage,
  getValuationMessage,
  getGrowthMessage,
  getForecastMessage,
  getSafetyMessage,
  getDividendMessage,
} from "~/utils/dueDiligence/scoreOptions"

const {
  capitalExpenditure,
  cfo,
  dps,
  dpsEstimate,
  dso,
  ebit,
  ebitda,
  ebitOverInterestExpense,
  epsNormalized,
  epsWithFallback,
  freeCashFlow,
  grossProfitMargin,
  netDebt,
  netIncome2150,
  returnOnCapital,
  revenue,
  stockPriceCagrFiveYears,
  tevOverNtmRevenueDivision,
  tevOverLtmRevenueDivision,
} = metrics

const cagrOneYearForwardFormula = {
  type: "cagr",
  startRelativePeriod: 0,
  endRelativePeriod: +1,
}

const cagrLastFiveYearsFormula = {
  type: "cagr",
  startRelativePeriod: -5,
  endRelativePeriod: 0,
}

const cagrTwoYearForwardFormula = {
  ...cagrOneYearForwardFormula,
  endRelativePeriod: +2,
}

const dimensionsTemplate = {
  Quality: {
    meta: {
      label: "Quality",
      description:
        "Analyzes the financial strength of the company by evaluating their track record of producing superior and stable profitability and returns on invested capital",
    },
    score: {
      variables: scoreVariables.quality,
      logicFunction: (_, score) => getQualityMessage(score),
    },
    data: [
      {
        label: "LTM Gross Margin",
        metricKeys: [grossProfitMargin],
        formatType: "whole_pct",
        relativeDate: "ltm",
        formula: "val",
        score: {
          options: qualityOptions.grossMargin,
          logicFunction: qualityOptions.grossMargin.logicFunction,
        },
      },
      {
        label: "5 Year LTM Gross Margin CAGR",
        metricKeys: [grossProfitMargin],
        // relativeDate: "ltm",
        actualOrEstimateKey: "mean",
        formula: cagrLastFiveYearsFormula,
        score: {
          options: qualityOptions.grossMarginFiveYears,
          variables: diligenceVariables.grossMarginFiveYears,
          logicFunction: qualityOptions.grossMarginFiveYears.logicFunction,
        },
      },
      {
        label: "5 Year LTM EBIT Margin CAGR",
        metricKeys: [ebit],
        formula: cagrLastFiveYearsFormula,
        // relativeDate: "ltm",
        actualOrEstimateKey: "mean",
        score: {
          options: qualityOptions.ebitMargin,
          variables: diligenceVariables.ebitMargin,
          logicFunction: qualityOptions.ebitMargin.logicFunction,
        },
      },
      {
        label: "LTM ROIC",
        metricKeys: [returnOnCapital],
        formula: "val",
        relativeDate: "ltm",
        formatType: "whole_pct",
        score: {
          options: qualityOptions.roic,
          logicFunction: qualityOptions.roic.logicFunction,
        },
      },
      {
        label: "5 Year LTM ROIC CAGR",
        metricKeys: [returnOnCapital],
        formula: cagrLastFiveYearsFormula,
        formatType: "whole_pct",
        // relativeDate: "ltm",
        score: {
          options: qualityOptions.roicFiveYears,
          variables: diligenceVariables.roicFiveYears,
          logicFunction: qualityOptions.roicFiveYears.logicFunction,
        },
      },
    ],
  },
  Growth: {
    meta: {
      label: "Growth",
      description:
        "Analyzes how quickly the company has historically grown revenues, Earnings Per Share, and more",
    },
    score: {
      variables: scoreVariables.growth,
      logicFunction: (_, score, variablesObj) => {
        const epsValues = variablesObj.eps
        const firstEpsVal = Number(epsValues?.[0]?.dataitemvalue)
        const secondEpsVal = Number(epsValues?.[1]?.dataitemvalue)
        const isEpsNegative = firstEpsVal < 0 || secondEpsVal < 0
        return getGrowthMessage(score, isEpsNegative)
      },
    },
    data: [
      {
        label: "5 Years Stock Price CAGR",
        metricKeys: [stockPriceCagrFiveYears],
        formula: "val",
        formatType: "pct",
        score: {
          options: growthOptions.fiveYearStockPriceCagr,
          logicFunction: growthOptions.fiveYearStockPriceCagr.logicFunction,
        },
      },
      {
        label: "LTM Revenue growth YoY",
        metricKeys: [revenue],
        formula: "YoY",
        relativeDate: "ltm",
        actualOrEstimateKey: "actual",
        formatType: "pct",
        score: {
          options: growthOptions.ltmRevenueGrowth,
          logicFunction: growthOptions.ltmRevenueGrowth.logicFunction,
        },
      },
      {
        label: "5 Year Revenue growth CAGR",
        metricKeys: [revenue],
        formula: cagrLastFiveYearsFormula,
        actualOrEstimateKey: "actual",
        formatType: "pct",
        score: {
          options: growthOptions.fiveYearRevenueGrowth,
          variables: diligenceVariables.revenueGrowthFiveYears,
          logicFunction: growthOptions.fiveYearRevenueGrowth.logicFunction,
        },
      },
      {
        label: "5 Year EPS growth historical CAGR",
        metricKeys: [epsWithFallback],
        formula: cagrLastFiveYearsFormula,
        formatType: "pct",
        score: {
          options: growthOptions.fiveYearEpsHistCagr,
          variables: diligenceVariables.fiveYearEpsHistCagr,
          logicFunction: growthOptions.fiveYearEpsHistCagr.logicFunction,
        },
      },
      {
        label: "LTM EPS growth YoY",
        metricKeys: [epsWithFallback],
        formula: {
          type: "cagr",
          startRelativePeriod: -1,
          endRelativePeriod: 0,
        },
        relativeDate: "ltm",
        actualOrEstimateKey: "mean",
        formatType: "pct",
        score: {
          options: growthOptions.ltmEpsGrowthYoY,
          logicFunction: growthOptions.ltmEpsGrowthYoY.logicFunction,
        },
      },
    ],
  },
  Forecast: {
    meta: {
      label: "Forecast",
      description:
        "Highlights how quickly Wall Street analysts expect the company to grow revenues, Free Cash Flow, Earnings Per Share, and more in the coming years",
    },
    score: {
      variables: scoreVariables.forecast,
      logicFunction: (_, score, variablesObj) => {
        const epsValues = variablesObj.eps
        const firstEpsVal = Number(epsValues?.[0]?.dataitemvalue)
        const secondEpsVal = Number(epsValues?.[1]?.dataitemvalue)
        const isEpsNegative = firstEpsVal < 0 || secondEpsVal < 0
        return getForecastMessage(score, isEpsNegative)
      },
    },
    data: [
      {
        label: "Revenue Growth FY+1",
        metricKeys: [revenue],
        actualOrEstimateKey: "mean",
        formula: cagrOneYearForwardFormula,
        formatType: "pct",
        score: {
          options: forecastOptions.revGrowth,
          logicFunction: forecastOptions.revGrowth.logicFunction,
        },
      },
      {
        label: "Free Cash Flow Growth FY+1",
        metricKeys: [freeCashFlow],
        actualOrEstimateKey: "mean",
        formula: cagrOneYearForwardFormula,
        formatType: "pct",
        score: {
          options: forecastOptions.fcfGrowth,
          logicFunction: forecastOptions.fcfGrowth.logicFunction,
        },
      },
      {
        label: "EPS Growth FY+1",
        metricKeys: [epsNormalized],
        actualOrEstimateKey: "mean",
        formula: cagrOneYearForwardFormula,
        formatType: "pct",
        score: {
          options: forecastOptions.epsGrowth,
          logicFunction: forecastOptions.epsGrowth.logicFunction,
        },
      },
      {
        label: "Revenue Growth FY+2",
        metricKeys: [revenue],
        actualOrEstimateKey: "mean",
        formula: "YoY",
        relativeDateKey: 2,
        formatType: "pct",
        score: {
          options: forecastOptions.revGrowthPlusTwo,
          logicFunction: forecastOptions.revGrowthPlusTwo.logicFunction,
        },
      },
      {
        label: "EPS Growth FY+2",
        metricKeys: [epsNormalized],
        actualOrEstimateKey: "mean",
        formula: {
          type: "cagr",
          startRelativePeriod: -1,
          endRelativePeriod: 0,
        },
        relativeDateKey: 2,
        formatType: "pct",
        score: {
          options: forecastOptions.epsGrowthPlusTwo,
          logicFunction: forecastOptions.epsGrowthPlusTwo.logicFunction,
        },
      },
    ],
  },
  Valuation: {
    meta: {
      label: "Valuation",
      description:
        "Quick analysis to see if the stock may be potentially undervalued. Be sure to do your own diligence!",
    },
    score: {
      variables: scoreVariables.valuation,
      logicFunction: (_, score, variablesObj) => {
        const evRevAvg = variablesObj?.evRevFiveYearAvg?.v
        const ntmPriceOverEarnings =
          variablesObj?.ntmPriceOverEarningsFiveYearAvgComparison.v
        const isFirstParamAbove = !Number.isNaN(evRevAvg) && evRevAvg > 0
        const isSecondParamAbove =
          !Number.isNaN(ntmPriceOverEarnings) && ntmPriceOverEarnings > 0
        return getValuationMessage(
          score,
          isFirstParamAbove,
          isSecondParamAbove,
          Number.isNaN(ntmPriceOverEarnings)
        )
      },
    },
    data: [
      {
        label: "TEV/Revenue",
        metricKeys: [
          {
            name: "fiveYearAvg",
            aliasId: "fiveYearAvg",
            source: [
              {
                ...tevOverNtmRevenueDivision,
                valuePropKey: "5yr",
              },
              {
                ...tevOverLtmRevenueDivision,
                valuePropKey: "5yr",
              },
            ],
          },
          {
            name: "oneYearAvg",
            aliasId: "oneYearAvg",
            source: [
              {
                ...tevOverNtmRevenueDivision,
                valuePropKey: "last",
              },
              {
                ...tevOverLtmRevenueDivision,
                valuePropKey: "last",
              },
            ],
          },
        ],
        formula: {
          type: "custom",
          evaluate: `* 100 (/ (- {{ oneYearAvg }} {{ fiveYearAvg }}) {{ fiveYearAvg }})`,
        },
        formatType: "whole_pct",
        score: {
          options: valuationOptions.tevOverRevenue,
          variables: diligenceVariables.tevOverRevenue,
          logicFunction: valuationOptions.tevOverRevenue.logicFunction,
        },
      },
      {
        label: "P/E NTM > 5YR AVG",
        metricKeys: [
          {
            name: "fiveYearAvg",
            aliasId: "fiveYearAvg",
            source: [
              {
                metricKey: "div-priceclose-eps normalized",
                source: "valuationStats",
                valuePropKey: "5yr",
              },
              {
                metricKey: "div-priceclose-diluted eps excl extra items 142",
                source: "valuationStats",
                valuePropKey: "5yr",
              },
            ],
          },
          {
            name: "oneYearAvg",
            aliasId: "oneYearAvg",
            source: [
              {
                metricKey: "div-priceclose-eps normalized",
                source: "valuationStats",
                tableNameId: "NTM Price / Normalized Earnings (P/E)",
                valuePropKey: "last",
              },
              {
                metricKey: "div-priceclose-diluted eps excl extra items 142",
                source: "valuationStats",
                tableNameId: "LTM Price / Diluted EPS (P/E)",
                valuePropKey: "last",
              },
            ],
          },
        ],
        formula: {
          type: "custom",
          evaluate: `* 100 (/ (- {{ oneYearAvg }} {{ fiveYearAvg }}) {{ fiveYearAvg }})`,
        },
        formatType: "whole_pct",
        score: {
          options: valuationOptions.priceOverEarnings,
          variables: diligenceVariables.priceOverEarnings,
          logicFunction: valuationOptions.priceOverEarnings.logicFunction,
        },
      },
      {
        label: "TEV/Revenue YoY",
        metricKeys: [
          {
            name: "tevOverRevenueDiv",
            source: [
              {
                ...tevOverNtmRevenueDivision,
                valuePropKey: "1yrChangePct",
              },
              {
                ...tevOverLtmRevenueDivision,
                valuePropKey: "1yrChangePct",
              },
            ],
          },
        ],
        formula: "val",
        formatType: "pct",
        score: {
          options: valuationOptions.tevOverRevenueYoY,
          logicFunction: valuationOptions.tevOverRevenueYoY.logicFunction,
        },
      },
      {
        label: "Stock Price / Street Price Target",
        metricKeys: [
          {
            ...metrics.stockPriceClose,
            aliasId: "streetPrice",
          },
          {
            ...metrics.stockPrice,
            aliasId: "stockPrice",
          },
        ],
        formula: {
          type: "custom",
          evaluate: `+ -1 (/ {{ stockPrice }} {{ streetPrice }})`,
        },
        formatType: "pct",
        score: {
          options: valuationOptions.lastPriceOverStreetPrice,
          variables: diligenceVariables.lastPriceOverStreetPrice,
          logicFunction:
            valuationOptions.lastPriceOverStreetPrice.logicFunction,
        },
      },
      {
        label: "Stock Price / 52 Week High",
        metricKeys: [metrics.lastPriceOver52WeekHigh],
        formula: "val",
        formatType: "pct",
        score: {
          options: valuationOptions.lastPriceOver52WeekHigh,
          logicFunction: valuationOptions.lastPriceOver52WeekHigh.logicFunction,
        },
      },
    ],
  },
  Safety: {
    meta: {
      label: "Safety",
      description:
        "Analyzes the potential financial risks associated with this company, including its cash flow generation, balance sheet, and more",
    },
    score: {
      logicFunction: (_, score) => getSafetyMessage(score),
    },
    data: [
      {
        label: "Positive FCF",
        metricKeys: [cfo, capitalExpenditure],
        actualOrEstimateKey: "mean",
        relativeDateKey: 0,
        formula: "sum",
        score: {
          options: safetyOptions.freeCashFlowRisk,
          variables: diligenceVariables.freeCashFlowRisk,
          logicFunction: safetyOptions.freeCashFlowRisk.logicFunction,
        },
      },
      {
        label: "LTM ND/EBITDA",
        relativeDate: "ltm",
        metricKeys: [netDebt, ebitda],
        formula: "div",
        actualOrEstimateKey: "actual",
        formatType: "turns",
        score: {
          options: safetyOptions.ltmNdEbitda,
          variables: diligenceVariables.ltmNdEbitda,
          logicFunction: safetyOptions.ltmNdEbitda.logicFunction,
        },
      },
      {
        label: "LTM Interest Coverage",
        metricKeys: [ebitOverInterestExpense],
        formula: "val",
        actualOrEstimateKey: "actual",
        formatType: "turns",
        score: {
          options: safetyOptions.ltmInterestCoverage,
          variables: diligenceVariables.ltmInterestCoverage,
          logicFunction: safetyOptions.ltmInterestCoverage.logicFunction,
        },
      },
      {
        label: "FY CFO / Net Income + D&A",
        metricKeys: [
          {
            ...cfo,
            aliasId: "cfo",
          },
          netIncome2150,
          {
            aliasId: "totalDepreciationAndAmortization",
            name: "total depreciation & amortization 2160",
            source: "financials",
          },
        ],
        formula: {
          type: "custom",
          evaluate: `/ {{ cfo }} (+ {{ netIncome }} {{ totalDepreciationAndAmortization }})`,
        },
        actualOrEstimateKey: "actual",
        score: {
          options: safetyOptions.cfoNetIncome,
          variables: diligenceVariables.cfoNetIncome,
          logicFunction: safetyOptions.cfoNetIncome.logicFunction,
        },
      },
      {
        label: "Stable/Decrease in LTM DSO & DSI YoY",
        metricKeys: [dso],
        formula: "YoY",
        formatType: "pct",
        score: {
          options: safetyOptions.dsoDsi,
          variables: diligenceVariables.dsoDsi,
          logicFunction: safetyOptions.dsoDsi.logicFunction,
        },
      },
    ],
  },
  Dividend: {
    meta: {
      label: "Dividend",
      description:
        "Evaluates the quality of the dividends that the company pays by analyzing its yield, stability, and growth",
    },
    score: {
      variables: scoreVariables.dividend,
      logicFunction: (_, score) => getDividendMessage(score),
    },
    data: [
      {
        label: "Dividends/Share 5-Yr CAGR",
        metricKeys: [dps],
        formula: cagrLastFiveYearsFormula,
        formatType: "pct",
        score: {
          options: dividendOptions.dividendsPerShareFiveYearCagr,
          variables: diligenceVariables.dividendsPerShareFiveYearCagr,
          logicFunction:
            dividendOptions.dividendsPerShareFiveYearCagr.logicFunction,
        },
      },
      {
        label: "Dividends/Share FY+2 CAGR",
        metricKeys: [dpsEstimate],
        formula: cagrTwoYearForwardFormula,
        formatType: "pct",
        score: {
          options: dividendOptions.dpsTwoYearFwdCagr,
          logicFunction: dividendOptions.dpsTwoYearFwdCagr.logicFunction,
        },
      },
      {
        label: "Stable Dividends",
        metricKeys: [dps],
        formula: "val",
        actualOrEstimateKey: "mean",
        score: {
          options: dividendOptions.stableDividends,
          variables: diligenceVariables.stableDividends,
          logicFunction: dividendOptions.stableDividends.logicFunction,
        },
      },
      {
        label: "FY + 1 Payout Ratio",
        metricKeys: [dpsEstimate, epsNormalized],
        actualOrEstimateKey: "mean",
        formula: "div",
        relativeDateKey: 1,
        formatType: "pct",
        score: {
          options: dividendOptions.payoutRatio,
          variables: diligenceVariables.payoutRatio,
          logicFunction: dividendOptions.payoutRatio.logicFunction,
        },
      },
      {
        label: "FY + 2 Payout Ratio",
        metricKeys: [dpsEstimate, epsNormalized],
        actualOrEstimateKey: "mean",
        formula: "div",
        relativeDateKey: 2,
        formatType: "pct",
        score: {
          options: dividendOptions.twoYearsPayoutRatio,
          variables: diligenceVariables.twoYearsPayoutRatio,
          logicFunction: dividendOptions.twoYearsPayoutRatio.logicFunction,
        },
      },
    ],
  },
}

export default dimensionsTemplate
