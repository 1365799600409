/**
 * algoliaSearch mixin contains the dependencies and initial setup to integrate
 * algolia search capabilities into a view component
 * Also includes the methods utilized to add and manage more tickers on a chart..
 * this pattern.. may not be the best, perhaps you shouldn't be abstracting it?
 *
 * This mixin exposes the `search` method which can be used with a vuetify
 * v-autocomplete :search-input.sync method to search
 *
 * It also exposes the items computed property which can is a list of results
 * from the search that should be set to the :items attribute of v-autocomplete
 *
 * TODO: one must implement addTicker($event) methods which is called upon
 * successful selection of a ticker from the autocomplete component
 */
"use strict"
import algoliasearch from "algoliasearch/lite"
import debounce from "lodash/debounce"

const searchIndex = process.env.SEARCH_INDEX
const stage = process.env.LAMBDA_STAGE
const devStage = "dev"

export default (indexName) => {
  return {
    // build algolia stuff here
    data: function () {
      return {
        index: algoliasearch(
          "TJPAY1DYT8",
          "d88ea2aa3c22293c96736f5ceb5bab4e"
        ).initIndex(indexName || searchIndex),
        search: null,
        entities: [],
      }
    },
    computed: {
      dev() {
        return stage === devStage
      },
      companyNameLimit() {
        return this.$store.state.autocompleteCharacterLimit
      },
      items() {
        try {
          return this.entities.map((result) => {
            const companyname = result.companyname
            // result.companyname.length > this.companyNameLimit
            //   ? result.companyname.slice(0, this.companyNameLimit) + "..."
            //   : result.companyname
            return { ...result, companyname }
          })
        } catch (error) {
          return []
        }
      },
    },
    watch: {
      search(newVal) {
        if (newVal) {
          // this.debounceAlgoliaSearch(newVal)
          this.debounceAlgoliaSearch(newVal)
        }
      },
    },
    created() {
      this.debounceAlgoliaSearch = debounce(this.algoliaSearch, 100)
    },
    methods: {
      algoliaSearch(query) {
        if (this.dev) {
          console.log("AlgoliaSearchVuetify search called with query: ", query)
        }
        this.index
          .search({ query, distinct: 2 })
          .then((res) => {
            const hits = res.hits
            const resultQuery = res.query
            if (hits) {
              if (this.dev) {
                console.log(
                  `AlgoliaSearchVuetify search executed with: ${query} while search is ${this.search}`
                )
              }

              const search = this.search
              if (resultQuery === search) {
                this.entities = hits
              }
            } else if (this.dev) {
              console.error("AlgoliaSearch No hits returned... response: ", res)
            }
          })
          .catch((err) =>
            console.error("Error searching from search bar: ", err)
          )
      },
    },
  }
}
