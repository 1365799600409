import {
  getCheckMessage,
  getScoreFromDescCutoff,
} from "~/utils/dueDiligence/utils"

const maxPercentageWidget = 100
const brTag = { tag: "br", value: "" }

const notApplicableMsgDimension = [
  {
    requiredVariables: [],
    messageArray: [
      { tag: "span", customProp: true, value: "Company" },
      {
        tag: "span",
        value: "currently does not pay out dividends to investors",
      },
    ],
  },
]

/**
 * QUALITY OPTIONS
 */
const getQualityGrossMarginMsg = (score) => {
  const partOne = getCheckMessage(score, "High LTM", "LTM", "Low LTM")

  const grossMargins = getCheckMessage(
    score,
    "gross margins",
    "Gross margins",
    "gross margins"
  )

  const partTwo = getCheckMessage(
    score,
    "Indicates a business that offers high-quality products/services. This means customers are willing to pay a higher price compared to what it costs the company to make the product/service.",
    "Indicates customers are willing to pay a decent premium relative to the company’s cost to make the good/service.",
    "Indicates that the company operates in a competitive, commoditized industry as the company is unable to charge a large premium relative to what it costs to manufacture the good/service"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        {
          tag: "strong",
          value: grossMargins,
          highlightMetric: "checkValue",
          to: { source: "financials", tab: "is" },
        },
        { tag: "strong", value: " of" },
        { tag: "strong", customProp: true, value: "checkValue", append: ":" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const getQualityGrossMarginFiveYearsMsg = (score) => {
  const partOne = getCheckMessage(score, "Rising 5-yr", "5-yr", "Volatile 5-yr")

  const partTwo = getCheckMessage(
    score,
    "Suggests a good business with pricing power or the ability to increase prices more than cost inflation",
    "Suggests a decent business with pricing that generally keeps up with cost inflation",
    "Suggests a lower quality business in a competitive or cyclical industry"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        {
          tag: "strong",
          value: "gross margins",
          highlightMetric: "checkValue",
          to: { source: "financials", tab: "is" },
        },
      ],
    },
    {
      requiredVariables: ["t0", "t1"],
      messageArray: [
        { tag: "strong", value: "from " },
        { tag: "strong", customProp: true, value: "t0" },
        { tag: "strong", value: " to " },
        { tag: "strong", customProp: true, value: "t1", append: ":" },
      ],
    },
    {
      requiredVariables: [],
      messageArray: [brTag, brTag, { tag: "span", value: partTwo }],
    },
  ]
}
const getQualityEbitMargin = (score) => {
  const partOne = getCheckMessage(
    score,
    "Rising 5-yr ",
    "5-yr ",
    "Declining 5-yr "
  )

  const partTwo = getCheckMessage(
    score,
    "Indicates a higher quality business that is growing revenues faster than expenses (i.e. operating leverage)",
    "Indicates a decent quality business with relatively stable margins",
    "Indicates a lower quality business that may be struggling. Alternatively, the business may be temporarily reinvesting in growth initiatives"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        {
          tag: "strong",
          value: "EBIT margins",
          highlightMetric: "checkValue",
          to: { source: "financials", tab: "r" },
        },
      ],
    },
    {
      requiredVariables: ["t0", "t1"],
      messageArray: [
        { tag: "strong", value: "from" },
        { tag: "strong", customProp: true, value: "t0" },
        { tag: "strong", value: " to " },
        { tag: "strong", customProp: true, value: "t1", append: ":" },
      ],
    },
    {
      requiredVariables: [],
      messageArray: [
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const getQualityRoicMsg = (score) => {
  const partOne = getCheckMessage(score, "High LTM", "Fair LTM", "Low LTM")

  const partTwo = getCheckMessage(
    score,
    "Indicates a good company that generates attractive returns on the capital invested in the business",
    "Indicates an average quality business",
    "Indicates a lower quality business. If the company’s ROIC is less than its cost of capital, the company destroys value"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        {
          tag: "strong",
          value: "ROIC",
          highlightMetric: "checkValue",
          to: { source: "financials", tab: "r" },
        },
        { tag: "strong", value: "of" },
        { tag: "strong", customProp: true, value: "checkValue", append: ":" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const getQualityRoicFiveYearsMsg = (score) => {
  const partOne = getCheckMessage(score, "Rising", "Stable", "Declining")
  const partTwo = getCheckMessage(
    score,
    "Implies a good business that is expanding its returns on capital with time",
    "Implies a fairly steady business",
    "Implies a weaker business that is operating in a competitive or cyclical industry. Alternatively, company may be reinvesting for growth"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        {
          tag: "strong",
          value: "ROIC",
          highlightMetric: "checkValue",
          to: { source: "financials", tab: "r" },
        },
      ],
    },
    {
      requiredVariables: ["t0", "t1"],
      messageArray: [
        { tag: "strong", value: "from" },
        { tag: "strong", customProp: true, value: "t0" },
        { tag: "strong", value: " to " },
        { tag: "strong", customProp: true, value: "t1", append: ":" },
      ],
    },
    {
      requiredVariables: [],
      messageArray: [
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const qualityOptions = {
  grossMargin: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([50, 30], dataObj.checkValue?.v)
      const message = getQualityGrossMarginMsg(score)
      return { score, message }
    },
  },
  grossMarginFiveYears: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([0, "-0.02"], dataObj.checkValue?.v)
      const message = getQualityGrossMarginFiveYearsMsg(score)
      return { score, message }
    },
  },
  ebitMargin: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([0, "-0.02"], dataObj.checkValue?.v)
      const message = getQualityEbitMargin(score)
      return { score, message }
    },
  },
  roic: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([20, 10], dataObj.checkValue.v)
      const message = getQualityRoicMsg(score)
      return {
        score,
        message,
      }
    },
  },
  roicFiveYears: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([0, "-0.02"], dataObj.checkValue?.v)
      const message = getQualityRoicFiveYearsMsg(score)

      return {
        score,
        message,
      }
    },
  },
}
/**
 * GROWTH OPTIONS
 */
const getGrowthFiveYearStockPriceCagrMsg = (score) => {
  const partOne = getCheckMessage(score, "High", "Average", "Weak")
  const partTwo = getCheckMessage(
    score,
    "Highlights that company has delivered an above market return over the last 5 years",
    "Highlights that company has roughly matched overall market returns over the last 5 years",
    "Highlights that company has underperformed the market over the last 5 years"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        {
          tag: "strong",
          value: "stock price",
          highlightMetric: "checkValue",
          to: { source: "multiples", tab: "street" },
        },
        { tag: "strong", value: "returns of" },
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: "annualized (per year):" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const getGrowthLtmRevenueGrowthMsg = (score) => {
  const partOne = getCheckMessage(score, "High ", "Fair ", "Weak ")
  const partTwo = getCheckMessage(
    score,
    "Suggests the company is rapidly growing, but be sure to understand if growth is sustainable",
    "Suggests the company is growing in-line with average market growth",
    "Suggests the company is growing slowly due to industry maturation, competition, cyclicality, or short-term volatility"
  )
  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        { tag: "strong", customProp: true, value: "checkValue" },
        {
          tag: "strong",
          value: "revenue",
          highlightMetric: "checkValue",
          to: { source: "estimates", tab: "est" },
        },
        { tag: "strong", value: "growth year-over-year:" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const getGrowthFiveYearRevenueMsg = (score) => {
  const partOne = getCheckMessage(score, "High 5-year", "5-year", "Weak 5-year")
  const partTwo = getCheckMessage(
    score,
    "Suggests the company has grown quickly but be sure to analyze whether growth is sustainable. Potential unsustainable growth includes one-time sales, acquisitions, etc.",
    "Suggests the company has grown roughly in-line with average market growth",
    "Suggests the company has grown slowly which may be due to industry maturation, competition, cyclicality, or short-term volatility"
  )
  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        {
          tag: "strong",
          value: "revenue",
          highlightMetric: "checkValue",
          to: { source: "estimates", tab: "est" },
        },
        { tag: "strong", value: "CAGR of" },
        { tag: "strong", customProp: true, value: "checkValue", append: ":" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const getGrowthFiveYearEpsHistCagrMsg = (score) => {
  const partOne = getCheckMessage(
    score,
    "High 5-year ",
    "Fair 5-year ",
    "Weak 5-year "
  )
  const partTwo = getCheckMessage(
    score,
    "Company has rapidly grown its profits per share at above market rates",
    "Company has grown its profits per share roughly in-line with average market rates",
    "Company has grown its profits per share slowly which may be due to maturation, competition, or weak management"
  )
  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        {
          tag: "strong",
          value: "EPS",
          highlightMetric: "checkValue",
          to: { source: "estimates", tab: "est" },
        },
        { tag: "strong", value: "CAGR of" },
        { tag: "strong", customProp: true, value: "checkValue", append: ":" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const getGrowthLtmEpsGrowthYoYMsg = (score) => {
  const partOne = getCheckMessage(score, "High ", "Fair ", "Weak ")
  const partTwo = getCheckMessage(
    score,
    "Company is rapidly growing profits per share, but be sure to understand if growth is sustainable",
    "Company is growing profits per share roughly in-line with the overall market",
    "Company is growing profits per share slowly which may be due to industry maturation, competition, cyclicality, or short-term volatility"
  )
  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        { tag: "strong", customProp: true, value: "checkValue" },
        {
          tag: "strong",
          value: "EPS",
          highlightMetric: "checkValue",
          to: { source: "estimates", tab: "est" },
        },
        { tag: "strong", value: "growth year-over-year:" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const growthOptions = {
  fiveYearStockPriceCagr: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([0.09, 0.05], dataObj.checkValue?.v)
      const message = getGrowthFiveYearStockPriceCagrMsg(score)
      return { score, message }
    },
  },
  ltmRevenueGrowth: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([0.08, 0.04], dataObj.checkValue?.v)
      const message = getGrowthLtmRevenueGrowthMsg(score)
      return { score, message }
    },
  },
  fiveYearRevenueGrowth: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([0.08, 0.04], dataObj.checkValue?.v)
      const message = getGrowthFiveYearRevenueMsg(score)
      return { score, message }
    },
  },
  fiveYearEpsHistCagr: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([0.1, 0.05], dataObj.checkValue?.v)
      const message = getGrowthFiveYearEpsHistCagrMsg(score)
      return { score, message }
    },
  },
  ltmEpsGrowthYoY: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([0.1, 0.05], dataObj.checkValue?.v)
      const message = getGrowthLtmEpsGrowthYoYMsg(score)
      return { score, message }
    },
  },
}
/**
 * FORECAST OPTIONS
 */
const getForecastRevGrowthMsg = (score) => {
  const partOne = getCheckMessage(score, "Strong ", "Fair ", "Weak ")
  const partTwo = getCheckMessage(
    score,
    "Wall Street analysts expect company to grow revenues rapidly year-over-year",
    "Wall Street analysts expect company to grow revenues in-line with the overall market",
    "Wall Street analysts expect company to grow revenues slowly year-over-year"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: "FY+1" },
        {
          tag: "strong",
          value: "Revenue",
          highlightMetric: "checkValue",
          to: { source: "estimates", tab: "est" },
        },
        { tag: "strong", value: "growth YoY:" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const getForecastFcfGrowthMsg = (score) => {
  const partOne = getCheckMessage(score, "Strong ", "Fair ", "Weak ")
  const partTwo = getCheckMessage(
    score,
    "Wall Street analysts expect company to grow free cash flows rapidly year-over-year",
    "Wall Street analysts expect company to grow free cash flows in-line with the overall market year-over-year",
    "Wall Street analysts expect company to grow free cash flows at a slow rate year-over-year"
  )
  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: "FY+1" },
        {
          tag: "strong",
          value: "FCF",
          highlightMetric: "checkValue",
          to: { source: "estimates", tab: "est" },
        },
        { tag: "strong", value: "growth YoY:" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const getForecastEpsGrowthMsg = (score) => {
  const partOne = getCheckMessage(score, "Strong ", "Fair ", "Weak ")
  const partTwo = getCheckMessage(
    score,
    "Wall Street analysts expect company to grow profits per share rapidly year-over-year",
    "Wall Street analysts expect company to grow profits per share in-line with the overall market year-over-year",
    "Wall Street analysts expect company to grow profits per share at a slow rate year-over-year"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: "FY+1" },
        {
          tag: "strong",
          value: "EPS",
          highlightMetric: "checkValue",
          to: { source: "estimates", tab: "est" },
        },
        { tag: "strong", value: "growth YoY:" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const getForecastRevGrowthPlusTwoMsg = (score) => {
  const partOne = getCheckMessage(score, "Strong ", "Fair ", "Weak ")
  const partTwo = getCheckMessage(
    score,
    "Wall Street analysts expect company next year to grow revenues rapidly year-over-year",
    "Wall Street analysts expect company next year to grow revenues in-line with the overall market",
    "Wall Street analysts expect company next year to grow revenues at a slow rate year-over-year"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: "FY+2" },
        {
          tag: "strong",
          value: "Revenue",
          highlightMetric: "checkValue",
          to: { source: "estimates", tab: "est" },
        },
        { tag: "strong", value: "growth YoY" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const getForecastEpsGrowthPlusTwoMsg = (score) => {
  const partOne = getCheckMessage(score, "Strong ", "Fair ", "Weak ")
  const partTwo = getCheckMessage(
    score,
    "Wall Street analysts expect company next year to grow profits per share rapidly year-over-year",
    "Wall Street analysts expect company next year to grow profits per share in-line with the overall market",
    "Wall Street analysts expect company next year to grow profits per share at a slow rate year-over-year"
  )
  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: "FY+2" },
        {
          tag: "strong",
          value: "EPS",
          highlightMetric: "checkValue",
          to: { source: "estimates", tab: "est" },
        },
        { tag: "strong", value: "growth YoY:" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const forecastOptions = {
  revGrowth: {
    logicFunction: (dataObj) => {
      const value = dataObj.checkValue?.v
      const score = value > 0.08 ? 2 : value < 0.04 ? 0 : 1
      const message = getForecastRevGrowthMsg(score)
      return { score, message }
    },
  },
  fcfGrowth: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([0.08, 0.04], dataObj.checkValue?.v)
      const message = getForecastFcfGrowthMsg(score)
      return { score, message }
    },
  },
  epsGrowth: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([0.1, 0.05], dataObj.checkValue?.v)
      const message = getForecastEpsGrowthMsg(score)
      return { score, message }
    },
  },
  revGrowthPlusTwo: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([0.08, 0.04], dataObj.checkValue?.v)
      const message = getForecastRevGrowthPlusTwoMsg(score)
      return { score, message }
    },
  },
  epsGrowthPlusTwo: {
    logicFunction: (dataObj) => {
      const score = getScoreFromDescCutoff([0.1, 0.05], dataObj.checkValue?.v)
      const message = getForecastEpsGrowthPlusTwoMsg(score)
      return { score, message }
    },
  },
}

/**
 * VALUATION OPTIONS
 */
const getValuationTevOverRevenueMsg = (score, isFirstParamAbove) => {
  const aboveOrBelow = isFirstParamAbove ? "above" : "below"
  const partOne = getCheckMessage(
    score,
    "Stock may be undervalued assuming business fundamentals are intact",
    "Stock may be fairly valued since its multiple is roughly in-line with its average multiple",
    "Stock may be overvalued since its current multiple is above its average"
  )

  return [
    {
      requiredVariables: ["last"],
      messageArray: [
        { tag: "strong", value: "NTM" },
        {
          tag: "strong",
          value: "EV/Revenue",
          highlightMetric: "last",
          to: {
            source: "multiples",
            tab: "multi",
          },
        },
        { tag: "strong", value: "of" },
        { tag: "strong", customProp: true, value: "last" },
      ],
    },
    {
      requiredVariables: ["fiveYearAvg"],
      messageArray: [
        { tag: "strong", value: " is " },
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: aboveOrBelow },
        { tag: "strong", value: " 5-Year Average of " },
        { tag: "strong", customProp: true, value: "fiveYearAvg" },
      ],
    },
    {
      requiredVariables: [],
      messageArray: [
        brTag,
        brTag,
        {
          tag: "span",
          value: partOne,
        },
      ],
    },
  ]
}
const getValuationPriceOverEarningsMsg = (score, isFirstParamAbove) => {
  const aboveOrBelow = isFirstParamAbove ? "above" : "below"
  const partOne = getCheckMessage(
    score,
    "Stock may be undervalued assuming business fundamentals are intact",
    "Stock may be fairly valued since its multiple is roughly in-line with its average multiple",
    "Stock may be overvalued since its current multiple is above its average"
  )

  return [
    {
      requiredVariables: ["ntm"],
      messageArray: [
        { tag: "strong", value: "NTM" },
        {
          tag: "strong",
          value: "P/E",
          to: {
            source: "multiples",
            tab: "multi",
          },
        },
        { tag: "strong", value: "of" },
        { tag: "strong", customProp: true, value: "ntm" },
      ],
    },
    {
      requiredVariables: ["fiveYearAvg"],
      messageArray: [
        { tag: "strong", value: " is " },
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: aboveOrBelow },
        { tag: "strong", value: " 5-Year Average of " },
        { tag: "strong", customProp: true, value: "fiveYearAvg", append: ":" },
      ],
    },
    {
      requiredVariables: [],
      messageArray: [
        brTag,
        brTag,
        {
          tag: "span",
          value: partOne,
        },
      ],
    },
  ]
}
const getValuationTevOverRevenueYoyMsg = (score, isFirstParamAbove) => {
  const upOrDown = isFirstParamAbove ? "up" : "down"
  const partOne = getCheckMessage(
    score,
    "Stock is cheaper and a better investment year-over-year assuming fundamentals are intact",
    "Stock’s multiple is roughly in-line with its multiple from a year ago",
    "Stock is more expensive year-over-year and therefore potentially a less attractive investment"
  )
  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: "NTM" },
        {
          tag: "strong",
          value: "EV/Revenue",
          to: {
            source: "multiples",
            tab: "multi",
          },
        },
        { tag: "strong", value: upOrDown },
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: "YoY:" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partOne,
        },
      ],
    },
  ]
}
const getValuationLastPriceOverStreetPriceMsg = (score, isFirstParamAbove) => {
  const upOrDown = isFirstParamAbove ? "Upside" : "Downside"
  const partOne = getCheckMessage(
    score,
    "Wall Street analysts believe the stock is undervalued",
    "Wall Street analysts believe that the stock is roughly fairly valued",
    "Wall Street analysts believe stock is overvalued at current"
  )
  return [
    {
      requiredVariables: ["streetPrice"],
      messageArray: [
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: upOrDown },
        { tag: "strong", value: "to " },
        {
          tag: "strong",
          value: "Street Target",
          to: { source: "multiples", tab: "street" },
        },
        { tag: "strong", value: "of" },
        { tag: "strong", customProp: true, value: "streetPrice", append: ":" },
      ],
    },
    {
      requiredVariables: [],
      messageArray: [
        brTag,
        brTag,
        {
          tag: "span",
          value: partOne,
        },
      ],
    },
    {
      requiredVariables: ["stockPrice"],
      messageArray: [
        {
          tag: "span",
          value: "at its current",
        },
        {
          tag: "span",
          value: "stock price",
          to: { source: "multiples", tab: "street" },
        },
        { tag: "span", value: "of" },
        {
          tag: "strong",
          customProp: true,
          value: "stockPrice",
          removeFromTitle: true,
        },
        { tag: "span", value: " (last close)" },
      ],
    },
  ]
}
const getValuationlastPriceOver52WeekHigh = (score) => {
  const partOne = getCheckMessage(
    score,
    "This is a meaningful discount to the 52-Week High price (although be careful of deteriorating fundamentals)",
    "This is a slight discount to the 52-Week High price",
    "This is a minimal discount to the 52-Week High price"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: "Last close" },
        {
          tag: "strong",
          value: "stock price",
          to: { source: "multiples", tab: "street" },
        },
        { tag: "strong", value: "is" },
        { tag: "strong", customProp: true, value: "checkValue" },
        {
          tag: "strong",
          value: "below 52-Week High.",
        },
        brTag,
        brTag,
        {
          tag: "span",
          value: partOne,
        },
      ],
    },
  ]
}

const valuationOptions = {
  tevOverRevenue: {
    logicFunction: (variablesObj) => {
      const { checkValue } = variablesObj
      const percentage = checkValue.v
      const score = percentage < -10 ? 2 : percentage < 10 ? 1 : 0
      const message = getValuationTevOverRevenueMsg(score, percentage > 0)

      return { score, message }
    },
  },
  priceOverEarnings: {
    logicFunction: (variablesObj) => {
      const { checkValue } = variablesObj
      const percentage = checkValue.v
      const score = percentage < -10 ? 2 : percentage < 10 ? 1 : 0
      const message = getValuationPriceOverEarningsMsg(score, percentage > 0)
      return { score, message }
    },
  },
  tevOverRevenueYoY: {
    logicFunction: (variablesObj) => {
      const { checkValue } = variablesObj
      const value = checkValue.v
      const score = value < -0.1 ? 2 : value < 0.1 ? 1 : 0
      const message = getValuationTevOverRevenueYoyMsg(score, value > 0)
      return { score, message }
    },
  },
  lastPriceOverStreetPrice: {
    logicFunction: (variablesObj) => {
      const { checkValue, stockPrice, streetPrice } = variablesObj
      const value = checkValue?.v
      const streetPriceValue = streetPrice.v
      const stockPriceValue = stockPrice.v
      let score
      if (value <= 0.9) {
        score = 2
      } else if (value < 1.1 && value > 0.9) {
        score = 1
      } else {
        score = 0
      }
      const message = getValuationLastPriceOverStreetPriceMsg(
        score,
        stockPriceValue < streetPriceValue
      )

      return { score, message }
    },
  },
  lastPriceOver52WeekHigh: {
    logicFunction: (variablesObj) => {
      const { checkValue } = variablesObj
      const value = checkValue.v
      const score = value < -0.15 && value > -0.25 ? 1 : value <= -0.25 ? 2 : 0
      const message = getValuationlastPriceOver52WeekHigh(score)
      return { score, message }
    },
  },
}

/**
 * SAFETY OPTIONS
 */
const getSafetyFreeCashFlowRiskMsg = (score) => {
  const partOne = getCheckMessage(score, "Positive", "Positive", "Negative")
  const partTwo = getCheckMessage(
    score,
    "each of the last 3 years:",
    "in 2 of the last 3 years:",
    ""
  )
  const partThree = getCheckMessage(
    score,
    "Company has strong cash flow generation and should therefore have lower risk",
    "Company has had some volatility in cash flow generation. It may be worth further researching the stability of cash flow for this business",
    "Company had negative cash flow in 2 of the 3 past years. Company may have higher risk of bankruptcy and could require additional equity or debt financing to fund cash burn"
  )
  return [
    {
      requiredVariables: [],
      messageArray: [
        {
          tag: "strong",
          value: partOne,
        },
        {
          tag: "strong",
          value: "Free Cash Flow",
          to: { source: "estimates", tab: "est" },
        },
        {
          tag: "strong",
          value: partTwo,
        },
        brTag,
        brTag,
        {
          tag: "span",
          value: partThree,
        },
      ],
    },
  ]
}

const getMsgByLetter = (letter, object) => {
  return object[letter]
}

const getSafetyLtmNdEbitdaMsg = (
  score,
  netCashVal,
  hideCheckValue = false,
  letter
) => {
  const isNetCashPositive = netCashVal >= 0

  const partOne = getCheckMessage(score, "Healthy", "", "Risky")
  const netCashOrDebtStr = netCashVal < 0 ? "net cash" : "net debt"
  const partTwo = isNetCashPositive ? "Net Debt/EBITDA" : "Net Cash/EBITDA"

  const msgA = "Company has a healthy balance sheet with limited risk from debt"
  const msgB =
    "Generally a manageable level of debt, but it’s worth further researching the potential financial risks for this stock"
  const msgC =
    "Company has excess debt which increases risk of bankruptcy. It’s worth researching company’s ability to meet its interest and principal debt payments"
  const msgD =
    "Company has a healthy cash balance relative to its EBITDA losses and should have a good runway before having to raise more capital"
  const msgE =
    "Company has a decent cash balance relative to its EBITDA losses but may need to raise more capital or become profitable soon"
  const msgF =
    "Company will need to raise capital soon or it may be at risk of potential bankruptcy given it has a net debt balance and EBITDA losses"
  const partThree = getMsgByLetter(letter, {
    a: msgA,
    b: msgB,
    c: msgC,
    d: msgD,
    e: msgE,
    f: msgF,
  })

  let objArr = [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        {
          tag: "strong",
          value: partTwo,
          to: { source: "estimates", tab: "est" },
        },
        { tag: "strong", value: "of" },
        { tag: "strong", customProp: true, value: "checkValue", append: ":" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partThree,
        },
      ],
    },
    {
      requiredVariables: ["netCash", "ebitda"],
      messageArray: [
        { tag: "span", customProp: true, value: "netCash", prepend: "(" },
        {
          tag: "span",
          value: netCashOrDebtStr,
          to: { source: "estimates", tab: "est" },
        },
        { tag: "span", value: "against" },
        { tag: "span", customProp: true, value: "ebitda" },
        {
          tag: "span",
          value: "EBITDA",
          to: { source: "estimates", tab: "est" },
          append: ")",
        },
      ],
    },
  ]

  if (hideCheckValue) {
    objArr = objArr.reduce((acc, item) => {
      const { messageArray } = item
      const newMsgArray = []
      for (const element of messageArray) {
        const isCheckValue =
          element.customProp && element.value === "checkValue"
        const isCheckValuePrepend =
          element.tag === "strong" && element.value === "of"
        const isCheckValuePartial = isCheckValue || isCheckValuePrepend
        if (!isCheckValuePartial) {
          newMsgArray.push(element)
        }
        if (isCheckValue) {
          newMsgArray.push({ tag: "strong", value: "Position:" })
        }
      }
      const objUpdated = {
        ...item,
        messageArray: newMsgArray,
      }
      acc.push(objUpdated)
      return acc
    }, [])
  }
  return objArr
}
const getSafetyLtmInterestCoverageMsg = (score) => {
  const partOne = getCheckMessage(score, "Healthy", "", "Weak")
  const partTwo = getCheckMessage(
    score,
    "Company can comfortably service its interest payments with its income",
    "Company can likely service its interest payments but it’s worth further researching the potential financial risks (",
    "Company is at risk of failing to meet its interest payments which can lead to bankruptcy without further financing ("
  )
  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        {
          tag: "strong",
          value: "Interest Coverage",
          to: { source: "financials", tab: "is" },
        },
        { tag: "strong", value: "of" },
        { tag: "strong", customProp: true, value: "checkValue", append: ":" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
    {
      requiredVariables: ["ebit", "iExpense"],
      messageArray: [
        { tag: "span", customProp: true, value: "ebit", prepend: "(" },
        { tag: "span", value: "EBIT", to: { source: "financials", tab: "is" } },
        { tag: "span", value: " against" },
        {
          tag: "span",
          customProp: true,
          value: "iExpense",
        },
        {
          tag: "span",
          value: " Interest Expense",
          append: ")",
          to: { source: "financials", tab: "is" },
        },
      ],
    },
  ]
}
const getSafetyCfoNetIncomeMsg = (score) => {
  const partOne = getCheckMessage(
    score,
    "Good Earnings Quality: ",
    "Fair Earnings Quality: ",
    "Weak Earnings Quality: "
  )
  const partTwo = getCheckMessage(
    score,
    " This indicates that company has good income-to-cash conversion",
    " This indicates that company has decent income-to-cash conversion",
    " Management may be artificially boosting Net Income in the short-term, since the income statement is easier to manipulate than cash flow"
  )
  return [
    {
      requiredVariables: [],
      messageArray: [{ tag: "strong", value: partOne }],
    },
    {
      requiredVariables: ["cfo", "netIncomeDASum"],
      messageArray: [
        { tag: "strong", customProp: true, value: "cfo" },
        {
          tag: "strong",
          value: "Operating Cash Flow",
          to: { source: "financials", tab: "cf" },
        },
        { tag: "strong", value: "vs." },
        { tag: "strong", customProp: true, value: "netIncomeDASum" },
        {
          tag: "strong",
          value: "Net Income",
          to: { source: "financials", tab: "cf" },
        },
        { tag: "strong", value: "+" },
        {
          tag: "strong",
          value: "D&A.",
          to: { source: "financials", tab: "cf" },
        },
      ],
    },
    {
      requiredVariables: [],
      messageArray: [
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
      ],
    },
  ]
}
const getSafetyDsoDsiMsg = (score, dsoDecreased, dsiDecreased) => {
  const decreasedIncreasedDso = dsoDecreased ? "decreased" : "increased"
  const decreasedIncreasedDsi = dsiDecreased ? "decreased" : "increased"
  const partOne = getCheckMessage(
    score,
    "Healthy Working Capital Trends:",
    "Mixed Working Capital Trends:",
    "Weak Working Capital Trends:"
  )
  const partTwo = getCheckMessage(score, "and", "while", "and")
  const partThree = getCheckMessage(
    score,
    "YoY. This means money is tied up in inventory and receivables for less time",
    "YoY",
    "YoY. Unhealthy working capital trends may be driven by inventory build-up as sales disappoint, customer collection issues, etc."
  )
  return [
    {
      requiredVariables: [],
      messageArray: [{ tag: "strong", value: partOne }, brTag, brTag],
    },
    {
      requiredVariables: ["dsi"],
      messageArray: [
        {
          tag: "span",
          value: "Average",
        },
        {
          tag: "span",
          value: "Days Inventory Outstanding",
          to: {
            source: "financials",
            tab: "r",
          },
        },
        {
          tag: "span",
          value: decreasedIncreasedDsi,
        },
        { tag: "span", customProp: true, value: "dsi" },
        { tag: "span", value: "YoY" },
      ],
    },
    {
      requiredVariables: ["dso"],
      messageArray: [
        { tag: "span", value: partTwo },
        {
          tag: "span",
          value: "Days Sales Outstanding",
          to: { source: "financials", tab: "r" },
        },
        { tag: "span", value: decreasedIncreasedDso },
        { tag: "span", customProp: true, value: "checkValue" },
        {
          tag: "span",
          value: partThree,
        },
      ],
    },
  ]
}
const safetyOptions = {
  freeCashFlowRisk: {
    logicFunction: (variablesObj) => {
      const { checkValue, tMinus1, tMinus2 } = variablesObj
      const allFcfArr = [checkValue.v, tMinus1.v, tMinus2.v]
      const fcfArrGtZero = allFcfArr.filter(
        (fcf) => typeof fcf === "number" && fcf > 0
      )
      const score =
        fcfArrGtZero.length === 3 ? 2 : fcfArrGtZero.length === 2 ? 1 : 0
      const message = getSafetyFreeCashFlowRiskMsg(score)
      return {
        score,
        message,
      }
    },
  },
  ltmNdEbitda: {
    logicFunction: (variablesObj) => {
      const { checkValue, ebitda, netCash } = variablesObj
      const value = checkValue.v
      const ebitdaValue = ebitda.v
      const netCashValue = netCash.v
      const isEbitdaPositive = ebitdaValue >= 0
      const isNetCashPositive = netCashValue >= 0
      let score
      let hideCheckValue
      let letter

      if (isEbitdaPositive) {
        if (value < 0) {
          score = 2
          letter = "a"
        } else if (value < 3) {
          score = 1
          letter = "b"
        } else {
          score = 0
          letter = "c"
        }
      } else if (value > 3) {
        score = 2
        letter = "d"
      } else if (value > 0 && value < 3) {
        score = 1
        letter = "e"
      } else if (isNetCashPositive) {
        score = 0
        letter = "f"
        hideCheckValue = true
      }
      const message = getSafetyLtmNdEbitdaMsg(
        score,
        netCashValue,
        hideCheckValue,
        letter
      )
      return {
        score,
        message,
      }
    },
  },
  ltmInterestCoverage: {
    logicFunction: (variablesObj) => {
      const { checkValue } = variablesObj
      const value = checkValue.v
      const score = value > 3 ? 2 : value > 2 && value < 3 ? 1 : 0
      const message = getSafetyLtmInterestCoverageMsg(score)
      return {
        score,
        message,
      }
    },
  },
  cfoNetIncome: {
    logicFunction: (variablesObj) => {
      const { checkValue } = variablesObj
      const value = checkValue.v
      const score = value > 0.9 ? 2 : value > 0.7 ? 1 : 0
      const message = getSafetyCfoNetIncomeMsg(score)
      return {
        score,
        message,
      }
    },
  },
  dsoDsi: {
    logicFunction: (variablesObj) => {
      const { checkValue, dsi } = variablesObj
      const dsoValue = checkValue.v
      const dsiValue = dsi.v
      const dsoDecreased = dsoValue < 0
      const dsiDecreased = dsiValue < 0
      const score =
        dsoDecreased && dsiDecreased ? 2 : dsoDecreased || dsiDecreased ? 1 : 0
      const message = getSafetyDsoDsiMsg(score, dsoDecreased, dsiDecreased)

      return { score, message }
    },
  },
}

/**
 * DIVIDEND OPTIONS
 */
const getDividendDpsFiveYearCagrMsg = (score) => {
  const partOne = getCheckMessage(
    score,
    "Company has seen its Dividends/Share increase from ",
    "Company has seen its Dividends/Share increase from ",
    "Company has seen its Dividends/Share go from "
  )
  const partTwo = getCheckMessage(
    score,
    " over the past 5 years",
    " over the past 5 years",
    " over the past 5 years. This is a below average growth rate"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: "5-Year" },
        {
          tag: "strong",
          value: "Dividends Per Share",
          to: { source: "financials", tab: "is" },
        },
        { tag: "strong", value: "CAGR:" },
        brTag,
        brTag,
      ],
    },
    {
      requiredVariables: ["t0", "t1"],
      messageArray: [
        {
          tag: "span",
          value: partOne,
        },
        { tag: "span", customProp: true, value: "t0" },
        { tag: "span", value: " to " },
        { tag: "span", customProp: true, value: "t1" },
        { tag: "span", value: partTwo },
      ],
    },
  ]
}
const getDividendDpsTwoYearFwdCagrMsg = (score) => {
  const partOne = getCheckMessage(
    score,
    "at an attractive growth rate over the next 2 years",
    "in-line with with the overall market",
    "at a slow rate over the next 2 years"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: "Dividends/Share FY+2 CAGR:" },
        {
          tag: "span",
          value:
            "Wall Street analysts forecast company to grow its Dividends/Share",
        },
        { tag: "span", value: partOne },
      ],
    },
  ]
}
const getDividendStableDividendsMsg = (score) => {
  const partOne = getCheckMessage(
    score,
    "Highly Stable Dividend History:",
    "Fairly Stable Dividend History:",
    "Volatile Dividend History:"
  )
  const partTwo = getCheckMessage(
    score,
    "Company has never had its",
    "Company has never had its",
    "Company has seen its"
  )
  const partThree = getCheckMessage(
    score,
    "decline by more than 5% in any of the last 10 years",
    "decline by more than 15% in any of the last 10 years",
    "decline by more than 15% in at least one of the last 10 years"
  )
  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", value: partOne },
        brTag,
        brTag,
        {
          tag: "span",
          value: partTwo,
        },
        {
          tag: "span",
          value: "dividends",
          to: { source: "financials", tab: "r" },
        },
        {
          tag: "span",
          value: partThree,
        },
      ],
    },
  ]
}
const getDividendPayoutRatioMsg = (score) => {
  const partOne = getCheckMessage(
    score,
    "Company can comfortably meet its dividend payments",
    "Company can meet its dividend payments",
    "The dividend may be at risk of being cut if profits decline given the lower margin of safety"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: "Dividend Payout Ratio:" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partOne,
        },
      ],
    },
    {
      requiredVariables: ["dps", "eps"],
      messageArray: [
        { tag: "span", customProp: true, value: "dps", prepend: "(" },
        {
          tag: "span",
          value: "Dividends Per Share",
          to: { source: "financials", tab: "is" },
        },
        { tag: "span", value: "vs." },
        { tag: "span", customProp: true, value: "eps" },
        {
          tag: "span",
          value: "EPS",
          append: ")",
          to: { source: "financials", tab: "is" },
        },
      ],
    },
  ]
}
const getDividendTwoYearsPayoutRatio = (score) => {
  const partOne = getCheckMessage(
    score,
    "Wall Street analysts expect the company to comfortably meet its dividend payments next year",
    "Wall Street analysts expect the company to meet its dividend payments next year",
    "The dividend may be at risk of being cut given the lower margin of safety"
  )

  return [
    {
      requiredVariables: [],
      messageArray: [
        { tag: "strong", customProp: true, value: "checkValue" },
        { tag: "strong", value: "FY+2 Dividend Payout Ratio:" },
        brTag,
        brTag,
        {
          tag: "span",
          value: partOne,
        },
      ],
    },
    {
      requiredVariables: ["dps", "eps"],
      messageArray: [
        { tag: "span", customProp: true, value: "dps", prepend: "(" },
        { tag: "span", value: " FY+2" },
        {
          tag: "span",
          value: "Dividends Per Share",
          to: { source: "financials", tab: "is" },
        },
        { tag: "span", value: "vs." },
        { tag: "span", customProp: true, value: "eps" },
        { tag: "span", value: "FY+2" },
        {
          tag: "span",
          value: "EPS",
          append: ")",
          to: { source: "financials", tab: "is" },
        },
      ],
    },
  ]
}
const dividendOptions = {
  dividendsPerShareFiveYearCagr: {
    logicFunction: (variablesObj) => {
      const { checkValue } = variablesObj
      const value = checkValue.v
      const score = value >= 0.06 ? 2 : value >= 0.03 ? 1 : 0
      const message = getDividendDpsFiveYearCagrMsg(score)
      return { score, message }
    },
  },
  dpsTwoYearFwdCagr: {
    logicFunction: (variablesObj) => {
      const { checkValue } = variablesObj
      const value = checkValue.v
      const score = value >= 0.05 ? 2 : value >= 0.02 ? 1 : 0
      const message = getDividendDpsTwoYearFwdCagrMsg(score)
      return {
        score,
        message,
      }
    },
  },
  stableDividends: {
    logicFunction: (variablesObj) => {
      const {
        tMinusZero,
        tMinus1,
        tMinus2,
        tMinus3,
        tMinus4,
        tMinus5,
        tMinus6,
        tMinus7,
        tMinus8,
        tMinus9,
        tMinus10,
      } = variablesObj
      const dividendArr = [
        tMinusZero,
        tMinus1,
        tMinus2,
        tMinus3,
        tMinus4,
        tMinus5,
        tMinus6,
        tMinus7,
        tMinus8,
        tMinus9,
        tMinus10,
      ].map(({ v }) => v)
      const yoyChange = dividendArr.reduce((arr, item, idx) => {
        const lastValue = dividendArr[idx - 1]
        if (lastValue) {
          const calc = lastValue / item - 1
          arr.push(calc)
          return arr
        } else {
          return arr
        }
      }, [])
      const hadFivePercentDecline =
        yoyChange.filter((val) => val < -0.05).length > 0
      const hadFifteenPercentDecline =
        yoyChange.filter((val) => val < -0.15).length > 0
      const score = !hadFivePercentDecline
        ? 2
        : hadFifteenPercentDecline
        ? 0
        : 1
      const message = getDividendStableDividendsMsg(score)

      return { score, message }
    },
  },
  payoutRatio: {
    logicFunction: (variablesObj) => {
      const { checkValue } = variablesObj
      const value = checkValue.v
      const score = value <= 0.7 ? 2 : value < 0.9 ? 1 : 0
      const message = getDividendPayoutRatioMsg(score)
      return { score, message }
    },
  },
  twoYearsPayoutRatio: {
    logicFunction: (variablesObj) => {
      const { checkValue } = variablesObj
      const value = checkValue.v
      const score = value > 0.9 ? 0 : value > 0.7 ? 1 : 2
      const message = getDividendTwoYearsPayoutRatio(score)
      return { score, message }
    },
  },
}

export {
  maxPercentageWidget,
  growthOptions,
  qualityOptions,
  forecastOptions,
  valuationOptions,
  safetyOptions,
  dividendOptions,
  notApplicableMsgDimension,
}
