var useCiqValuationState = function useCiqValuationState() {
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var valuationData = computed(function () {
    var _$store$state$ciq;
    var tid = $store.state.ciq.ticker.tradingitemid;
    return ((_$store$state$ciq = $store.state.ciq) === null || _$store$state$ciq === void 0 || (_$store$state$ciq = _$store$state$ciq.addMultiples) === null || _$store$state$ciq === void 0 || (_$store$state$ciq = _$store$state$ciq[tid]) === null || _$store$state$ciq === void 0 ? void 0 : _$store$state$ciq.tblDataObj) || {};
  });
  return {
    valuationData: valuationData
  };
};
export default useCiqValuationState;