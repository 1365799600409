import metrics from "~/utils/constants/metrics"

const {
  dpsEstimate,
  epsNormalized,
  epsWithFallback,
  grossProfitMargin,
  payoutRatio,
  returnOnCapital,
  revenue,
  tevOverLtmRevenueDivision,
  tevOverNtmRevenueDivision,
} = metrics

const cagrLastFiveYearsFormula = {
  type: "cagr",
  startRelativePeriod: -5,
  endRelativePeriod: 0,
}

const variables = {
  quality: {
    grossMargins: {
      metricKeys: [grossProfitMargin],
      relativeDate: "ltm",
      formula: "val",
      formatType: "whole_pct",
    },
    roc: {
      metricKeys: [returnOnCapital],
      relativeDate: "ltm",
      formula: "val",
      formatType: "whole_pct",
    },
  },
  growth: {
    revenues: {
      metricKeys: [revenue],
      formula: cagrLastFiveYearsFormula,
      formatType: "pct",
    },
    eps: {
      metricKeys: [epsWithFallback],
      formula: cagrLastFiveYearsFormula,
      formatType: "pct",
    },
  },
  forecast: {
    revenues: {
      metricKeys: [revenue],
      formula: {
        type: "cagr",
        startRelativePeriod: 0,
        endRelativePeriod: 2,
      },
      formatType: "pct",
    },
    eps: {
      metricKeys: [epsNormalized],
      formula: {
        type: "cagr",
        startRelativePeriod: 0,
        endRelativePeriod: 2,
      },
      formatType: "pct",
    },
  },
  valuation: {
    evRevFiveYearAvg: {
      metricKeys: [
        {
          name: "fiveYearAvg",
          aliasId: "fiveYearAvg",
          source: [
            {
              ...tevOverNtmRevenueDivision,
              valuePropKey: "5yr",
              tableNameId: "NTM Total Enterprise Value / Revenues",
            },
            {
              ...tevOverLtmRevenueDivision,
              valuePropKey: "5yr",
              tableNameId: "LTM Total Enterprise Value / Revenues",
            },
          ],
        },
        {
          name: "oneYearAvg",
          aliasId: "oneYearAvg",
          source: [
            {
              ...tevOverNtmRevenueDivision,
              valuePropKey: "last",
            },
            {
              ...tevOverLtmRevenueDivision,
              valuePropKey: "last",
            },
          ],
        },
      ],
      formula: {
        type: "custom",
        evaluate: `* 100 (/ (- {{ oneYearAvg }} {{ fiveYearAvg }}) {{ fiveYearAvg }})`,
      },
      formatType: "whole_pct",
    },
    ntmPriceOverEarningsFiveYearAvgComparison: {
      metricKeys: [
        {
          name: "fiveYearAvg",
          aliasId: "fiveYearAvg",
          source: [
            {
              metricKey: "div-priceclose-eps normalized",
              source: "valuationStats",
              valuePropKey: "5yr",
            },
            {
              metricKey: "div-priceclose-diluted eps excl extra items 142",
              source: "valuationStats",
              valuePropKey: "5yr",
            },
          ],
        },
        {
          name: "oneYearAvg",
          aliasId: "oneYearAvg",
          source: [
            {
              metricKey: "div-priceclose-eps normalized",
              source: "valuationStats",
              valuePropKey: "last",
            },
            {
              metricKey: "div-priceclose-diluted eps excl extra items 142",
              source: "valuationStats",
              valuePropKey: "last",
            },
          ],
        },
      ],
      formula: {
        type: "custom",
        evaluate: `* 100 (/ (- {{ oneYearAvg }} {{ fiveYearAvg }}) {{ fiveYearAvg }})`,
      },
      formatType: "whole_pct",
    },
  },
  dividend: {
    payoutRatio: {
      metricKeys: [payoutRatio],
      actualOrEstimateKey: "mean",
      relativeDate: "ltm",
      formula: "val",
      formatType: "whole_pct",
    },
    dps: {
      metricKeys: [dpsEstimate],
      actualOrEstimateKey: "actual",
      formula: cagrLastFiveYearsFormula,
      formatType: "pct",
    },
    dpsFiveYearsAgo: {
      metricKeys: [dpsEstimate],
      actualOrEstimateKey: "actual",
      relativeDateKey: -5,
      formula: "val",
      formatType: "currency",
    },
    dpsLast: {
      metricKeys: [dpsEstimate],
      actualOrEstimateKey: "actual",
      relativeDateKey: 0,
      formula: "val",
      formatType: "currency",
    },
  },
}

export default variables
