import "core-js/modules/es.number.constructor.js";
import { getDimensionScore } from "~/utils/dueDiligence/utils";
import useBreakpoints from "~/functions/useBreakpoints";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'DiligenceThermometer',
  props: {
    score: {
      type: Number,
      "default": 0
    },
    vertical: {
      type: Boolean,
      "default": false
    },
    disabled: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBreakpoints = useBreakpoints(),
      lg = _useBreakpoints.lg;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive;
    var isBullish = computed(function () {
      return getDimensionScore(props.score).isBullish;
    });
    var isBearish = computed(function () {
      return getDimensionScore(props.score).isBearish;
    });
    var isNeutral = computed(function () {
      return getDimensionScore(props.score).isNeutral;
    });
    var styles = {
      bullish: {
        light: {
          background: "#ACD18BCC",
          border: "#008000"
        },
        dark: {
          background: "#324029",
          border: "#4C7839"
        }
      },
      neutral: {
        light: {
          background: "#FBF7D5",
          border: "#F0E368"
        },
        dark: {
          background: "#48432B",
          border: "#6B652C"
        }
      },
      bearish: {
        light: {
          background: "#FFE1E1",
          border: "#F06868"
        },
        dark: {
          background: "#412A2C",
          border: "#78403B"
        }
      }
    };
    var disabledStyle = {
      light: {
        background: "#e9e9e9",
        border: "#D9D9D9"
      },
      dark: {
        background: "#494949",
        border: "#555"
      }
    };
    var wrapperStyle = computed(function () {
      var key = isBullish.value ? "bullish" : isBearish.value ? "bearish" : "neutral";
      var mode = darkActive.value ? "dark" : "light";
      var style = props.disabled ? disabledStyle[mode] : styles[key][mode];
      return {
        "max-width": lg.value ? "150px" : "50%",
        height: props.vertical ? "140px" : "227px",
        "--bg": style.background,
        "--border-color": style.border
      };
    });
    var listStyle = computed(function () {
      if (props.vertical) {
        return {
          "--left": isBullish.value ? "85%" : isBearish.value ? "25%" : "50%"
        };
      } else {
        return {
          "--top": isBullish.value ? "15%" : isBearish.value ? "83%" : "50%"
        };
      }
    });
    return {
      __sfc: true,
      lg: lg,
      darkActive: darkActive,
      props: props,
      isBullish: isBullish,
      isBearish: isBearish,
      isNeutral: isNeutral,
      styles: styles,
      disabledStyle: disabledStyle,
      wrapperStyle: wrapperStyle,
      listStyle: listStyle
    };
  }
};