import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function _createForOfIteratorHelper(r, e) {
  var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (!t) {
    if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) {
      t && (r = t);
      var _n = 0,
        F = function F() {};
      return {
        s: F,
        n: function n() {
          return _n >= r.length ? {
            done: !0
          } : {
            done: !1,
            value: r[_n++]
          };
        },
        e: function e(r) {
          throw r;
        },
        f: F
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var o,
    a = !0,
    u = !1;
  return {
    s: function s() {
      t = t.call(r);
    },
    n: function n() {
      var r = t.next();
      return a = r.done, r;
    },
    e: function e(r) {
      u = !0, o = r;
    },
    f: function f() {
      try {
        a || null == t["return"] || t["return"]();
      } finally {
        if (u) throw o;
      }
    }
  };
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { isArray } from "lodash";
import { useStorage } from "@vueuse/core";
import dayjs from "~/utils/tools/dayjs";
import { findFirstAvailableItemInObject } from "~/utils/tools/object";
import useBaseUtils from "~/functions/useBaseUtils";
import useCiqDataSource from "~/functions/useCiqDataSource";
import useCiqQuotesState from "~/functions/useCiqQuotesState";
import useNumberFormatters from "~/functions/useNumberFormatters";
import { notApplicableMsg, getDimensionScore, useObjectKeysAsDescCutoffValues } from "~/utils/dueDiligence/utils";
import { getLoaderByCellType, getFormulaKey } from "~/utils/ciq/common";
import { maxPercentageWidget, notApplicableMsgDimension } from "~/utils/dueDiligence/diligenceOptions.js";
import { USE_SESSION_STORAGE } from "~/feature-toggle";
var mapScoreToPercentage = function mapScoreToPercentage(actualScore, maxScore) {
  if (maxScore === 0) {
    return 0;
  }
  return actualScore / maxScore * 100;
};
var useCompanyScore = function useCompanyScore(dimensionsTemplate) {
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var _useBaseUtils = useBaseUtils(),
    customGroup = _useBaseUtils.customGroup;
  var _useNumberFormatters = useNumberFormatters(),
    getFormattedValue = _useNumberFormatters.getFormattedValue;
  var _useCiqQuotesState = useCiqQuotesState(),
    quotesCurrencyFormatter = _useCiqQuotesState.quotesCurrencyFormatter;
  var $route = useRoute();
  var symbol = computed(function () {
    var _$store$state$ciq;
    return (_$store$state$ciq = $store.state.ciq) === null || _$store$state$ciq === void 0 ? void 0 : _$store$state$ciq.ticker.tickersymbol;
  });
  var company = computed(function () {
    var _$store$state$ciq2;
    return (_$store$state$ciq2 = $store.state.ciq) === null || _$store$state$ciq2 === void 0 ? void 0 : _$store$state$ciq2.ticker.companyname;
  });
  var refResult = ref({});
  var storageResult = useStorage("DD-score-".concat($route.query.tid, "-").concat(customGroup.value), {}, sessionStorage);
  var isValidStoreObject = computed(function () {
    var _storageResult$value;
    if (!USE_SESSION_STORAGE) return false;
    var currentTimestamp = dayjs();
    var twoHoursAgo = currentTimestamp.subtract(2, "hour");
    var updatedTimestamp = (_storageResult$value = storageResult.value) === null || _storageResult$value === void 0 ? void 0 : _storageResult$value.updated;
    if (!updatedTimestamp) return false;
    var isWithinLastTwoHoursRange = dayjs(updatedTimestamp).isAfter(twoHoursAgo) && dayjs(updatedTimestamp).isBefore(currentTimestamp);
    return updatedTimestamp && isWithinLastTwoHoursRange;
  });
  var currentSources = computed(function () {
    var sources = new Set(Object.values(dimensionsTemplate).flatMap(function (dimension) {
      return dimension.data.flatMap(function (item) {
        return isArray(item.metricKeys) ? item.metricKeys.flatMap(function (element) {
          return isArray(element.source) ? element.source.map(function (item) {
            return item.metricKey || item.name;
          }) : [element.source];
        }) : [item.metricKeys.source];
      });
    }));
    return sources;
  });
  var isScoreLoading = computed(function () {
    if (USE_SESSION_STORAGE && isValidStoreObject.value) {
      return false;
    } // we need this too so we can pass the correct
    // quotesCurrencyFormatter into the initialization function
    var quotesLoading = $store.state.ciq.fetchingQuotes;
    var loaders = Array.from(currentSources.value).map(function (key) {
      return getLoaderByCellType(key, $store);
    });
    return loaders.filter(function (item) {
      return item === true;
    }).length > 0 || quotesLoading;
  });
  watch(isScoreLoading, function (isLoading) {
    var hasLoaded = isLoading === false;
    var shouldUpdateRef = USE_SESSION_STORAGE ? !isValidStoreObject.value : true;
    if (hasLoaded && shouldUpdateRef) {
      var newResult = createScoreResults({
        template: dimensionsTemplate,
        currencyFormatter: quotesCurrencyFormatter.value,
        company: company.value,
        symbol: symbol.value
      });
      if (USE_SESSION_STORAGE) {
        storageResult.value = _objectSpread(_objectSpread({}, newResult), {}, {
          updated: dayjs().valueOf()
        });
        return;
      }
      refResult.value = newResult;
    }
  });
  var getVarsObject = function getVarsObject() {
    var vars = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var currencyFormatter = arguments.length > 1 ? arguments[1] : undefined;
    var mappedVars = Object.keys(vars).reduce(function (acc, key) {
      var item = vars[key];
      var numerator = item.metricKeys[0];
      var numeratorKey = (numerator === null || numerator === void 0 ? void 0 : numerator.aliasId) || (numerator === null || numerator === void 0 ? void 0 : numerator.name);
      var _useCiqDataSource = useCiqDataSource(_objectSpread(_objectSpread({}, item), {}, {
          formattedValue: false
        })),
        mappedCellItems = _useCiqDataSource.mappedCellItems,
        cellValue = _useCiqDataSource.cellValue,
        isAnyCellUnauth = _useCiqDataSource.isAnyCellUnauth;
      var cellItem = mappedCellItems.value.get(numeratorKey);
      var formatted = getFormattedValue(cellValue.value, item === null || item === void 0 ? void 0 : item.formatType, currencyFormatter);
      var unauth = item.formula === "val" ? !!cellItem.unauth : isAnyCellUnauth.value;
      var object = _objectSpread(_objectSpread({}, cellItem), {}, {
        unauth: unauth,
        u: cellItem === null || cellItem === void 0 ? void 0 : cellItem.estimatescaleid,
        iso: findFirstAvailableItemInObject({
          item: cellItem,
          keys: ["isocode", "iso"],
          defaultEmpty: "USD"
        }),
        exrate: findFirstAvailableItemInObject({
          item: cellItem,
          keys: ["priceclose", "pc"],
          defaultEmpty: 1
        }),
        v: cellValue.value,
        formatted: formatted
      });
      acc[key] = object;
      return acc;
    }, {});
    return mappedVars;
  };
  var createVariablesObject = function createVariablesObject(_ref) {
    var _cellItem$;
    var company = _ref.company,
      symbol = _ref.symbol,
      _ref$vars = _ref.vars,
      vars = _ref$vars === void 0 ? {} : _ref$vars,
      value = _ref.value,
      cellItem = _ref.cellItem,
      formatType = _ref.formatType,
      currencyFormatter = _ref.currencyFormatter;
    var mappedVars = getVarsObject(vars, currencyFormatter);
    var formatted = getFormattedValue(value, formatType, currencyFormatter);
    var checkValue = {
      v: value,
      unauth: !!(cellItem !== null && cellItem !== void 0 && cellItem.unauth),
      u: cellItem === null || cellItem === void 0 ? void 0 : cellItem.estimatescaleid,
      iso: cellItem === null || cellItem === void 0 ? void 0 : cellItem.isocode,
      exrate: cellItem === null || cellItem === void 0 ? void 0 : cellItem.priceclose,
      formatted: formatted,
      _cellName: cellItem === null || cellItem === void 0 ? void 0 : cellItem._cellName,
      _sourceName: cellItem === null || cellItem === void 0 ? void 0 : cellItem._sourceName,
      _tableNameId: (cellItem === null || cellItem === void 0 ? void 0 : cellItem._tableNameId) || (cellItem === null || cellItem === void 0 || (_cellItem$ = cellItem[0]) === null || _cellItem$ === void 0 ? void 0 : _cellItem$.tableNameId)
    };
    return _objectSpread(_objectSpread({}, mappedVars), {}, {
      Company: company || symbol,
      checkValue: checkValue
    });
  };
  var checkNegative = function checkNegative(val) {
    return !Number.isNaN(val) && val < 0;
  }; // this is for cagr only
  var isAnyCagrCellNegative = function isAnyCagrCellNegative(cells) {
    var _iterator = _createForOfIteratorHelper(cells),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = _slicedToArray(_step.value, 2),
          val = _step$value[1];
        var _val = _slicedToArray(val, 2),
          start = _val[0],
          end = _val[1];
        var keys = ["dataitemvalue", "v"];
        var startVal = Number(findFirstAvailableItemInObject({
          item: start,
          keys: keys
        }));
        var endVal = Number(findFirstAvailableItemInObject({
          item: end,
          keys: keys
        }));
        var isAnyNegative = checkNegative(startVal) || checkNegative(endVal);
        if (isAnyNegative) {
          return true;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return false;
  };
  var checkInvalidity = function checkInvalidity(value, isAnyCellEmpty, formula, cells, isAnyCellUnauth, label) {
    var isCagr = formula === "cagr";
    var isCagrInvalid = isCagr && isAnyCagrCellNegative(cells);
    var isPriceOverEarningsException = label === "P/E NTM > 5YR AVG";
    var invalid = !value || isAnyCellEmpty || isAnyCellUnauth || isCagrInvalid;
    if (isPriceOverEarningsException) {
      var fiveYearAvg = cells.get("fiveYearAvg");
      var oneYearAvg = cells.get("oneYearAvg");
      var values = [oneYearAvg.v, fiveYearAvg.v];
      var isPositiveNumbers = values.every(function (num) {
        return num >= 0;
      });
      if (!isPositiveNumbers) {
        return true;
      }
    }
    return invalid;
  };
  var formatConditions = function formatConditions(_ref2) {
    var dimensionList = _ref2.dimensionList,
      currencyFormatter = _ref2.currencyFormatter,
      company = _ref2.company,
      symbol = _ref2.symbol;
    return dimensionList.map(function (item) {
      var _item$score;
      var _useCiqDataSource2 = useCiqDataSource(_objectSpread(_objectSpread({}, item), {}, {
          formattedValue: false
        })),
        cellValue = _useCiqDataSource2.cellValue,
        mappedCellItems = _useCiqDataSource2.mappedCellItems,
        isAnyCellEmpty = _useCiqDataSource2.isAnyCellEmpty,
        isAnyCellUnauth = _useCiqDataSource2.isAnyCellUnauth;
      var _item$metricKeys$ = item.metricKeys[0],
        name = _item$metricKeys$.name,
        aliasId = _item$metricKeys$.aliasId;
      var id = aliasId || name;
      var v = cellValue.value;
      var formulaKey = getFormulaKey(item.formula);
      var cell = mappedCellItems.value.get(id);
      var cellItem = isArray(cell) ? cell[0] : cell;
      var isInvalid = checkInvalidity(v, isAnyCellEmpty.value, formulaKey, mappedCellItems.value, isAnyCellUnauth.value, item.label);
      var vars = ((_item$score = item.score) === null || _item$score === void 0 ? void 0 : _item$score.variables) || {};
      var newVariablesObj = createVariablesObject({
        company: company,
        symbol: symbol,
        value: v,
        cellItem: cellItem,
        vars: vars,
        formatType: item === null || item === void 0 ? void 0 : item.formatType,
        currencyFormatter: currencyFormatter
      });
      var message;
      var actualCheckScore = 0;
      var maxCheckScore = 0;
      if (isInvalid) {
        message = notApplicableMsg;
        actualCheckScore = 0;
        maxCheckScore = 0;
      } else {
        var _item$score$options;
        var getDimensionData = ((_item$score$options = item.score.options) === null || _item$score$options === void 0 ? void 0 : _item$score$options.logicFunction) || useObjectKeysAsDescCutoffValues;
        var data = getDimensionData(newVariablesObj, item.score.options.messages);
        message = data.message;
        actualCheckScore = data.score;
        maxCheckScore = 2;
      }
      var percentage = mapScoreToPercentage(actualCheckScore, maxCheckScore);
      return {
        name: item.label,
        actualCheckScore: actualCheckScore,
        maxCheckScore: maxCheckScore,
        percentage: percentage,
        disabled: isInvalid,
        message: message,
        variablesObject: newVariablesObj,
        isAnyCellUnauth: isAnyCellUnauth.value
      };
    });
  };
  var getOverallData = function getOverallData(dimensions) {
    var sum = 0;
    var validKeys = 0;
    var keys = Object.keys(dimensions);
    for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
      var key = _keys[_i]; // filter out dividend dimension
      if (key !== "Dividend") {
        var _dimensions$key, _dimensions$key2;
        var _score = ((_dimensions$key = dimensions[key]) === null || _dimensions$key === void 0 ? void 0 : _dimensions$key.actualDimensionScore) || 0;
        sum += Number(_score);
        if (!((_dimensions$key2 = dimensions[key]) !== null && _dimensions$key2 !== void 0 && _dimensions$key2.unauthDimension)) {
          validKeys++;
        }
      }
    } // minus dividend dimension
    var disabledScore = validKeys !== keys.length - 1;
    var averageScore = sum / (keys.length - 1);
    var score = parseInt(averageScore);
    return {
      score: score,
      disabledScore: disabledScore
    };
  };
  var getDimensionMessage = function getDimensionMessage(options, score) {
    var _getDimensionScore = getDimensionScore(score),
      isBullish = _getDimensionScore.isBullish,
      isBearish = _getDimensionScore.isBearish;
    if (isBullish) {
      return options.a;
    } else if (isBearish) {
      return options.c;
    } else {
      return options.b;
    }
  };
  var mapDimensionScoreToPercentage = function mapDimensionScoreToPercentage(actualScore, maxScore) {
    if (maxScore === 0) {
      return 0;
    }
    return parseInt(maxPercentageWidget * (actualScore / maxScore));
  };
  var createScoreResults = function createScoreResults(_ref3) {
    var template = _ref3.template,
      currencyFormatter = _ref3.currencyFormatter,
      company = _ref3.company,
      symbol = _ref3.symbol;
    var dimensions = Object.keys(template).reduce(function (obj, key) {
      return reducedDimensions(obj, key, dimensionsTemplate, currencyFormatter, company, symbol);
    }, {});
    var _getOverallData = getOverallData(dimensions),
      score = _getOverallData.score,
      disabledScore = _getOverallData.disabledScore;
    return {
      overallScore: score,
      disabledScore: disabledScore,
      dimensions: dimensions
    };
  };
  var reducedDimensions = function reducedDimensions(obj, key, template, currencyFormatter, company, symbol) {
    var _dimension$score;
    var dimension = template[key] || {};
    var dimensionList = dimension.data || [];
    var conditions = formatConditions({
      dimensionList: dimensionList,
      currencyFormatter: currencyFormatter,
      company: company,
      symbol: symbol
    });
    var validConditions = conditions.filter(function (cond) {
      return !cond.disabled;
    }).length;
    var validDimension = conditions.filter(function (dimension) {
      return dimension.isAnyCellUnauth;
    }).length === 0 && validConditions > 0;
    var meta = dimension.meta;
    var scoreSum = conditions.reduce(function (acc, item) {
      var score = isFinite(item === null || item === void 0 ? void 0 : item.actualCheckScore) ? item === null || item === void 0 ? void 0 : item.actualCheckScore : 0;
      return acc + score;
    }, 0);
    var maxPoints = validConditions * 20;
    var actualDimensionScore = mapDimensionScoreToPercentage(scoreSum * 10, maxPoints);
    var dimensionVariables = getVarsObject((_dimension$score = dimension.score) === null || _dimension$score === void 0 ? void 0 : _dimension$score.variables, currencyFormatter);
    var dimensionVars = _objectSpread(_objectSpread({}, dimensionVariables), {}, {
      Company: company || symbol,
      score: actualDimensionScore.toString()
    });
    var isNotApplicableDimension = actualDimensionScore === 0 && maxPoints === 0 && key === "Dividend";
    var unauthDimension = conditions.filter(function (item) {
      return item.isAnyCellUnauth;
    }).length > 0;
    var dimensionMessage;
    if (isNotApplicableDimension) {
      dimensionMessage = notApplicableMsgDimension;
    } else {
      var _dimension$score2;
      var getDimensionMessageFn = ((_dimension$score2 = dimension.score) === null || _dimension$score2 === void 0 ? void 0 : _dimension$score2.logicFunction) || getDimensionMessage;
      dimensionMessage = getDimensionMessageFn(dimension.score.options, actualDimensionScore, dimensionVars);
    }
    obj[key] = _objectSpread(_objectSpread({
      actualDimensionScore: actualDimensionScore,
      maxDimensionScore: maxPoints,
      validDimension: validDimension,
      unauthDimension: unauthDimension,
      dimensionMessage: dimensionMessage,
      dimensionVars: dimensionVars
    }, meta), {}, {
      conditions: conditions
    });
    return obj;
  };
  return {
    isScoreLoading: isScoreLoading,
    scoreResults: USE_SESSION_STORAGE ? storageResult : refResult
  };
};
export default useCompanyScore;