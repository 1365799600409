import metrics from "~/utils/constants/metrics"

const {
  capitalExpenditure,
  dps,
  dpsEstimate,
  dsi,
  grossProfitMargin,
  revenue,
  returnOnCapital,
  epsNormalized,
  netDebt,
  ebitda,
  ebit,
  ebitAct,
  interestExpense,
  cfo,
  netIncome2150,
  tevOverNtmRevenueDivision,
  tevOverLtmRevenueDivision,
} = metrics

const variables = {
  ebitMargin: {
    t0: {
      metricKeys: [ebit],
      actualOrEstimateKey: "mean",
      formula: "val",
      relativeDateKey: -5,
      formatType: "whole_pct",
    },
    t1: {
      metricKeys: [ebit],
      actualOrEstimateKey: "mean",
      formula: "val",
      relativeDateKey: 0,
      formatType: "whole_pct",
    },
  },
  grossMarginFiveYears: {
    t0: {
      metricKeys: [grossProfitMargin],
      relativeDateKey: -5,
      formula: "val",
      formatType: "whole_pct",
    },
    t1: {
      metricKeys: [grossProfitMargin],
      relativeDateKey: 0,
      formula: "val",
      formatType: "whole_pct",
    },
  },
  revenueGrowthFiveYears: {
    t0: {
      metricKeys: [revenue],
      relativeDateKey: -5,
      actualOrEstimateKey: "actual",
      formula: "val",
    },
    t1: {
      metricKeys: [revenue],
      relativeDateKey: 0,
      actualOrEstimateKey: "actual",
      formula: "val",
    },
  },
  fiveYearEpsHistCagr: {
    t0: {
      metricKeys: [epsNormalized],
      relativeDateKey: -5,
      actualOrEstimateKey: "actual",
      formula: "val",
    },
    t1: {
      metricKeys: [epsNormalized],
      relativeDateKey: 0,
      actualOrEstimateKey: "actual",
      formula: "val",
    },
  },
  freeCashFlowRisk: {
    tMinus1: {
      metricKeys: [cfo, capitalExpenditure],
      relativeDateKey: -1,
      actualOrEstimateKey: "actual",
      formula: "sum",
    },
    tMinus2: {
      metricKeys: [cfo, capitalExpenditure],
      relativeDateKey: -2,
      actualOrEstimateKey: "actual",
      formula: "sum",
    },
  },
  ltmNdEbitda: {
    netCash: {
      metricKeys: [netDebt],
      relativeDateKey: 0,
      actualOrEstimateKey: "actual",
      formula: "val",
    },
    ebitda: {
      metricKeys: [ebitda],
      actualOrEstimateKey: "actual",
      formula: "val",
    },
  },
  ltmInterestCoverage: {
    ebit: {
      formula: "val",
      metricKeys: [ebitAct],
      actualOrEstimateKey: "actual",
    },
    iExpense: {
      formula: "val",
      metricKeys: [interestExpense],
    },
  },
  cfoNetIncome: {
    cfo: {
      metricKeys: [cfo],
      formula: "val",
    },
    netIncomeDASum: {
      metricKeys: [
        netIncome2150,
        {
          aliasId: "totalDepreciationAndAmortization",
          name: "total depreciation & amortization 2160",
          source: "financials",
        },
      ],
      formula: {
        type: "custom",
        evaluate: `+ {{ netIncome }} {{ totalDepreciationAndAmortization }}`,
      },
      actualOrEstimateKey: "actual",
    },
  },
  roicFiveYears: {
    t0: {
      metricKeys: [returnOnCapital],
      formula: "val",
      // relativeDate: "ltm",
      relativeDateKey: -5,
      formatType: "whole_pct",
    },
    t1: {
      metricKeys: [returnOnCapital],
      formula: "val",
      // relativeDate: "ltm",
      relativeDateKey: 0,
      formatType: "whole_pct",
    },
  },
  dsoDsi: {
    dsi: {
      metricKeys: [dsi],
      formula: "YoY",
      relativeDateKey: 0,
      formatType: "pct",
    },
  },
  stableDividends: {
    tMinusZero: {
      metricKeys: [dps],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: 0,
    },
    tMinus1: {
      metricKeys: [dps],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: -1,
    },
    tMinus2: {
      metricKeys: [dps],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: -2,
    },
    tMinus3: {
      metricKeys: [dps],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: -3,
    },
    tMinus4: {
      metricKeys: [dps],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: -4,
    },
    tMinus5: {
      metricKeys: [dps],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: -5,
    },
    tMinus6: {
      metricKeys: [dps],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: -6,
    },
    tMinus7: {
      metricKeys: [dps],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: -7,
    },
    tMinus8: {
      metricKeys: [dps],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: -8,
    },
    tMinus9: {
      metricKeys: [dps],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: -9,
    },
    tMinus10: {
      metricKeys: [dps],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: -10,
    },
    tMinus11: {
      metricKeys: [dps],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: -11,
    },
  },
  dividendsPerShareFiveYearCagr: {
    t0: {
      metricKeys: [dps],
      formula: "val",
      relativeDateKey: -5,
      formatType: "currency",
    },
    t1: {
      metricKeys: [dps],
      formula: "val",
      relativeDateKey: 0,
      formatType: "currency",
    },
  },
  tevOverRevenue: {
    fiveYearAvg: {
      aliasId: "fiveYearAvg",
      metricKeys: [
        {
          ...tevOverNtmRevenueDivision,
          valuePropKey: "5yr",
        },
        {
          ...tevOverLtmRevenueDivision,
          valuePropKey: "5yr",
        },
      ],
      formula: "val",
      formatType: "turns",
    },
    last: {
      aliasId: "last",
      metricKeys: [
        {
          ...tevOverNtmRevenueDivision,
          valuePropKey: "last",
        },
        {
          ...tevOverLtmRevenueDivision,
          valuePropKey: "last",
        },
      ],
      formula: "val",
      formatType: "turns",
    },
  },
  lastPriceOverStreetPrice: {
    stockPrice: {
      metricKeys: [metrics.stockPriceClose],
      formula: "val",
      formatType: "currency",
    },
    streetPrice: {
      metricKeys: [metrics.stockPrice],
      formula: "val",
      formatType: "currency",
    },
  },
  priceOverEarnings: {
    fiveYearAvg: {
      metricKeys: [
        {
          name: "fiveYearAvg",
          aliasId: "fiveYearAvg",
          source: [
            {
              metricKey: "div-priceclose-eps normalized",
              source: "valuationStats",
              valuePropKey: "5yr",
            },
            {
              metricKey: "div-priceclose-diluted eps excl extra items 142",
              source: "valuationStats",
              valuePropKey: "5yr",
            },
          ],
        },
      ],
      formula: "val",
      formatType: "turns",
    },
    ntm: {
      metricKeys: [
        {
          name: "oneYearAvg",
          aliasId: "oneYearAvg",
          source: [
            {
              metricKey: "div-priceclose-eps normalized",
              source: "valuationStats",
              valuePropKey: "last",
            },
            {
              metricKey: "div-priceclose-diluted eps excl extra items 142",
              source: "valuationStats",
              valuePropKey: "last",
            },
          ],
        },
      ],
      formula: "val",
      formatType: "turns",
    },
  },
  payoutRatio: {
    dps: {
      metricKeys: [dpsEstimate],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: 1,
    },
    eps: {
      metricKeys: [epsNormalized],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: 1,
    },
  },
  twoYearsPayoutRatio: {
    dps: {
      metricKeys: [dpsEstimate],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: 2,
    },
    eps: {
      metricKeys: [epsNormalized],
      formula: "val",
      actualOrEstimateKey: "mean",
      relativeDateKey: 2,
    },
  },
}

export default variables
