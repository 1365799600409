import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _createForOfIteratorHelper(r, e) {
  var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (!t) {
    if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) {
      t && (r = t);
      var _n = 0,
        F = function F() {};
      return {
        s: F,
        n: function n() {
          return _n >= r.length ? {
            done: !0
          } : {
            done: !1,
            value: r[_n++]
          };
        },
        e: function e(r) {
          throw r;
        },
        f: F
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var o,
    a = !0,
    u = !1;
  return {
    s: function s() {
      t = t.call(r);
    },
    n: function n() {
      var r = t.next();
      return a = r.done, r;
    },
    e: function e(r) {
      u = !0, o = r;
    },
    f: function f() {
      try {
        a || null == t["return"] || t["return"]();
      } finally {
        if (u) throw o;
      }
    }
  };
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import algoliaSearch from "~/mixins/algoliaSearch";
import multiChartUtils from "~/mixins/multiChartUtils";
import useNumberFormatters from "~/functions/useNumberFormatters";
import useBaseUtils from "~/functions/useBaseUtils";
import { calculateCAGR } from "~/modules/ratios";
import { getRowsAndTickersSelected } from "~/functions/common";
export default defineComponent({
  mixins: [algoliaSearch(), multiChartUtils],
  props: {
    currency: {
      type: Number,
      "default": 0
    },
    charttitle: {
      type: String,
      "default": ""
    }
  },
  setup: function setup() {
    var _useNumberFormatters = useNumberFormatters(),
      formatPercentOneDecimal = _useNumberFormatters.formatPercentOneDecimal,
      formatNumberTwoDecimals = _useNumberFormatters.formatNumberTwoDecimals;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store,
      $vuetify = _useNuxtApp.$vuetify;
    var chartDescription = computed(function () {
      var _getRowsAndTickersSel = getRowsAndTickersSelected({
          $store: $store,
          chartType: "quotes"
        }),
        tickers = _getRowsAndTickersSel.tickers; // en-GB has no oxford comma at the end
      var list = new Intl.ListFormat("en-GB");
      if (tickers.length === 1) {
        return "".concat(list.format(tickers), "'s Volume and Price Close");
      } else {
        var firstItem = tickers[0];
        return "".concat(firstItem, "'s Volume and ").concat(list.format(tickers), "'s Price Close");
      }
    });
    var searchStyling = computed(function () {
      return {
        width: $vuetify.breakpoint.name === "xs" ? "100%" : "auto"
      };
    });
    var buttonsStyling = computed(function () {
      return {
        "white-space": $vuetify.breakpoint.xs ? "inherit" : "nowrap"
      };
    });
    return {
      chartDescription: chartDescription,
      formatPercent: formatPercentOneDecimal,
      formatNumber: formatNumberTwoDecimals,
      I18nFn: I18nFn,
      searchStyling: searchStyling,
      buttonsStyling: buttonsStyling
    };
  },
  data: function data() {
    return {
      fullscreen: false,
      chartHeight: "500",
      ohlc: true,
      relativePercent: false,
      lineToggle: [{
        name: "OHLC",
        value: "ohlc"
      }],
      hideSelf: false
    };
  },
  computed: {
    amountOfAdditionalTickers: function amountOfAdditionalTickers() {
      var additionalTickersForChart = this.addTickersForChart;
      var additionalTids = Object.keys(additionalTickersForChart);
      return additionalTids.length;
    },
    error: function error() {
      return this.$store.state.ciq.quotesError;
    },
    origFetch: function origFetch() {
      return this.$store.state.ciq.fetchingQuotes;
    },
    quotes: function quotes() {
      return this.$store.state.ciq.quotes.price || [];
    },
    chartColor: function chartColor() {
      return this.$store.state.chartColor;
    },
    darkMode: function darkMode() {
      return this.$vuetify.theme.dark;
    },
    addTickersForChart: function addTickersForChart() {
      return this.$store.state.ciq.quotesChartTickers;
    },
    addDailySeries: function addDailySeries() {
      return this.$store.state.ciq["add".concat(this.charttype)] || {};
    },
    chartOptions: function chartOptions() {
      var _this = this;
      var vue = this;
      var I18n = this.$Amplify.I18n;
      var priceData = this.quotes;
      var darkMode = this.$vuetify.theme.dark;
      var statistics = "";
      var chartTitle = this.charttitle;
      var fullscreen = this.fullscreen;
      var chartHeight = this.chartHeight;
      var makeChartButton = function makeChartButton(_ref) {
        var key = _ref.key,
          type = _ref.type,
          _ref$count = _ref.count,
          count = _ref$count === void 0 ? null : _ref$count;
        return _defineProperty({}, key, {
          key: key,
          type: type,
          count: count,
          text: _this.I18nFn(key)
        });
      };
      var chartButton = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, makeChartButton({
        key: "3m",
        type: "month",
        count: 3
      })), makeChartButton({
        key: "6m",
        type: "month",
        count: 6
      })), makeChartButton({
        key: "ytd",
        type: "ytd"
      })), makeChartButton({
        key: "1yr",
        type: "year",
        count: 1
      })), makeChartButton({
        key: "3yr",
        type: "year",
        count: 3
      })), makeChartButton({
        key: "5yr",
        type: "year",
        count: 5
      })), makeChartButton({
        key: "10yr",
        type: "year",
        count: 10
      })), makeChartButton({
        key: "all",
        type: "all"
      }));
      var minButtons = [chartButton["6m"], chartButton.ytd, chartButton["1yr"], chartButton["5yr"], chartButton.all];
      var allButtons = [chartButton["3m"], chartButton["6m"], chartButton.ytd, chartButton["1yr"], chartButton["3yr"], chartButton["5yr"], chartButton["10yr"], chartButton.all]; // Need to split into volume and ohlc arrays which have specific structures
      // ohlc has a specific structure to work with the chart
      // ohlc: [[date, open, high, low, close]] volume: [[date, volume]]:
      var ohlc = priceData.map(function (d) {
        if (_this.currency === 1) {
          var exchange = d.pc;
          return [d.d, d.o / exchange, d.h / exchange, d.l / exchange, d.c / exchange];
        } else {
          return [d.d, d.o, d.h, d.l, d.c];
        }
      });
      var close = priceData.map(function (d) {
        if (_this.currency === 1) {
          var exchange = d.pc;
          return [d.d, d.c / exchange];
        } else {
          return [d.d, d.c];
        }
      }); // I think volume is actual raw volume - not scaled for anything
      var volume = priceData.map(function (d) {
        return [d.d, d.v];
      });
      var highChartButtons = vue.$vuetify.breakpoint.name === "xs" ? minButtons : allButtons; // const symbol = this.$store.state.ciq.ticker.tickersymbol // trusting there is something here
      // TODO: Change theme based on light/dark mode
      var options = {
        chart: {
          height: fullscreen ? null : vue.$vuetify.breakpoint.name === "xs" ? null : chartHeight,
          zooming: {
            mouseWheel: false
          },
          events: {
            render: function render() {
              try {
                var dates = this.series[0].processedXData;
                var data = this.series[0].processedYData;
                if (data.length > 1 && dates.length > 1) {
                  var _this$options;
                  var firstDate = dates[1];
                  var lastDate = dates[dates.length - 1];
                  var initialValue = data[1];
                  var lastValue = data[data.length - 1];
                  var increase = initialValue < lastValue;
                  var _calculateCAGR = calculateCAGR({
                      initialValue: initialValue,
                      lastValue: lastValue,
                      firstDate: firstDate,
                      lastDate: lastDate
                    }),
                    cagr = _calculateCAGR.cagr,
                    yearDiff = _calculateCAGR.yearDiff,
                    valueChange = _calculateCAGR.valueChange;
                  var changeText = increase ? vue.formatPercent.format(valueChange) : "(".concat(vue.formatPercent.format(Math.abs(valueChange)), ")");
                  var color = increase ? "green" : "red";
                  var CAGRText = increase ? vue.formatPercent.format(cagr) : "(".concat(vue.formatPercent.format(Math.abs(cagr)), ")"); // if cagr is more than 10k percent, show as >10k%
                  if (cagr >= 100) {
                    CAGRText = ">10,000%";
                  }
                  var buttonSelectedIndex = (_this$options = this.options) === null || _this$options === void 0 || (_this$options = _this$options.rangeSelector) === null || _this$options === void 0 ? void 0 : _this$options.selected;
                  var yearInSelectedRange = buttonSelectedIndex && ["1yr", "3yr", "5yr", "10yr"].includes(highChartButtons[buttonSelectedIndex].key);
                  var yearDiffText = vue.formatNumber.format(yearInSelectedRange ? highChartButtons[buttonSelectedIndex].count : yearDiff);
                  statistics = "<span style=\"color: ".concat(color, ";\">").concat(changeText, "</span> ").concat(I18n.get("price return over"), " ").concat(yearDiffText, " ").concat(I18n.get("years"), " - <span style=\"color: ").concat(color, ";\">").concat(CAGRText, "</span> ").concat(I18n.get("CAGR"));
                  this.setTitle(null, {
                    text: statistics
                  });
                }
              } catch (error) {
                console.error("Error calculating summary statistics for price chart: ", error);
              }
            },
            load: function load() {
              var _chart$stockTools;
              var chart = this;
              (_chart$stockTools = chart.stockTools) === null || _chart$stockTools === void 0 || (_chart$stockTools = _chart$stockTools.showhideBtn) === null || _chart$stockTools === void 0 || _chart$stockTools.click();
            }
          }
        },
        accessibility: {
          description: this.chartDescription
        },
        rangeSelector: {
          buttonTheme: {
            fill: this.darkMode ? "#a5a5a5" : "#E1E4EA",
            r: 4,
            width: 22,
            height: 15,
            style: {
              color: "black",
              fontSize: 11
            },
            states: {
              hover: {
                fill: this.$vuetify.theme.themes.light.primaryAction,
                style: {
                  color: "black"
                }
              },
              select: {
                fill: this.$vuetify.theme.themes.light.primaryAction,
                style: {
                  color: "black"
                }
              }
            }
          },
          selected: vue.$vuetify.breakpoint.name === "xs" ? 2 : 3,
          buttons: highChartButtons,
          buttonPosition: {
            align: "right"
          },
          buttonSpacing: 2,
          inputEnabled: false // FIXME: this has never worked
        },
        xAxis: [{
          labels: {
            style: {
              color: this.darkMode ? "#e6e6e6" : "#666666"
            }
          }
        }],
        yAxis: [{
          height: "80%",
          gridLineColor: this.darkMode ? "#969696" : "#e6e6e6",
          labels: {
            style: {
              color: this.darkMode ? "#e6e6e6" : "#666666"
            }
          },
          resize: {
            enabled: true
          }
        }, {
          top: "80%",
          height: "20%",
          offset: 0
        }],
        title: {
          text: chartTitle,
          align: "left",
          style: {
            color: darkMode ? "#e6e6e6" : "#333333",
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: '"Inter", "Roboto", sans-serif'
          }
        },
        subtitle: {
          align: "left",
          style: {
            color: darkMode ? "#e6e6e6" : "#333333",
            fontSize: "14px"
          }
        },
        tooltip: {
          borderWidth: 0,
          shadow: false,
          valueDecimals: 2,
          crosshairs: [true, true]
        },
        navigator: {
          maskFill: "rgba(255, 153, 0, 0.4)",
          handles: {
            backgroundColor: this.chartColor,
            borderColor: "#232F3E",
            symbols: ["customcircle", "customcircle"]
          }
        },
        scrollbar: {
          barBackgroundColor: "#E1E4EA",
          barBorderRadius: 7,
          barBorderWidth: 0,
          buttonBackgroundColor: "#E1E4EA",
          buttonBorderWidth: 0,
          buttonArrowColor: "#232F3E",
          buttonBorderRadius: 7,
          rifleColor: "#232F3E",
          trackBackgroundColor: this.darkMode ? "#a5a5a5" : "white",
          trackBorderWidth: 1,
          trackBorderColor: "#828282",
          trackBorderRadius: 7
        },
        stockTools: {
          gui: {
            buttons: ["currentPriceIndicator", "indicators", "separator", "simpleShapes", "lines", "crookedLines", "measure", "toggleAnnotations", "separator", "verticalLabels", "flags", "separator", "fullScreen"],
            enabled: this.$device.isDesktopOrTablet
          }
        },
        credits: {
          enabled: true,
          href: "https://tikr.com",
          text: "TIKR.com",
          style: {
            cursor: "pointer",
            color: darkMode ? "#FFFFFF" : "#2F3033",
            fontSize: "12px"
          }
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG", "separator", "downloadCSV"],
              symbolFill: this.darkMode ? "#a5a5a5" : "#666666",
              symbolStroke: this.darkMode ? "#a5a5a5" : "#666666",
              theme: {
                fill: this.darkMode ? "#1E1E1E" : "#ffffff"
              }
            }
          },
          sourceWidth: 1000,
          sourceHeight: 600,
          scale: 1
        },
        plotOptions: {
          series: {
            animation: false
          }
        }
      };
      var dataseries = [{
        type: "line",
        id: "close",
        name: "".concat(this.chartsymbol, " ").concat(I18n.get("Price Close")),
        yAxis: 0,
        data: close,
        color: this.chartColor,
        visibile: !this.hideSelf,
        compare: vue.relativePercent ? "percent" : undefined,
        tooltip: {
          pointFormat: "<span style=\"color:{point.color}\">\u25CF</span> {series.name}: <b>{point.y}</b> ".concat(vue.relativePercent ? " <b>{point.change}%</b>" : "", "<br/>"),
          changeDecimals: 1,
          valueDecimals: 2
        }
      }, {
        type: "column",
        id: "volume",
        data: volume,
        name: "".concat(this.chartsymbol, " ").concat(I18n.get("Volume")),
        yAxis: 1,
        color: this.chartColor,
        tooltip: {
          valueDecimals: 0,
          pointFormat: "<span style=\"color:{point.color}\">\u25CF</span> {series.name}: <b>{point.y}</b><br/>"
        }
      }];
      var additionalTickersForChart = this.addTickersForChart;
      var additionalTids = Object.keys(additionalTickersForChart);
      if (additionalTids.length > 0) {
        var _iterator = _createForOfIteratorHelper(additionalTids),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var tid = _step.value;
            if (this.addDailySeries[tid]) {
              var priceObj = this.addDailySeries[tid]; // transform newObj
              var additionalClose = priceObj.price.map(function (d) {
                if (_this.currency === 1) {
                  var exchange = d.pc;
                  return [d.d, d.c / exchange];
                } else {
                  return [d.d, d.c];
                }
              });
              dataseries.push({
                id: "".concat(tid, "-close"),
                name: "".concat(additionalTickersForChart[tid].tickersymbol, " ").concat(I18n.get("Price Close")),
                type: "line",
                data: additionalClose,
                yAxis: 0,
                compare: vue.relativePercent ? "percent" : undefined,
                tooltip: {
                  pointFormat: "<span style=\"color:{point.color}\">\u25CF</span> {series.name}: <b>{point.y}</b> ".concat(vue.relativePercent ? " <b>{point.change}%</b>" : "", "<br/>"),
                  changeDecimals: 1,
                  valueDecimals: 2
                }
              });
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      } else if (this.ohlc && additionalTids.length === 0 && !this.relativePercent) {
        dataseries.push({
          type: "ohlc",
          id: "ohlc",
          data: ohlc,
          name: "OHLC",
          color: this.$vuetify.theme.dark ? "#b3b3b3" : "#152939",
          tooltip: {
            pointFormat: ""
          }
        });
      }
      if (darkMode) {
        options.chart.backgroundColor = "#1E1E1E";
      }
      return Object.assign({}, options, {
        series: dataseries
      });
    }
  },
  created: function created() {
    var _this2 = this;
    document.addEventListener("fullscreenchange", function () {
      _this2.fullscreen = Boolean(document.fullscreenElement);
    });
  },
  methods: {
    addTicker: function addTicker(e) {
      var ticker = e[e.length - 1];
      this.$store.dispatch("ciq/quotesOneStep", {
        chartType: "quotesChart",
        ticker: ticker
      });
      this.relativePercent = true;
      this.$refs.quotesAutocomplete.blur();
    },
    removeTicker: function removeTicker(e) {
      this.$store.commit("ciq/removeTickerFromDailyChart", {
        chartType: "quotesChart",
        tid: e.tradingitemid.toString()
      });
      this.$refs.quotesAutocomplete.blur();
    }
  }
});