import { render, staticRenderFns } from "./OverviewMetricDisplay.vue?vue&type=template&id=f96cbc1c&scoped=true"
import script from "./OverviewMetricDisplay.vue?vue&type=script&setup=true&lang=js"
export * from "./OverviewMetricDisplay.vue?vue&type=script&setup=true&lang=js"
import style0 from "./OverviewMetricDisplay.vue?vue&type=style&index=0&id=f96cbc1c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prett_mslvjkx5fqxq5hwkswdqi36zjm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f96cbc1c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OverviewMetricSkeleton: require('/vercel/path0/components/overviewMetrics/OverviewMetricSkeleton.vue').default,UnauthorizedIcon: require('/vercel/path0/components/UnauthorizedIcon.vue').default})
