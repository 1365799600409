import "core-js/modules/es.number.constructor.js";
import DiligenceSkeletonCheckItems from "./DiligenceSkeletonCheckItems.vue";
import useBreakpoints from "~/functions/useBreakpoints";
export default {
  __name: 'DiligenceSkeletonTabs',
  props: {
    checksAmount: {
      type: Number,
      "default": 6
    },
    isOverviewTab: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBreakpoints = useBreakpoints(),
      lg = _useBreakpoints.lg;
    var tabsContentStyles = computed(function () {
      var gridTemplateColumns = props.isOverviewTab && lg.value ? "230px minmax(0,1fr)" : "minmax(0,1fr)";
      return {
        "grid-template-columns": gridTemplateColumns
      };
    });
    var overviewStyle = computed(function () {
      return {
        "--mock-image-size": lg.value ? "385px" : "182px"
      };
    });
    return {
      __sfc: true,
      lg: lg,
      props: props,
      tabsContentStyles: tabsContentStyles,
      overviewStyle: overviewStyle,
      DiligenceSkeletonCheckItems: DiligenceSkeletonCheckItems
    };
  }
};