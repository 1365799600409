import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url.to-json.js";
import "core-js/modules/web.url-search-params.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
import { useEventListener } from "@vueuse/core";
import useBusinessMode from "~/functions/useBusinessMode";
import useBaseUtils from "~/functions/useBaseUtils";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import useNumberFormatters from "~/functions/useNumberFormatters";
import { defaultUsdObj } from "~/utils/constants/objects";
import OverviewMetrics from "~/components/OverviewMetrics.vue";
import QuickDueDiligence from "~/components/dueDiligence/QuickDueDiligence.vue";
import { estimatesTabs, financialTabs, valuationTabs, dataSourceTabs } from "~/utils/constants/tabs";
import { SHOW_MORNINGSTAR_FINANCIALS } from "~/feature-toggle";
export default {
  __name: 'stock',
  setup: function setup(__props) {
    var _useBusinessMode = useBusinessMode(),
      hidePrice = _useBusinessMode.hidePrice;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      dev = _useBaseUtils.dev,
      I18nFn = _useBaseUtils.I18nFn,
      sectionWrapperStyle = _useBaseUtils.sectionWrapperStyle,
      darkActive = _useBaseUtils.darkActive;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      utcEpochToLongDate = _useDateTimeFormatter.utcEpochToLongDate;
    var _useNumberFormatters = useNumberFormatters(),
      formatPercent = _useNumberFormatters.formatPercent;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store,
      $vuetify = _useNuxtApp.$vuetify,
      $featureToggle = _useNuxtApp.$featureToggle;
    var $route = useRoute();
    var _dataSourceTabs = dataSourceTabs(SHOW_MORNINGSTAR_FINANCIALS),
      _dataSourceTabs2 = _slicedToArray(_dataSourceTabs, 1),
      dataSourceFirstSlug = _dataSourceTabs2[0];
    var estimatesFirstTabSlug = estimatesTabs[0].slug;
    var financialFirstTabSlug = financialTabs[0].slug;
    var valuationFirstTabSlug = valuationTabs[0].slug;
    var showChart = ref(false);
    var sectionVars = computed(function () {
      return {
        "--section-border-color": darkActive.value ? "#3D3D3D" : "#d9d9d9",
        "--section-bg": darkActive.value ? "#1E1E1E" : "inherit",
        "--section-border-radius": "5px",
        "--section-gap": "16px"
      };
    });
    var wrapperStyle = computed(function () {
      return {
        "padding-inline": $vuetify.breakpoint.smAndUp ? "12px" : "0"
      };
    });
    var priceWrapperStyle = computed(function () {
      return {
        "--padding-size": $vuetify.breakpoint.lgAndUp ? "8px" : "4px"
      };
    });
    var isDueDiligenceActive = computed(function () {
      return $featureToggle.toggles["due-diligence"];
    });
    var enableDataSourceQueryParams = computed(function () {
      var _$featureToggle$toggl;
      return (_$featureToggle$toggl = $featureToggle.toggles) === null || _$featureToggle$toggl === void 0 ? void 0 : _$featureToggle$toggl["datasource-query-params"];
    });
    var loading = computed(function () {
      return $store.state.ciq.fetchingQuotes || $store.state.iex.lastSingleStockQuoteLoading;
    });
    var currencyToggle = computed({
      get: function get() {
        return $store.state.ciq.quoteCurrencyToggle;
      },
      set: function set(value) {
        return $store.commit("ciq/setToggle", {
          type: "quoteCurrencyToggle",
          value: value
        });
      }
    });
    var ticker = computed(function () {
      return $store.state.ciq.ticker;
    });
    var iexPrice = computed(function () {
      try {
        return $store.state.iex.lastSingleStockQuote[ticker.value.tradingitemid] || {};
      } catch (error) {
        return {};
      }
    });
    var formatCurrency = computed(function () {
      var lang = navigator.language || "default";
      var currencyIso = activeCurrency.value.code || "USD";
      var min = 2;
      var max = 2;
      return new Intl.NumberFormat(lang, {
        style: "currency",
        currency: currencyIso,
        minimumFractionDigits: min,
        maximumFractionDigits: max
      });
    });
    var activeCurrency = computed(function () {
      return currencies.value[currencyToggle.value] || defaultUsdObj;
    });
    var currencies = computed(function () {
      return $store.state.ciq.quoteCurrencies;
    });
    var rkdticker = computed(function () {
      return $store.state.trkd.ticker;
    });
    var closeQuotes = computed(function () {
      return $store.state.ciq.quotes.price || [];
    });
    var last = computed(function () {
      if (closeQuotes.value.length <= 0) {
        return {
          latestPrice: 0,
          change: 0,
          changePercent: 0,
          latestTime: ""
        };
      }
      var lastDay = closeQuotes.value[closeQuotes.value.length - 1];
      var secondToLast = closeQuotes.value[closeQuotes.value.length - 2];
      var latestPrice = currencyToggle.value ? lastDay.c / lastDay.pc : lastDay.c;
      var latestTime = utcEpochToLongDate.value.format(lastDay.d) + I18nFn(" Close");
      if (!secondToLast) {
        return {
          latestPrice: latestPrice,
          change: 0,
          changePercent: 0,
          latestTime: latestTime
        };
      }
      var previousClose = currencyToggle.value ? secondToLast.c / secondToLast.pc : secondToLast.c;
      var change = latestPrice - previousClose;
      var changePercent = change / previousClose; // TODO: Make time formatting better
      return {
        latestPrice: latestPrice,
        change: change,
        changePercent: changePercent,
        latestTime: latestTime
      };
    });
    var denseStockTabs = computed(function () {
      return $store.state.config.denseStockTabs;
    });
    var autoHidePriceChart = computed(function () {
      return $store.state.config.autoHidePriceChart;
    });
    var refinitiv = computed(function () {
      return $store.state.config.refinitiv;
    });
    var darkMode = computed(function () {
      return $vuetify.theme.dark;
    });
    var ciqDocumentTitle = computed(function () {
      var newVal = iexPrice.value;
      if (!hidePrice.value && newVal && newVal.latestPrice && newVal.latestPrice !== 0) {
        return "".concat(ticker.value.tickersymbol, " ").concat(formatCurrency.value.format(newVal.latestPrice), " ").concat(newVal.change !== 0 ? "".concat(formatCurrency.value.format(newVal.change), " ").concat(formatPercent.value.format(newVal.changePercent)) : "", " - ").concat(I18nFn("TIKR Terminal"));
      } else {
        // change the title
        return ticker.value.companyname ? "".concat(hidePrice.value ? "" : formatCurrency.value.format(last.value.latestPrice), " ").concat(ticker.value.companyname, " (").concat(ticker.value.tickersymbol, ") - ").concat(I18nFn("TIKR Terminal")) : "".concat(I18nFn("TIKR Terminal"));
      }
    });
    var modelPath = computed(function () {
      var path = $route.path === "/stock/simple-model" ? "/stock/simple-model" : "/stock/model";
      return "".concat(path, "?cid=").concat(ticker.value.companyid, "&tid=").concat(ticker.value.tradingitemid, "&ref=").concat(refCode.value);
    });
    var lastQuoteEventListenerCleanup = null;
    watch(rkdticker, function (newVal) {
      if (newVal && newVal.iex) {
        var symbol = null;
        if (newVal.ExchangeCountry === "USA") {
          symbol = newVal.TickerSymbol; // TODO: attach an IEX ID to the refinitiv obj
          var iex = newVal.iex || [];
          if (iex.length === 1) {
            // this is the happy path...
            // https://iexcloud.io/docs/api/#international-exchanges
            // checkout mic - market identifier code ISO 10383
            symbol = iex[0].symbol;
          } else if (iex.length > 1) {
            var iexSymbolSet = new Set();
            iex.forEach(function (i) {
              iexSymbolSet.add(i.symbol);
            });
            var uniqueIexSymbols = Array.from(iexSymbolSet);
            if (uniqueIexSymbols.length === 1) {
              symbol = uniqueIexSymbols[0];
            } else if (dev.value) {
              console.error("More than one iex symbol: ", uniqueIexSymbols);
            }
          } else if (dev.value) {
            console.error("iex returned no symbols");
          }
          if (symbol) {
            var newObj = _objectSpread({}, newVal);
            newObj.cid = ticker.value.companyid;
            newObj.tid = ticker.value.tradingitemid;
            newObj.symbol = symbol;
            $store.dispatch("iex/fetchLastIEXQuotes", {
              tickerObjArr: [newObj],
              intervalType: "SingleStock",
              stateKey: "lastSingleStockQuote"
            }); // I don't think that the automatic destroy of the event listener
            // is working? Potentially because it is wrapped within the watcher?
            lastQuoteEventListenerCleanup = useEventListener(window, "focus", function () {
              if (!newObj) {
                return;
              }
              $store.commit("iex/clearFetchInterval", {
                intervalType: "SingleStock"
              });
              $store.dispatch("iex/fetchLastIEXQuotes", {
                tickerObjArr: [newObj],
                intervalType: "SingleStock",
                stateKey: "lastSingleStockQuote",
                clearLast: false,
                showLoading: false // FIXME: Do you want to remove this?
              });
            }); // useEventListener(window, "blur", () => {
            //   // maybe just do this..?
            //   console.log("stock window blurred") // FIXME: remove log
            //   $store.commit("iex/clearFetchInterval", {
            //     intervalType: "SingleStock",
            //   })
            // })
          }
        }
      }
    }, {
      immediate: true
    });
    watch(ciqDocumentTitle, function (newVal) {
      if (newVal) {
        document.title = newVal;
      }
    });
    watch(function () {
      return $route.query.tid;
    }, function () {
      if (lastQuoteEventListenerCleanup) {
        lastQuoteEventListenerCleanup();
      }
    });
    onMounted(function () {
      // I could do something here... to fetch the data (if someone say, pasted a url, or clicked on a link)
      // for when people directly type in the url they're going to
      // initiate fetching if it hasn't been done yet.. etc
      if ($route.path === "/stock/about") {
        showChart.value = true;
      }
    }); // FIXME: you may need to do this on a watch hook looking at ticker?
    // that way it always works when it changes?
    onUnmounted(function () {
      // FIXME: make this an action instead of a mutation
      if (lastQuoteEventListenerCleanup) {
        lastQuoteEventListenerCleanup();
      }
      $store.commit("iex/clearFetchInterval", {
        intervalType: "SingleStock"
      });
    });
    var handleModelClick = function handleModelClick(currentRoute) {
      // if its coming from a editModel page
      if (currentRoute.path === "/stock/model" && currentRoute.query.modelId) {
        var url = new URL(window.location.href);
        url.searchParams["delete"]("modelId"); // force full-reload
        window.location.replace(url);
        return false;
      }
    };
    var hidePriceChart = function hidePriceChart() {
      if (autoHidePriceChart.value) {
        showChart.value = false;
      }
    };
    var showPriceChart = function showPriceChart() {
      if (autoHidePriceChart.value) {
        showChart.value = true;
      }
    };
    return {
      __sfc: true,
      hidePrice: hidePrice,
      refCode: refCode,
      dev: dev,
      I18nFn: I18nFn,
      sectionWrapperStyle: sectionWrapperStyle,
      darkActive: darkActive,
      utcEpochToLongDate: utcEpochToLongDate,
      formatPercent: formatPercent,
      $store: $store,
      $vuetify: $vuetify,
      $featureToggle: $featureToggle,
      $route: $route,
      dataSourceFirstSlug: dataSourceFirstSlug,
      estimatesFirstTabSlug: estimatesFirstTabSlug,
      financialFirstTabSlug: financialFirstTabSlug,
      valuationFirstTabSlug: valuationFirstTabSlug,
      showChart: showChart,
      sectionVars: sectionVars,
      wrapperStyle: wrapperStyle,
      priceWrapperStyle: priceWrapperStyle,
      isDueDiligenceActive: isDueDiligenceActive,
      enableDataSourceQueryParams: enableDataSourceQueryParams,
      loading: loading,
      currencyToggle: currencyToggle,
      ticker: ticker,
      iexPrice: iexPrice,
      formatCurrency: formatCurrency,
      activeCurrency: activeCurrency,
      currencies: currencies,
      rkdticker: rkdticker,
      closeQuotes: closeQuotes,
      last: last,
      denseStockTabs: denseStockTabs,
      autoHidePriceChart: autoHidePriceChart,
      refinitiv: refinitiv,
      darkMode: darkMode,
      ciqDocumentTitle: ciqDocumentTitle,
      modelPath: modelPath,
      lastQuoteEventListenerCleanup: lastQuoteEventListenerCleanup,
      handleModelClick: handleModelClick,
      hidePriceChart: hidePriceChart,
      showPriceChart: showPriceChart,
      OverviewMetrics: OverviewMetrics,
      QuickDueDiligence: QuickDueDiligence
    };
  }
};