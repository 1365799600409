const fin = "financials"
const est = "estimates"
const val = "valuation"
const valStats = "valuationStats"
const trading = "trading"

const metrics = {
  bookValuePerShare: {
    name: "book value / share act.",
    source: est,
  },
  capitalExpenditure: {
    name: "capital expenditure 2021",
    source: fin,
  },
  capitalExpenditureAct: {
    name: "capital expenditure act.",
    source: est,
  },
  cfo: {
    name: "cash from operations 2006",
    source: fin,
  },
  cfoAct: {
    name: "cash from operations act.",
    source: est,
  },
  depreciationAmortization: {
    name: "depreciation & amortization 2171",
    aliasId: "da",
    source: fin,
  },
  dps: { name: "dividends per share 3058", source: fin },
  dpsEstimate: {
    name: "dividend per share act.",
    source: est,
    tableNameId: "Dividend Per Share",
  },
  dsi: {
    name: "avg. days outstanding inventory",
    source: fin,
  },
  dso: {
    name: "avg. days sales outstanding 4042",
    source: fin,
  },
  ebit: {
    name: "ebit margin % 4053",
    source: fin,
    tableNameId: "EBIT Margin %",
  },
  ebitAct: { name: "ebit act.", source: est },
  ebitdaAct: {
    name: "ebitda act.",
    source: est,
  },
  ebitda: {
    name: "ebitda",
    source: [
      { metricKey: "ebitda act.", source: est },
      { metricKey: "ebitda 4051", source: fin },
    ],
  },
  ebitOverInterestExpense: {
    name: "ebit / interest expense 4189",
    source: fin,
  },
  epsWithFallback: {
    name: "eps",
    source: [
      { metricKey: "eps normalized act.", source: est },
      { metricKey: "diluted eps excl extra items 142", source: fin },
    ],
    tableNameId: "EPS Normalized ",
  },
  epsNormalized: {
    name: "eps normalized act.",
    source: est,
    aliasId: "epsNormalized",
    tableNameId: "EPS Normalized ",
  },
  epsGaap: {
    name: "eps (gaap) act.",
    source: est,
  },
  ffoReit: {
    name: "ffo",
    source: [
      { metricKey: "ffo (reit) act.", source: est },
      { metricKey: "adjusted ffo (reit) act.", source: est },
    ],
  },
  ffoPerShare: {
    name: "adjusted ffo / share (reit) act.",
    source: est,
  },
  freeCashFlow: {
    name: "free cash flow act.",
    source: est,
    tableNameId: "Free Cash Flow ",
  },
  grossProfitMargin: {
    name: "gross profit margin % 4074",
    source: fin,
    tableNameId: "Gross Profit Margin %",
  },
  interestCoverage: {
    name: "ffo interest coverage 43903",
    source: fin,
  },
  interestExpense: { name: "interest expense", source: fin },
  lastPriceOver52WeekHigh: {
    name: "lastPriceDiv52WeekHigh",
    source: trading,
  },
  netDebt: {
    name: "net debt",
    source: [
      { metricKey: "net debt act.", source: est },
      { metricKey: "net debt 4364", source: fin },
    ],
  },
  netDebtOverEbitda: { name: "net debt / ebitda 4193", source: fin },
  netIncomeAct: {
    name: "net income normalized act.",
    source: est,
    aliasId: "netIncomeNormalized",
  },
  netIncome2150: {
    name: "net income_cf 2150",
    source: fin,
    aliasId: "netIncome",
  },
  netIncome: {
    name: "net income margin % 4094",
    aliasId: "netIncome",
    source: fin,
  },
  payoutRatio: {
    name: "payout ratio % 4377",
    source: fin,
    tableNameId: "Payout Ratio %",
  },
  priceOverEarnings: {
    name: "div-priceclose-diluted eps excl extra items 142",
    source: valStats,
  },
  revenue: {
    name: "revenue",
    source: [
      { metricKey: "revenue act.", exact: true, source: est },
      { metricKey: "revenues 112", source: fin },
    ],
    tableNameId: "Revenue ",
  },
  returnOnCapital: {
    name: "return on capital % 43905",
    source: fin,
    tableNameId: "Return on Capital %",
  },
  stockPriceClose: {
    name: "priceclose",
    source: val,
  },
  stockPrice: {
    name: "target stock price mean",
    source: val,
  },
  stockPriceCagrFiveYears: {
    name: "price5YrCagr",
    source: trading,
    tableNameId: "Price Close",
  },
  tev: { name: "tev", source: val },
  tevOverNtmRevenueDivision: {
    name: "div-tev-revenue",
    source: valStats,
    tableNameId: "NTM Total Enterprise Value / Revenues",
  },
  tevOverLtmRevenueDivision: {
    name: "div-tev-total revenues 28",
    source: valStats,
    tableNameId: "LTM Total Enterprise Value / Revenues",
  },
}

export default metrics
