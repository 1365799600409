export default {
  __name: 'OverviewMetricSkeleton',
  setup(__props) {

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
const randomWidthValue = `${randomIntFromInterval(32, 70)}px`

return { __sfc: true,randomIntFromInterval, randomWidthValue }
}

}